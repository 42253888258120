import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter, route } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyDashboards from '@/stores/me/dashboards'

export const useModalsBoardEditStore = defineForm({
    id: 'modalsBoardEdit',

    inputs: () => ({
        board: {},
        name: null,
        description: null,
        avatar: {}
    }),

    submitRequest() {
        if (! this.inputs.board.id) {
            trackEvent('dashboards', 'new-dashboard-saved', 'board')
        }

        return api.url(this.inputs.board.id ? route('me dashboards update', { id: this.inputs.board.id }) : route('me dashboards store'))
            .formData({
                _method: this.inputs.board.id ? 'put' : 'post',
                type: 'board',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: {
                    color: this.inputs.avatar.color,
                    icon: this.inputs.avatar.icon,
                    imageId: this.inputs.avatar.imageId
                }
            })
            .post()
    },

    onResponse(res) {
        useMyDashboards().reload()

        useModal().hide('board-edit')

        if (! this.inputs.board.id) {
            useRouter().push({ name: 'dashboard.boards.board', params: { id: res.data.id } })
        }
    },

    actions: {
        open(board) {
            this.reset()

            this.inputs.board = board || {}
            this.inputs.name = board?.name
            this.inputs.description = board?.description
            this.inputs.avatar = board?.avatar || {}

            useModal().show('board-edit')
        },

        cancel() {
            useModal().hide('board-edit')
        },

        delete() {
            return api.route('me dashboards delete', { id: this.inputs.board.id })
                .delete()
                .res(res => {
                    useMyDashboards().reload()
                    useModal().hide('board-edit')
                    useRouter().push({ name: 'dashboards', params: { type: 'boards' } })
                })
        }
    }
})

export default useModalsBoardEditStore
