import useMyAnalysesStore from '../stores/me/analyses'
import useBoardStore from '../stores/dashboards/board'
import useMyStore from '@/stores/me/my'
import useMyPerspectivesStore from '../stores/me/perspectives'
import useMyTopicsStore from '../stores/me/search-topics'
import useTargetListsStore from '../stores/me/target-lists'

import { defineStore } from 'pinia'

export const useDashboardsBoardPage = defineStore({
    id: 'dashboardsBoardPage',

    state: () => ({
        wasShowingSidebar: false
    }),

    getters: {
        title: () => useBoardStore()?.board.name ? `${useBoardStore().board.name} | Dashboards` : ''
    },

    actions: {
        async beforeEnter(to) {
            this.wasShowingSidebar = useMyStore().showSidebar
            useMyStore().showSidebar = false

            await Promise.all([
                useMyAnalysesStore().initialize(),
                useMyPerspectivesStore().initialize(),
                useMyTopicsStore().initialize(),
                useTargetListsStore().initialize()
            ])

            useBoardStore().initialize(to.params.id).then(() => {
                if (useBoardStore().board?.lastUsedAt) { useBoardStore().board.lastUsedAt = new Date }
            })
        },

        async beforeLeave() {
            useBoardStore().stopUpdating()
            useMyStore().showSidebar = this.wasShowingSidebar
        }
    }
})

export default useDashboardsBoardPage
