import { defineStore } from 'pinia'

export const useFeaturesLibraryTypesStore = defineStore({
    id: 'features-library-types',

    getters: {
        features: state => ([
            {
                type: 'image-cluster',
                name: 'Images',
                icon: 'badges.feature-image',
                tabs: [ 'information', 'stream' ],
                hidden: true
            },
            {
                type: 'video-cluster',
                name: 'Videos',
                icon: 'badges.feature-video',
                tabs: [ 'information', 'stream' ],
                hidden: true
            },
            {
                type: 'email',
                name: 'Email Addresses',
                icon: 'badges.feature-email',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'location',
                name: 'Locations',
                icon: 'badges.feature-location',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'phone-number',
                name: 'Phone Numbers',
                icon: 'badges.feature-phone-number',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'iban',
                name: 'Bank Accounts',
                icon: 'badges.feature-iban',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'domain',
                name: 'Domain Names',
                icon: 'badges.feature-domain',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'ip-address',
                name: 'IP Addresses',
                icon: 'badges.feature-ip-address',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'hashtag',
                name: 'Hashtags',
                icon: 'badges.feature-hashtag',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'name',
                name: 'Person Names',
                icon: 'badges.feature-name',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            },
            {
                type: 'url',
                name: 'URLs',
                icon: 'badges.feature-url',
                tabs: [ 'information', 'stream', 'history', 'connections' ]
            }
        ]),

        shownFeatures: store => store.features.filter(f => ! f.hidden)
    },

    actions: {
        async all() {
            return this.features
        },

        find(type) {
            return this.features.find(f => f.type == type)
        }
    }
})

export default useFeaturesLibraryTypesStore
