<template>
    <ui-async :guards="items" class="space-y-3">
        <stream-card :content="item" :key="item.id" v-for="item in items"></stream-card>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-48">
                <ui-spinner v-bind="spinner"></ui-spinner>
            </div>
        </template>
    </ui-async>
</template>

<script>
import StreamCard from './stream-card'

import { mapLocalState } from '@/helpers'

export default {
    components: {
        StreamCard
    },

    computed: {
        ...mapLocalState([ 'items' ])
    }
}
</script>