import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTaggingStore from '@/stores/tagging/tagging'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { targets } from '@/api'

import { defineStore } from 'pinia'

export const useTargetsTargetPage = defineStore({
    id: 'targetsTargetPage',

    state: () => ({
        type: 'sources',
        modelType: null,
        target: null,
        model: null
    }),

    getters: {
        title: store => store.target ? `${store.target.title} | Targets` : ''
    },

    actions: {
        async afterEnter(to) {
            if (this.target?.type != to.params.modelType || this.target?.id != to.params.id) this.$reset()

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize()
            useMyTargetListsStore().initialize()

            await Promise.all([
                this.load(to.params.type, to.params.modelType, to.params.id),
                useMyTargetListsStore().initialize(),
                useTaggingStore().initialize()
            ])

            useMyRecentTargetsStore().reload()
        },

        load(type, modelType, id, force) {
            if (this.target && ! force) return

            this.type = type
            this.modelType = modelType

            return targets().family(this.type)
                .include([ 'content-count', 'images', 'mirror-target', 'notes' ])
                .find(id)
                .then(target => this.target = target)
        },

        reload() {
            return this.load(this.type, this.modelType, this.target?.id, true)
        }
    }
})

export default useTargetsTargetPage
