<template>
    <content-card
        v-bind="$props"
        :avatar-badge="content.source.type == 'facebook-user' ? 'badges.target-facebook-user' : 'badges.target-facebook-group'"
        :avatar-badge-tooltip="content.source.type == 'facebook-user' ? 'Facebook User' : 'Facebook Group'"
        :parent-component="{ 'facebook-post': FacebookPostCard, 'facebook-group-post': _.type, 'facebook-user-post': FacebookUserPostCard }"
    >
        <!-- Metrics -->
        <template v-slot:metrics>
            <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="isShowingMetrics">
                <div class="flex items-center" v-if="content.metrics.interactions">
                    <ui-dropdown align="left" position="top" unstyled>
                        <template v-slot:trigger>
                            <div class="flex justify-center items-center cursor-pointer">
                                <ui-icon name="interactions-solid" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                <div class="leading-none ml-2">
                                    <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions
                                    <template v-if="content.forecasts && content.forecasts.length">
                                        <ui-icon name="chevron-right"></ui-icon>
                                        <span class="font-bold">{{ $number(latestForecast.reactions) }}</span> by tomorrow
                                    </template>
                                </div>
                            </div>
                        </template>

                        <template v-slot:content>
                            <div class="rounded-sm shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left space-y-3 whitespace-nowrap">
                                <div class="flex items-center" v-if="content.metrics.reactions">
                                    <ui-icon name="thumbs-up" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.reactions) }}</span> reactions
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.comments">
                                    <ui-icon name="comment" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.comments) }}</span> comments
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.shares">
                                    <ui-icon name="share" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.shares) }}</span> shares
                                    </div>
                                </div>
                            </div>
                        </template>
                    </ui-dropdown>
                </div>

                <div class="flex justify-center items-center" v-if="content.metrics.views">
                    <ui-icon name="views" class="text-lg shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-2">
                        <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'
import FacebookPostCard from './facebook-post'
import FacebookUserPostCard from './facebook-user-post'

import { markRaw } from 'vue'

export default {
    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    data: () => ({
        FacebookPostCard: markRaw(FacebookPostCard),
        FacebookUserPostCard: markRaw(FacebookUserPostCard)
    }),

    computed: {
        isShowingMetrics() {
            return this.content.metrics?.interactions
                || this.content.metrics?.views
        }
    }
}
</script>
