<template>
    <div class="pb-6">
        <div class="bg-white w-full shadow rounded mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="! store.series.length">
            <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
            <h1 class="text-2xl font-semibold my-6">Welcome to the "Top Platforms" analysis!</h1>
            <p class="text-lg my-1">This analysis shows you <span class="font-medium">a dataset breakdown by platform</span>, including a visualisation and tabular representation of various metrics for each included platform.</p>
            <div class="flex space-x-10 my-10">
                <div class="flex-1">
                    <h2 class="font-semibold">Data-set</h2>
                    <p class="text-justify my-2">Select a Perspective, Topic or Target List as a data-set, or define a new perspective inline.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Analysis Styles</h2>
                    <p class="text-justify my-2">Customize how the analysis looks by choosing form multiple analysis types like line or bar analysis and other options.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Range & Grouping</h2>
                    <p class="text-justify my-2">Use the range & grouping controls to specify the shown date range and granularity. You can also drag select a part of the analysis to zoom in.</p>
                </div>
            </div>
            <p class="text-lg my-1">
                To start let's <span class="font-medium">add some data</span> to the analysis by clicking on the Select Data-set button.
            </p>
            <div class="flex items-center mt-8">
                <ui-button color="blue" icon="plus-square" size="lg" :action="() => store.changeDataset()">
                    Select Data-set
                </ui-button>
            </div>
        </div>

        <div v-else>
            <analysis-toolbar :store="store">
                <template v-slot:settings>
                    <settings-dataset :store="store"></settings-dataset>
                    <settings-metric :store="store"></settings-metric>
                </template>
            </analysis-toolbar>

            <div class="px-4 py-8 h-128 bg-white rounded-md shadow mb-6 relative">
                <div class="absolute top-4 right-4 z-20">
                    <chart-styles-contextual-menu :store="store"></chart-styles-contextual-menu>
                </div>

                <ui-analysis :store="store" ref="analysis"></ui-analysis>
            </div>

            <div class="py-4 w-full bg-white border border-gray-200 rounded-lg shadow-sm relative" v-if="store.hasValues">
                <div v-if="store.isLoading" class="absolute inset-0 z-20 flex items-center justify-center bg-gray-100 bg-opacity-10 backdrop-blur-sm rounded-md">
                    <div class="bg-gray-200 bg-opacity-80 text-gray-900 font-medium px-6 py-4 rounded-lg shadow-sm flex items-center">
                        <ui-spinner type="clip" class="mr-2"></ui-spinner>
                        Updating table content...
                    </div>
                </div>

                <ui-table :store="store"></ui-table>
            </div>
        </div>
    </div>
</template>

<script>
import AnalysisToolbar from './toolbar/toolbar'
import ChartStylesContextualMenu from '@/components/analysis/contextual-menus/chart-styles'
import SettingsDataset from './settings/dataset'
import SettingsMetric from './settings/metric'

export default {
    components: {
        AnalysisToolbar, ChartStylesContextualMenu, SettingsDataset, SettingsMetric
    },

    watch: {
        'store.series.length'() {
            this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
        }
    },

    mounted() {
        this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
    }
}
</script>
