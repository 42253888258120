<template>
    <ui-async :guards="shares" v-slot:default="{ isLoaded }">
        <div class="max-w-3xl mx-auto space-y-4" v-if="isLoaded">
            <facebook-post-card :content="content" :stream-store="targetsTargetContentStreamStore" :key="content.id" v-for="content in shares.data"></facebook-post-card>

            <ui-infinite-loading @infinite="loadMore" v-if="shares.data?.length"></ui-infinite-loading>

            <div class="text-center text-sm text-gray-800 py-6" v-if="unavailableSharesCount > 0">
                Additional <span class="font-bold">{{ unavailableSharesCount }}</span> shares in unmonitored targets.
            </div>
            <div class="text-center text-sm text-gray-800 py-6" v-else-if="unavailableSharesCount !== null">
                This post has no shares in monitored targets.
            </div>
        </div>
    </ui-async>
</template>

<script>
import FacebookPostCard from '@/components/content/stream-cards/facebook-post'

import useTargetsTargetContentFacebookPostSharesStore from '@/stores/targets/target/content-facebook-post-shares'
import useTargetsTargetContentStreamStore from '@/stores/targets/target/content-stream'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: { FacebookPostCard },

    computed: {
        ...mapState(useTargetsTargetContentFacebookPostSharesStore, [
            'shares', 'unavailableSharesCount'
        ]),
        ...mapStores(useTargetsTargetContentStreamStore)
    },

    methods: {
        ...mapActions(useTargetsTargetContentFacebookPostSharesStore, [
            'loadMore'
        ])
    }
}
</script>
