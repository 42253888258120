<template>
    <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md flex flex-col h-96 px-6 py-4 mb-4">
        <div class="flex items-center justify-between pb-4">
            <h3 class="font-semibold text-gray-900">
                Activity
            </h3>

            <ui-dropdown align="right" width="w-48">
                <template v-slot:trigger>
                    <button class="flex items-center text-gray-700 text-sm hover:text-gray-900 focus:outline-none hover:bg-gray-50 focus:text-gray-900 transition px-3 py-1 rounded-full" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                        {{selectedDateOption ? selectedDateOption.name : ''}}
                        <ui-icon name="chevron-down" class="ml-1"></ui-icon>
                    </button>
                </template>

                <template v-slot:content>
                    <ui-dropdown-link @click="selectDate(dateOption)" :key="index" v-for="dateOption, index in dateOptions">
                        <div class="w-4 text-center inline-block">
                            <ui-icon name="check" v-if="selectedDateOption == dateOption"></ui-icon>
                        </div>
                        {{dateOption.name}}
                    </ui-dropdown-link>
                </template>
            </ui-dropdown>
        </div>

        <div class="w-full flex-1 min-h-0 relative">
            <ui-analysis class="" :store="featuresFeatureInformationActivityAnalysisStore"></ui-analysis>
        </div>
    </div>
</template>

<script>
import useFeaturesFeatureInformationActivityAnalysisStore from '@/stores/features/feature/information-activity-analysis'

import { mapStores } from 'pinia'

export default {
    data: () => ({
        dateOptions: [
            { name: 'Past Day', date: { type: 'past', date: { past: 1, unit: 'days' } }, granularity: 'hour' },
            { name: 'Past Week', date: { type: 'past', date: { past: 1, unit: 'weeks' } }, granularity: 'day' },
            { name: 'Past Month', date: { type: 'past', date: { past: 1, unit: 'months' } }, granularity: 'day' },
            { name: 'Past Year', date: { type: 'past', date: { past: 1, unit: 'years' } }, granularity: 'week' }
        ],

        selectedDateOption: null
    }),

    computed: {
        ...mapStores(useFeaturesFeatureInformationActivityAnalysisStore)
    },

    methods: {
        selectDate(dateOption) {
            this.selectedDateOption = dateOption

            this.featuresFeatureInformationActivityAnalysisStore.setDate(dateOption.date)
            this.featuresFeatureInformationActivityAnalysisStore.setGranularity(dateOption.granularity)
        }
    },

    mounted() {
        this.selectedDateOption = this.dateOptions[3]
    }
}
</script>
