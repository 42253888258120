<template>
    <ui-contextual-menu v-bind="$attrs" z-index="50">
        <template v-if="streamStore && streamStore.items.data && streamStore.items.data.length">
            <ui-dropdown-link icon="filter" @click="excludeTarget(target)">
                Exclude {{target.family == 'groups' ? 'Group' : 'Source'}}
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>
        </template>

        <lists-section align="right" :target="target" :list="list"></lists-section>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'targets.target.information', params: { type: target.family, modelType: target.type, id: target.id } }).href">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="external-link" :href="target.link" target="_blank" rel="noreferrer noopener" :disabled="! target.link">
            Open original
        </ui-dropdown-link>
        
        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="edit" @click="() => createNote(target)">
            Add note...
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="warning" @click="() => report('target', target.id)">
            Report
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(target)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import ListsSection from './target-lists'

import useMeModalsNoteStore from '@/stores/me/modals/note'
import useSupportReportStore from '@/stores/support/report'

import { mapActions } from 'pinia'

export default {
    components: { ListsSection },

    props: [ 'streamStore', 'target', 'list' ],

    methods: {
        ...mapActions(useMeModalsNoteStore, { createNote: 'create' }),
        ...mapActions(useSupportReportStore, [ 'report' ]),

        excludeTarget(target) {
            this.streamStore.filter('targets').set([
                ...(this.streamStore.filter('targets').value || []),
                {
                    id: this.target.id,
                    type: this.target.family == 'groups' ? 'group' : 'source',
                    name: this.target.title,
                    exclude: true
                }
            ])
        }
    }
}
</script>
