<template>
    <div class="py-2 text-sm">
        <div class="flex items-center">
            <a :href="$router.resolve({ name: 'targets.target.information', params: { type: reply.source.family, modelType: reply.source.type, id: reply.source.id } }).href" target="_blank">
                <ui-avatar :item="reply.source" class="w-7 h-7 mr-2"></ui-avatar>
            </a>

            <div>
                <a class="font-medium" :href="$router.resolve({ name: 'targets.target.information', params: { type: reply.source.family, modelType: reply.source.type, id: reply.source.id } }).href" target="_blank">
                    {{ reply.source.title }}
                </a>

                <a class="block text-2xs leading-none mt-0.5 text-gray-700" :href="$router.resolve({ name: 'targets.target.content', params: { type: reply.source.family, modelType: reply.source.type, id: reply.source.id, contentId: reply.id } }).href" target="_blank">
                    <span>{{ $dateTime(reply.publishedAt) }}</span>
                    <span class="ml-1 bg-red-100 text-red-500 py-0.5 rounded-sm px-2 cursor-default" v-if="deleted">Deleted</span>
                </a>
            </div>
        </div>
        <div class="mt-2">
            <span class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></span>
            <a href="#" v-if="! isShowingFullText" class="ml-1 text-blue-600" @click.prevent="readMore">Read more</a>
        </div>
        <div v-if="reply.images.length" class="flex flex-wrap mt-1">
            <img class="w-12 h-12 mr-2 object-contain rounded-md border border-gray-400 hover:border-gray-700 cursor-pointer" @click="showMedia(i)" :src="image.src" v-for="image, i in reply.images" :key="i" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'parent', 'reply', 'streamStore' ],

    data: () => ({
        showFullText: false
    }),

    computed: {
        isRTL() {
            return ['ar', 'arc', 'ha', 'khw', 'ks', 'ps', 'yi', 'az', 'dv', 'he', 'ku', 'ckb', 'fa', 'ur'].includes(this.reply.language)
        },

        isShowingFullText() {
            return this.showFullText || (this.reply.body || '').length <= 100
        },

        text() {
            return this.isShowingFullText ? this.reply.body : this.reply.body.replace(/\n\s*\n/g, "\n").substr(0, 100) + '...'
        },

        deleted() {
            return this.reply.labels.filter(l => l === 'deleted').length
        }
    },

    methods: {
        readMore() {
            this.showFullText = true
            this.streamStore.triggerLayoutUpdate([ this.parent ])
        },

        showMedia(currentIndex) {
            this.streamStore.mediaLightbox.open(this.reply.images, currentIndex)
        }
    }
}
</script>
