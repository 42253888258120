<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg w-full p-4 pt-0 flex flex-col min-w-0 relative" :class="isComparing ? 'h-96' : 'h-80'">
        <div class="flex justify-between">
            <div class="flex items-start gap-x-3">
                <div class="flex flex-col justify-center min-h-0 mx-3 cursor-pointer relative pt-4 overflow-hidden" :class="{ 'pt-5 pb-5': isComparing }" v-for="metric in metrics(series[0])" :key="metric.id" @click="selectedMetric = metric.id">
                    <div v-if="selectedMetric == metric.id" class="absolute -top-0.5 left-0 h-1.5 w-full rounded-full bg-blue-700"></div>
                    <div class="flex items-center gap-x-1 font-medium" :class="{ 'text-blue-600': selectedMetric == metric.id, 'text-gray-700': selectedMetric != metric.id, 'text-sm': ! isComparing }">
                        <ui-icon :name="metric.icon" class="text-lg"></ui-icon>
                        <h2>{{metric.name}}</h2>
                    </div>
                    <h1 class="text-3xl font-semibold" v-if="! isComparing">
                        {{$number(metric.current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous && ! isComparing">
                        <div class="text-sm border rounded leading-tight whitespace-nowrap px-1" :class="metric.previous < metric.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                            <span :class="metric.previous < metric.current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{metric.change}}%
                            </span>
                        </div>
                        <div class="text-sm">
                            {{$number(metric.previous)}}
                        </div>
                   </div>
                </div>
            </div>

            <div class="flex items-top gap-x-2 pt-4">
                <ui-dropdown width="w-auto">
                    <template v-slot:trigger>
                        <a href="#" class="font-medium text-gray-700">
                            More...
                        </a>
                    </template>
                    <template v-slot:content>
                        <div class="flex">
                            <div v-for="series, index in series" :key="index" class="w-64 divide-y divide-gray-200">
                                <div class="flex items-center px-4 py-3">
                                    <div class="flex-1 flex items-center gap-x-1.5 font-medium text-gray-700 text-lg">
                                        <ui-icon name="thumbs-up" class="text-xl"></ui-icon>
                                        <h2>Reactions</h2>
                                    </div>
                                    <div class="flex items-center gap-x-2">
                                        <h1 class="text-xl font-semibold">
                                            {{$number(detailedMetrics(series).reactions.current)}}
                                        </h1>
                                        <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="detailedMetrics(series).reactions.previous && ! isComparing">
                                            <div class="text-xs border rounded leading-tight whitespace-nowrap px-1 cursor-default" :class="detailedMetrics(series).reactions.previous < detailedMetrics(series).reactions.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                                                <span :class="detailedMetrics(series).reactions.previous < detailedMetrics(series).reactions.current ? 'text-green-600' : 'text-red-600'" v-tooltip="`Previously ${$number(detailedMetrics(series).reactions.previous)}`">
                                                    <ui-icon :name="detailedMetrics(series).reactions.previous < detailedMetrics(series).reactions.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                                    {{detailedMetrics(series).reactions.change}}%
                                                </span>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                                <div v-for="platform in detailedMetrics(series).platform" :key="platform.id" class="px-4 py-2">
                                    <div class="flex items-center justify-center gap-x-1 font-medium text-gray-700 text-xs">
                                        <ui-icon :name="platform.icon"></ui-icon>
                                        {{platform.name}}
                                    </div>
                                    <div v-for="metric in platform.metrics" :key="metric.id" class="flex items-center py-1">
                                        <div class="flex-1 flex items-center gap-x-1.5 font-medium text-gray-700 text-sm">
                                            <ui-icon :name="metric.icon" :as-image="platform.id == 'facebook'" class="text-xl"></ui-icon>
                                            <h2>{{metric.name}}</h2>
                                        </div>
                                        <div class="flex items-center gap-x-2">
                                            <h1 class="font-semibold">
                                                {{$number(metric.current)}}
                                            </h1>
                                            <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous">
                                                <div class="text-xs border rounded leading-tight whitespace-nowrap px-1 py-0.5 cursor-default" :class="metric.previous < metric.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'" v-tooltip="`${metric.previous < metric.current ? '↑' : '↓'} ${metric.change}%, previously ${$number(metric.previous)}`">
                                                    <span :class="metric.previous < metric.current ? 'text-green-600' : 'text-red-600'">
                                                        <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                                    </span>
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </ui-dropdown>

                <ui-data-quality-icon :quality="activeMetric(series[0]).dataQuality"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex-1 min-h-0">
            <chart :options="chartOptions" ref="chart" class="h-full rounded-2xl"></chart>
        </div>

        <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20 bg-gray-100': isInitialized, 'bg-white': ! isInitialized}" v-if="isLoading">
            <ui-spinner></ui-spinner>
        </div>

        <div v-if="isComparing" class="flex items-start gap-x-3">
            <div class="flex px-3 pt-6 pb-2 gap-x-2" v-for="series, index in series" :key="index">
                <div class="w-5 h-1 rounded mt-2" :style="{ 'background-color': series.color }"></div>
                <div class="flex flex-col justify-center min-h-0">
                    <div class="flex items-center gap-x-1 font-medium text-gray-700">
                        <h2 class="text-sm">
                            {{series.name}}
                        </h2>
                    </div>
                    <h1 class="text-3xl font-semibold">
                        {{$number(activeMetric(series).current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="activeMetric(series).previous">
                        <div class="text-sm border rounded leading-tight whitespace-nowrap px-1" :class="activeMetric(series).previous < activeMetric(series).current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                            <span :class="activeMetric(series).previous < activeMetric(series).current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="activeMetric(series).previous < activeMetric(series).current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{activeMetric(series).change}}%
                            </span>
                        </div>
                        <div class="text-sm">
                            {{$number(activeMetric(series).previous)}}
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

import { mapLocalActions, mapLocalState, mapLocalWritableState } from '@/helpers'

export default {
    components: {
        Chart
    },

    computed: {
        ...mapLocalState([ 'chartOptions', 'isComparing', 'isInitialized', 'isLoading', 'series' ]),
        ...mapLocalWritableState([ 'selectedMetric' ])
    },

    methods: {
        ...mapLocalActions([ 'activeMetric', 'detailedMetrics', 'metrics' ])
    }
}
</script>
