export default [
    // Root redirect to default section (content)
    { path: '', redirect: { name: 'content' } },

    // Public routes
    {
        component: require('./Main.vue').default,
        meta: { guestOnly: true },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: { page: require('@/pages/auth-login').default },
                component: require('./components/auth/login').default
            },
            {
                path: '/login/activate/:token',
                name: 'login.activate',
                meta: { page: require('@/pages/auth-activate').default },
                component: require('./components/auth/activate').default
            },
            {
                path: '/login/social',
                name: 'login.social',
                meta: { page: require('@/pages/auth-social').default },
                component: require('./components/ui/screen').default
            },
            {
                path: '/password-recovery/request',
                name: 'password-recovery.request',
                meta: { page: require('@/pages/auth-password-recovery-request').default },
                component: require('./components/auth/password-recovery-request').default
            },
            {
                path: '/password-recovery/reset/:token',
                name: 'password-recovery.reset',
                meta: { page: require('@/pages/auth-password-recovery-reset').default },
                component: require('./components/auth/password-recovery-reset').default
            }
        ]
    },

    // Application routes (requires authentication)
    {
        component: require('./App.vue').default,
        meta: { authenticatedOnly: true },
        children: [
            {
                path: '/analysis',
                components: {
                    default: require('./components/analysis/screen').default,
                    navigation: require('./components/analysis/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'analysis',
                        component: require('@/components/analysis/index').default,
                        meta: { page: require('@/pages/analysis-index').default }
                    },
                    {
                        path: 'analyses',
                        name: 'analysis.my-analyses',
                        component: require('@/components/analysis/my-analyses').default,
                        meta: { page: require('@/pages/analysis-my-analyses').default }
                    },
                    {
                        path: 'analyses/new',
                        name: 'analysis.my-analyses.new',
                        component: require('@/components/analysis/my-analyses-new').default,
                        meta: { page: require('@/pages/analysis-my-analyses-new').default }
                    },
                    {
                        path: ':type/:id',
                        name: 'analysis.analysis.details',
                        component: require('@/components/analysis/analysis').default,
                        meta: { page: require('@/pages/analysis-my-analyses-analysis').default }
                    }
                ]
            },

            {
                path: '/connections/:nodeId?',
                name: 'connections',
                component: require('./components/connections/screen').default,
                meta: { page: require('@/pages/connections').default }
            },

            {
                path: '/content',
                components: {
                    default: require('./components/content/screen').default,
                    navigation: require('./components/content/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'content',
                        component: require('./components/content/index').default,
                        meta: { page: require('@/pages/content-index').default }
                    },
                    {
                        path: 'perspectives',
                        name: 'content.perspectives',
                        component: require('./components/content/perspectives').default,
                        meta: { page: require('@/pages/content-perspectives').default }
                    },
                    {
                        path: 'perspectives/:perspectiveId',
                        name: 'content.perspectives.perspective',
                        component: require('./components/content/perspective').default,
                        meta: { page: require('@/pages/content-perspectives-perspective').default }
                    },
                    {
                        path: 'topics',
                        name: 'content.topics',
                        component: require('./components/content/topics').default,
                        meta: { page: require('@/pages/content-topics').default }
                    }
                ]
            },

            {
                path: '/dashboards',
                components: {
                    default: require('./components/dashboards/screen').default,
                    navigation: require('./components/dashboards/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'dashboards',
                        component: require('@/components/dashboards/index').default,
                        meta: { page: require('@/pages/dashboards-index').default }
                    },
                    {
                        path: ':id',
                        name: 'dashboard.boards.board',
                        component: require('@/components/dashboards/board').default,
                        meta: { page: require('@/pages/dashboards-board').default }
                    }
                ]
            },

            {
                path: '/features',
                components: {
                    default: require('./components/features/screen').default,
                    navigation: require('./components/features/navigation').default
                },
                children: [
                    {
                        path: ':type?',
                        name: 'features',
                        component: require('./components/features/library-details').default,
                        meta: { page: require('@/pages/features-library-details').default }
                    },
                    {
                        path: ':type/:id',
                        component: require('./components/features/feature').default,
                        meta: { page: require('@/pages/features-feature').default },
                        children: [
                            {
                                path: '',
                                name: 'features.feature.information',
                                component: require('./components/features/feature/information').default,
                                meta: { page: require('@/pages/features-feature-information').default }
                            },
                            {
                                path: 'stream',
                                name: 'features.feature.stream',
                                component: require('./components/features/feature/stream').default,
                                meta: { page: require('@/pages/features-feature-stream').default }
                            },
                            {
                                path: 'connections/:connectionsType?',
                                name: 'features.feature.connections',
                                component: require('./components/features/feature/connections').default,
                                meta: { page: require('@/pages/features-feature-connections').default }
                            }
                        ]
                    }
                ]
            },

            {
                path: '/settings',
                components: {
                    default: require('./components/settings/screen').default,
                    navigation: require('./components/settings/navigation').default
                },
                children: [
                    {
                        path: 'account',
                        name: 'settings.account',
                        component: require('./components/settings/account').default,
                        meta: { page: require('@/pages/settings-account').default },
                    },
                    {
                        path: 'account/login/social',
                        name: 'settings.account.login.social',
                        component: require('./components/ui/screen').default,
                        meta: { page: require('@/pages/settings-account-login-social').default }
                    },
                    {
                        path: 'apps',
                        name: 'settings.apps',
                        component: require('./components/settings/apps').default,
                        meta: { page: require('@/pages/settings-apps').default },
                    },
                ]
            },

            {
                path: '/targets',
                components: {
                    default: require('./components/targets/screen').default,
                    navigation: require('./components/targets/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'targets',
                        component: require('./components/targets/index').default,
                        meta: { page: require('@/pages/targets-index').default }
                    },
                    {
                        path: 'bookmarks',
                        name: 'targets.bookmarks',
                        component: require('./components/targets/bookmarks').default,
                        meta: { page: require('@/pages/targets-bookmarks').default }
                    },
                    {
                        path: 'my-lists',
                        name: 'targets.my-lists',
                        component: require('./components/targets/my-lists').default,
                        meta: { page: require('@/pages/targets-my-lists').default }
                    },
                    {
                        path: 'my-lists/:listId',
                        name: 'targets.my-lists.list',
                        component: require('./components/targets/list').default,
                        meta: { page: require('@/pages/targets-my-lists-list').default }
                    },
                    {
                        path: 'my-targets',
                        name: 'targets.my-targets',
                        component: require('./components/targets/my-targets').default,
                        meta: { page: require('@/pages/targets-my-targets').default }
                    },
                    {
                        path: 'curated-lists',
                        name: 'targets.curated-lists',
                        component: require('./components/targets/curated-lists').default,
                        meta: { page: require('@/pages/targets-curated-lists').default }
                    },
                    {
                        path: 'curated-lists/:categoryId',
                        name: 'targets.curated-lists.category',
                        component: require('./components/targets/curated-lists-category').default,
                        meta: { page: require('@/pages/targets-curated-lists-category').default }
                    },
                    {
                        path: 'library/search',
                        name: 'targets.library.search',
                        component: require('./components/targets/library-search').default,
                        meta: { page: require('@/pages/targets-library-search').default }
                    },
                    {
                        path: ':type/:modelType/:id',
                        component: require('./components/targets/target').default,
                        meta: { page: require('@/pages/targets-target').default },
                        children: [
                            {
                                path: '',
                                name: 'targets.target.information',
                                component: require('./components/targets/target/information').default,
                                meta: { page: require('@/pages/targets-target-information').default }
                            },
                            {
                                path: 'stream',
                                name: 'targets.target.stream',
                                component: require('./components/targets/target/stream').default,
                                meta: { page: require('@/pages/targets-target-stream').default }
                            },
                            {
                                path: 'history',
                                name: 'targets.target.history',
                                component: require('./components/targets/target/history').default,
                                meta: { page: require('@/pages/targets-target-history').default }
                            },
                            {
                                path: 'connections/:connectionsType?',
                                name: 'targets.target.connections',
                                component: require('./components/targets/target/connections').default,
                                meta: { page: require('@/pages/targets-target-connections').default }
                            },
                            {
                                path: 'members',
                                name: 'targets.target.members',
                                component: require('./components/targets/target/members').default,
                                meta: { page: require('@/pages/targets-target-members').default }
                            },
                            {
                                path: 'content/:contentId',
                                name: 'targets.target.content',
                                component: require('./components/targets/target/content').default,
                                meta: { page: require('@/pages/targets-target-content').default }
                            }
                        ]
                    }
                ]
            },

            {
                path: '/help',
                components: {
                    default: require('./components/support/help/screen').default,
                    navigation: require('./components/support/help/navigation/navigation').default
                },
                children: [
                    {
                        path: ':page/:section?',
                        name: 'help.page',
                        component: require('./components/support/help/page').default,
                        meta: { page: require('./pages/support-help-page').default }
                    },
                ]
            },

            {
                path: '/release-notes',
                components: {
                    default: require('./components/support/release-notes/screen').default,
                    navigation: require('./components/support/release-notes/navigation').default
                },
                children: [
                    {
                        path: ':page/:section?',
                        name: 'release-notes.page',
                        component: require('./components/support/release-notes/page').default,
                        meta: { page: require('./pages/support-release-notes').default }
                    }
                ]
            }
        ]
    },

    // Catch-all route for "not found" pages
    { path: '/:pathMatch(.*)*', redirect: { name: 'content' } }
]
