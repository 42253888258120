<template>
    <div class="relative">
        <div class="w-full h-48 p-2 cursor-pointer relative" @click="showMedia">
            <div class="w-full h-full" style="filter: drop-shadow(0 -8px 0 white) drop-shadow(0 8px 0 white) drop-shadow(-8px 0 0 white) drop-shadow(8px 0 0 white) drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.05));">
                <img class="w-full h-full object-contain" :src="media.type == 'video' ? media.thumbnailUrl : media.src" v-if="media.type == 'video' ? media.thumbnailUrl : media.src">
                <div class="w-full h-full bg-gray-500" v-else></div>

                <div class="absolute inset-0 flex items-center justify-center text-3xl text-white" v-if="media.type == 'video'">
                    <div class="w-16 h-16 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-full" v-if="media.src">
                        <ui-icon name="play"></ui-icon>
                    </div>
                    <div class="h-16 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 rounded-2xl px-4" v-else>
                        <div class="flex items-center">
                            <ui-icon name="warning" class="text-xl"></ui-icon>
                            <span class="text-lg ml-1">Video unavailable</span>
                        </div>
                        <div class="text-sm mt-1">
                            Click to learn more
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'content', 'media', 'streamStore' ],

    methods: {
        showMedia() {
            this.streamStore.mediaLightbox.open([ this.media ], 0)
        }
    }
}
</script>
