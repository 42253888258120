<template>
    <ui-header title="My Targets">
        <template v-slot:actions>
            <ui-button icon="plus-square" color="blue" @click="addTarget">
                Monitor Target
            </ui-button>

            <ui-help-button v-tooltip="'My Targets'" page="my-targets"></ui-help-button>
        </template>
    </ui-header>

    <div class="px-8 pb-16 max-w-7xl mx-auto">
        <ui-async :guards="! isLoadingStats" class="bg-white w-full shadow rounded-lg flex items-center mb-4 p-2 space-x-2">
            <div class="relative px-5 py-3 bg-gray-50 rounded-lg">
                <div class="text-blue-700">
                    <span class="text-3xl font-medium">{{stats.current.monitoredTargets}} </span>
                    <span>/ {{stats.limits.monitoredTargets}}</span>
                </div>
                <div class="text-sm text-gray-800 font-semibold">
                    Monitored Targets
                </div>
                <div class="mt-0.5">
                    <div class="bg-blue-100 h-1 w-28 rounded-full">
                        <div class="bg-blue-500 h-1 rounded-full" :style="`width:${Math.min(stats.current.monitoredTargets / stats.limits.monitoredTargets * 100, 100)}%;`"></div>
                    </div>
                </div>
            </div>

            <div class="flex-1"></div>

            <div class="px-5 py-3 bg-gray-50 rounded-lg">
                <div class="text-blue-700">
                    <span class="text-2xl font-medium">{{stats.current.imageTextExtraction}} </span>
                    <span class="text-sm">/ {{stats.limits.imageTextExtraction}}</span>
                </div>
                <div class="text-xs text-gray-800 font-semibold">
                    w/ Image Text Extraction
                </div>
                <div class="mt-0.5">
                    <div class="bg-gray-100 h-1 w-24 rounded-full">
                        <div class="bg-gray-500 h-1 rounded-full" :style="`width:${stats.current.imageTextExtraction / stats.limits.imageTextExtraction * 100}%;`"></div>
                    </div>
                </div>
            </div>

            <div class="px-5 py-3 bg-gray-50 rounded-lg">
                <div class="text-blue-700">
                    <span class="text-2xl font-medium">{{stats.current.videoDownload}} </span>
                    <span class="text-sm">/ {{stats.limits.videoDownload}}</span>
                </div>
                <div class="text-xs text-gray-800 font-semibold">
                    w/ Video Download
                </div>
                <div class="mt-0.5">
                    <div class="bg-gray-100 h-1 w-24 rounded-full">
                        <div class="bg-gray-500 h-1 rounded-full" :style="`width:${stats.current.videoDownload / stats.limits.videoDownload * 100}%;`"></div>
                    </div>
                </div>
            </div>

            <div class="px-5 py-3 bg-gray-50 rounded-lg">
                <div class="text-blue-700">
                    <span class="text-2xl font-medium">{{stats.current.videoTranscription}} </span>
                    <span class="text-sm">/ {{stats.limits.videoTranscription}}</span>
                </div>
                <div class="text-xs text-gray-800 font-semibold">
                    w/ Video Transcription
                </div>
                <div class="mt-0.5">
                    <div class="bg-gray-100 h-1 w-24 rounded-full">
                        <div class="bg-gray-500 h-1 rounded-full" :style="`width:${stats.current.videoTranscription / stats.limits.videoTranscription * 100}%;`"></div>
                    </div>
                </div>
            </div>
        </ui-async>

        <div class="mt-12" v-if="isLoadingRequests || requests.length">
            <div class="flex items-center justify-between mb-2">
                <h1 class="px-2 text-xl text-gray-900 font-semibold">
                    Monitoring Requests
                </h1>
                <a class="cursor-pointer float-right text-blue-600 hover:text-blue-800" v-if="requests.length > 4" @click.prevent="showAllMonitoringRequests">Show more</a>
            </div>

            <div class="bg-white w-full rounded-lg mb-4 p-2 shadow">
                <monitoring-requests :requests="requests"></monitoring-requests>
            </div>
        </div>

        <div class="mt-12">
            <div class="flex items-center justify-between mb-2">
                <h1 class="px-2 text-xl text-gray-900 font-semibold">
                    Monitored Targets
                </h1>

                <div class="flex items-center space-x-5">
                    <a href="#" class="inline-flex items-center text-sm text-blue-500 hover:text-blue-800 font-medium" @click.prevent="exportMyTargets">
                        <ui-icon name="download" class="text-xl mr-1"></ui-icon>
                        Export
                    </a>

                    <ui-dropdown>
                        <template v-slot:trigger>
                            <a href="#" class="inline-flex items-center text-sm text-blue-500 hover:text-blue-800 font-medium">
                                {{selectedFilter.name}}
                                <ui-icon name="select"></ui-icon>
                            </a>
                        </template>
                        <template v-slot:content>
                            <template :key="index" v-for="option, index in filterOptions">
                                <ui-dropdown-link :selected="option.id == selectedFilter.id" @click="setMyTargetsFilter(option.id)" v-if="option">{{option.name}}</ui-dropdown-link>
                                <ui-dropdown-separator v-else></ui-dropdown-separator>
                            </template>
                        </template>
                    </ui-dropdown>

                    <ui-input placeholder="Search..." icon="search" class="rounded-md bg-gray-200 border-gray-300 placeholder-gray-600" v-model="myTargetsSearch" @keyup="searchMyTargets"></ui-input>
                </div>
            </div>

            <ui-async :guards="! isLoadingTargets" class="bg-white w-full shadow rounded-lg mb-4 divide-y divide-gray-100">
                <div class="bg-white flex h-16 group relative first:rounded-t-lg last:rounded-b-lg" :key="target.id" v-for="target in targets">
                    <div class="pl-6 flex items-center flex-1 min-w-0">
                        <div class="shrink-0 relative">
                            <ui-avatar :item="target" class="w-8 h-8"></ui-avatar>
                            <ui-icon :name="`badges.target-${target.type}`" v-tooltip="targetTypeToHuman(target.type)" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! target.monitored }"></ui-icon>
                        </div>

                        <div class="flex-1 min-w-0 px-3">
                            <a :href="$router.resolve({ name: 'targets.target.information', params: { type: target.family, modelType: target.type, id: target.id } }).href" target="_blank">
                                <h1 class="text-xl font-semibold truncate leading-tight">
                                    <span v-tooltip="target.title">{{ target.title }}</span>
                                    <div class="h-4 w-4 rounded-full bg-blue-500 text-white flex items-center justify-center text-xs inline-flex ml-2 -mb-1"
                                         v-if="target.verified">
                                        <ui-icon name="check" class="text-2xs"></ui-icon>
                                    </div>
                                </h1>
                            </a>
                            <div class="text-gray-800 text-xs leading-tight truncate">
                                {{targetTypeToHuman(target.type)}}
                                <span v-if="target.username" v-tooltip="target.username">
                                    · {{target.username}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex space-x-2 items-center px-3 text-gray-900" v-if="target.monitoringOptions && target.monitoringOptions.length">
                        <span class="px-1 bg-blue-100 text-blue-900 text-2xs rounded" :key="option" v-for="option in target.monitoringOptions">
                            {{monitoringOptionInfo(option).name}}
                        </span>
                    </div>

                    <div class="flex space-x-2 items-center px-3 text-gray-900" v-if="target.lists && target.lists.length">
                        <ui-avatar class="w-6 h-6" :item="list" :key="list.id" v-tooltip="list.name" v-for="list in target.lists"></ui-avatar>
                    </div>

                    <div class="flex items-center space-x-5 px-4 w-64 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner">
                        <div>
                            <div class="text-2xs text-gray-700 leading-tight">
                                {{ target.family === 'sources' ? 'Followers' : 'Members' }}
                            </div>
                            <div class="font-semibold text-sm">
                                <template v-if="target.family === 'sources'">
                                    {{target.metrics.followers !== null && target.metrics.followers !== undefined ? $number(target.metrics.followers) : '-'}}
                                </template>
                                <template v-else>
                                    {{target.metrics.members !== null && target.metrics.members !== undefined ? $number(target.metrics.members) : '-'}}
                                </template>
                            </div>
                        </div>

                        <div>
                            <div class="text-2xs text-gray-700 leading-tight">
                                Activity ⌀
                            </div>
                            <div class="font-semibold text-sm">
                                {{target.metrics.publishedPerWeek !== null && target.metrics.publishedPerWeek !== undefined ? $number(Math.round(target.metrics.publishedPerWeek)) : '-'}}
                            </div>
                        </div>

                        <div>
                            <div class="text-2xs text-gray-700 leading-tight">
                                Inters. ⌀
                            </div>
                            <div class="font-semibold text-sm">
                                {{target.metrics.interactionsPerWeek !== null && target.metrics.interactionsPerWeek !== undefined ? $number(Math.round(target.metrics.interactionsPerWeek)) : '-'}}
                            </div>
                        </div>

                        <div class="flex items-center justify-end flex-1 space-x-2 text-gray-500">
                            <contextual-menu :target="target"></contextual-menu>
                        </div>
                    </div>
                </div>

                <div class="bg-white flex items-center justify-center h-16 rounded-lg" v-if="! isLoadingTargets && ! targets.length">
                    <span class="text-gray-600">No matching targets.</span>
                </div>

                <div class="py-4" v-if="targets.length">
                    <ui-infinite-loading @infinite="loadMoreMyTargets" :identifier="lastReloadTime"></ui-infinite-loading>
                </div>
            </ui-async>
        </div>
    </div>

    <Teleport to="#root">
        <my-targets-options-resource-unavailable></my-targets-options-resource-unavailable>
        <target-history-download-modal></target-history-download-modal>
        <my-targets-monitoring-requests-modal></my-targets-monitoring-requests-modal>
    </Teleport>
</template>

<script>
import ContextualMenu from '@/components/targets/contextual-menus/monitored-target'
import MyTargetsOptionsResourceUnavailable from '@/components/targets/modals/my-targets-options-resource-unavailable'
import TargetHistoryDownloadModal from '@/components/targets/modals/history-download'
import MyTargetsMonitoringRequestsModal from "@/components/targets/modals/my-targets-monitoring-requests"
import MonitoringRequests from "@/components/targets/my-targets/monitoring-requests"

import useMyMonitoredTargetsStore from '@/stores/me/monitored-targets'
import useTargetsMyListsCardsStore from '@/stores/targets/my-lists-cards'
import useTargetsModalsAddTargetStore from '@/stores/targets/modals/library-add-target'
import useTargetsModalsListEditStore from '@/stores/targets/modals/list-edit'
import useTargetsMyTargetsMonitoringRequestsStore from '@/stores/targets/my-targets/monitoring-requests'
import useTargetsMyTargetsStatsStore from '@/stores/targets/my-targets/stats'
import useTargetsTargetTypesStore from '@/stores/targets/target-types'

import { mapActions, mapState, mapWritableState, mapStores } from 'pinia'
import debounce from 'just-debounce-it'

export default {
    components: {
        MonitoringRequests, ContextualMenu, MyTargetsOptionsResourceUnavailable, TargetHistoryDownloadModal, MyTargetsMonitoringRequestsModal
    },

    data: () => ({
        monitoringOptions: [
            { name: 'Image Text Extraction', id: 'imageTextExtraction' },
            { name: 'Video Download', id: 'videoDownload' },
            { name: 'Video Transcription', id: 'videoTranscription' }
        ]
    }),

    computed: {
        ...mapState(useMyMonitoredTargetsStore, {
            filterOptions: 'filterOptions', isLoadingTargets: 'isLoading', lastReloadTime: 'lastReloadTime',
            selectedFilter: 'selectedFilter', targets: 'items'
        }),
        ...mapWritableState(useMyMonitoredTargetsStore, {
            myTargetsSearch: 'search'
        }),
        ...mapState(useTargetsMyTargetsMonitoringRequestsStore, {
            isLoadingRequests: 'isLoading',
            requests: store => store.requests.slice(0, 5)
        }),
        ...mapState(useTargetsMyTargetsStatsStore, {
            isLoadingStats: 'isLoading', stats: 'stats'
        }),
        ...mapStores(useTargetsMyListsCardsStore)
    },

    methods: {
        ...mapActions(useMyMonitoredTargetsStore, {
            exportMyTargets: 'export', loadMoreMyTargets: 'loadMore', setMyTargetsFilter: 'setFilter'
        }),
        ...mapActions(useTargetsModalsAddTargetStore, { 'addTarget': 'open' }),
        ...mapActions(useTargetsModalsListEditStore, { 'createList': 'open' }),
        ...mapActions(useTargetsTargetTypesStore, { 'targetTypeToHuman': 'typeToHuman' }),
        ...mapActions(useTargetsMyTargetsMonitoringRequestsStore, { 'showAllMonitoringRequests': 'open' }),

        monitoringOptionInfo(option) {
            return this.monitoringOptions.find(o => o.id == option)
        },

        searchMyTargets: debounce(function() {
            useMyMonitoredTargetsStore().reload()
        }, 250)
    }
}
</script>
