<template>
    <ui-contextual-menu z-index="50">
        <template v-if="targetList.shared">
            <ui-dropdown-link icon="subscription" @click="subscribeToList(targetList)" v-if="! findList(targetList.id)">
                Subscribe
            </ui-dropdown-link>
            <ui-dropdown-link icon="subscription" @click="unsubscribeFromList(targetList)" v-else>
                Unsubscribe
            </ui-dropdown-link>
        </template>

        <template v-else>
            <ui-dropdown-link icon="pin-off" @click="updateList(targetList, { favorite: 1 })" v-if="! targetList.pinned">
               Pin
            </ui-dropdown-link>
            <ui-dropdown-link icon="pin-off" @click="updateList(targetList, { favorite: 0 })" v-if="targetList.pinned">
               Un-pin
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="settings" @click="editList(targetList)">
               Settings
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="merge" @click="mergeList(targetList)">
               Merge
            </ui-dropdown-link>
            <ui-dropdown-link icon="copy" @click="duplicateList(targetList)">
               Duplicate
            </ui-dropdown-link>
            <ui-dropdown-link icon="trash" @click="deleteList(targetList)">
               Delete
            </ui-dropdown-link>
        </template>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(targetList)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTargetsModalsListEditStore from '@/stores/targets/modals/list-edit'
import useTargetsModalsListDuplicateStore from '@/stores/targets/modals/list-duplicate'
import useTargetsModalsListMergeStore from '@/stores/targets/modals/list-merge'

import { mapActions } from 'pinia'

export default {
    props: [ 'targetList' ],

    methods: {
        ...mapActions(useMyTargetListsStore, [ 'subscribeToList', 'unsubscribeFromList' ]),
        ...mapActions(useMyTargetListsStore, {
            'deleteList': 'delete',
            'findList': 'find',
            'updateList': 'update'
        }),
        ...mapActions(useTargetsModalsListEditStore, { 'editList': 'open' }),
        ...mapActions(useTargetsModalsListDuplicateStore, { 'duplicateList': 'open' }),
        ...mapActions(useTargetsModalsListMergeStore, { 'mergeList': 'open' })
    }
}
</script>
