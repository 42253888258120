Keywords,<template>
    <div>
        <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="! store.series.length">
            <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
            <h1 class="text-2xl font-semibold my-6">Welcome to the "Content Keywords" analysis!</h1>
            <p class="text-lg my-1">This analysis <span class="font-medium">visualizes the prevalence of the most common keywords</span> in the content in the dataset.</p>
            <div class="flex space-x-10 my-10">
                <div class="flex-1">
                    <h2 class="font-semibold">Data-set</h2>
                    <p class="text-justify my-2">Select a Perspective, Topic or Target List as a data-set, or define a new perspective inline.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Sizes</h2>
                    <p class="text-justify my-2">The more the a keyword occurs in the series, the larger its final size will be in the keyword cloud</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Range</h2>
                    <p class="text-justify my-2">Use the range controls to specify the date range and see keywords for that period.</p>
                </div>
            </div>
            <p class="text-lg my-1">
                To start let's <span class="font-medium">add some data</span> to the analysis by clicking on the Select Data-set button.
            </p>
            <div class="flex items-center mt-8">
                <ui-button color="blue" icon="plus-square" size="lg" :action="() => store.changeDataset()">
                    Select Data-set
                </ui-button>
            </div>
        </div>

        <div v-else>
            <analysis-toolbar :store="store">
                <template v-slot:settings>
                    <settings-dataset :store="store"></settings-dataset>
                </template>
            </analysis-toolbar>

            <div class="px-4 py-8 h-128 bg-white border border-gray-200 rounded-lg shadow-sm">
                <div class="relative h-full flex justify-center">
                    <keywords :store="store" ref="analysis"></keywords>
                </div>

                <p v-if="store.analysisSeries" class="text-sm text-gray-700 text-center">
                    Up to 1000 posts are analyzed per series. The analysis is based on the content of the posts, not the title or other metadata.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import AnalysisToolbar from './toolbar/toolbar'
import Keywords from './keywords/keywords'
import SettingsDataset from './settings/dataset'

export default {
    components: {
        AnalysisToolbar, Keywords, SettingsDataset
    },

    watch: {
        'store.series.length'() {
            this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
        }
    },

    mounted() {
        this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
    }
}
</script>
