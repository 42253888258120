import useMyBroadcasterStore from '@/stores/me/broadcaster'
import useMyStore from '@/stores/me/my'
import useSupportChatStore from '@/stores/support/chat'

import api from '@/api'
import { downloadUrl, sortByDate, useRouter } from '@/helpers'
import { debug } from '@/helpers/logger'

import { isToday, parseISO } from 'date-fns'
import { defineStore } from 'pinia'

export const useMyNotificationsStore = defineStore({
    id: 'myNotifications',

    state: () => ({
        broadcaster: null,

        isInitialized: false,
        isSubscribed: false,
        isOverlayShown: false,
        loadingPromise: null,
        isLoading: false,
        isShowingAllNotifications: false,

        toasts: [],
        lastToastId: 0,
        notifications: [],
        tasks: []
    }),

    getters: {
        shownNotifications: store => {
            let notifications = sortByDate(store.notifications).reverse()
            let todayNotifications = notifications.filter(n => isToday(parseISO(n.createdAt)))

            if (store.isShowingAllNotifications) return notifications

            return todayNotifications.length ? todayNotifications : notifications.slice(-1)
        },

        unreadNotificationsCount(state) {
            return state.notifications.filter(n => n.readAt === null).length
        }
    },

    actions: {
        async initialize() {
            this.subscribe()
            await this.load()
        },

        toggle() {
            this.isOverlayShown ? this.hide() : this.show()
        },

        show() {
            useSupportChatStore().hide()

            this.isOverlayShown = true

            this.dismissAllToasts()
            this.loadNew()
        },

        hide() {
            if (! this.isOverlayShown) return

            this.isOverlayShown = false

            api.route('me notifications mark-as-read')
                .json({
                    ids: this.notifications.filter(n => n.readAt === null).map(n => n.id)
                }).post()

            this.notifications.forEach(n => n.readAt = new Date())
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me notifications').get().json(res => {
                this.notifications = []

                res.data.forEach(n => {
                    n.onAction = () => this.resolveAction(n)
                    this.notifications.push(n)
                })

                this.isLoading = false
                this.isInitialized = true
            })
        },

        loadNew() {
            this.isLoading = true

            api.route('me notifications')
                .query({ after: this.notifications[0]?.createdAt })
                .get()
                .json(res => {
                    res.data.forEach(n => {
                        n.onAction = () => this.resolveAction(n)
                        this.notifications.unshift(n)
                    })

                    this.isLoading = false
                })
        },

        loadMore($state) {
            let lastNotification = this.notifications[this.notifications.length - 1]

            if (! lastNotification) return $state.complete()

            api.route('me notifications')
                .query({ before: lastNotification.createdAt })
                .get()
                .json(res => {
                    if (! res.data.length) {
                        return $state.complete()
                    }

                    res.data.forEach(n => {
                        n.onAction = () => this.resolveAction(n)
                        this.notifications.push(n)
                    })

                    $state.loaded()
                })
        },

        subscribe() {
            useMyBroadcasterStore().broadcaster.private(`workspaces.${useMyStore().currentWorkspace.id}`)
                .notification((notification) => this.processIncoming(notification))

            useMyBroadcasterStore().broadcaster.private(`users.${useMyStore().user.id}`)
                .notification((notification) => this.processIncoming(notification))
        },

        processIncoming(notification) {
            this.loadNew()

            debug('Notifications - incoming notification', notification)

            if (notification.type.startsWith('task.')) {
                return this.processIncomingTask(notification)
            }

            if (notification.data?.toasted) {
                this.pushToast({
                    type: notification.data.level,
                    title: notification.data.title,
                    text: notification.data.description,
                    action: notification.data.action ?? null,
                    onAction: () => this.resolveAction(notification)
                })
            }
        },

        processIncomingTask(incomingTask) {
            let task = this.resolveTask(incomingTask.taskId)

            Object.assign(task, {
                state: incomingTask.state,
                payload: incomingTask.payload,
                progressCompletedUnits: incomingTask.completedUnits,
                progressTotalUnits: incomingTask.totalUnits
            })
            
            if (incomingTask.type == 'task.completed') task.onCompleted()

            if (! incomingTask.notification) return

            let toast = this.toasts.find(t => t.taskId == incomingTask.taskId)

            let toastData = {
                title: incomingTask.notification.title,
                text: incomingTask.notification.description,
                icon: incomingTask.notification.icon,
                type: incomingTask.state == 'completed' ? 'success' : 'info',
                taskId: incomingTask.taskId,
                progressCompletedUnits: incomingTask.completedUnits || toast?.progressCompletedUnits,
                progressTotalUnits: incomingTask.totalUnits || toast?.progressTotalUnits
            }

            if (toast) {
                Object.assign(toast, toastData)
            } else {
                this.pushToast(toastData)
            }
        },

        pushToast(toast) {
            if (toast.uniqueId && this.toasts.find(t => t.uniqueId === toast.uniqueId)) { return }

            this.toasts.push(toast = { ...toast, id: ++this.lastToastId, createdAt: new Date })

            let originalOnAction = toast.onAction
            toast.onAction = () => {
                originalOnAction && originalOnAction(toast)
                this.dismissToast(toast)
            }

            if (toast.lifetime) {
                setTimeout(() => this.dismissToast(toast), toast.lifetime * 1000)
            }

            return toast
        },

        dismissToast(toast) {
            if (toast.onDismiss) toast.onDismiss()

            this.toasts = this.toasts.filter(t => t.id != toast.id)
        },

        dismissAllToasts() {
            this.toasts = []
        },

        resolveAction(notification) {
            if (! notification.data.actionMeta) return

            if (notification.data.actionMeta.type == 'link') {
                if (notification.data.actionMeta.url[0] == '/') {
                    // local url, use router to navigate to the url to avoid full app reload
                    useRouter().replace({ path: notification.data.actionMeta.url })
                } else {
                    window.open(notification.data.actionMeta.url, notification.data.actionMeta.target ?? '_self')
                }
            } else if (notification.data.actionMeta.type == 'download') {
                downloadUrl(notification.data.actionMeta.url)
            }
        },

        reportApiError() {
            this.pushToast({
                type: 'error',
                title: 'Unexpected error',
                text: 'You might need to reload the page to recover normal functionality.<br>Please contact us if the problem persists.',
                action: 'Reload',
                onAction: toast => window.location.reload()
            })
        },

        reportForbiddenError() {
            this.pushToast({
                type: 'error',
                title: 'Permission error',
                text: 'You don\'t have permission to perform this action.<br>Please contact us if the problem persists.',
                action: 'Reload',
                onAction: toast => window.location.reload()
            })
        },

        resolveTask(id) {
            let task = this.tasks.find(t => t.id == id)

            if (! task) this.tasks.push(task = { id, state: 'waiting', payload: {}, onCompleted: () => {} })

            return task
        },

        showAllNotifications() {
            this.isShowingAllNotifications = true
        }
    }
})

export default useMyNotificationsStore
