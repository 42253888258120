<template>
    <div>
        <component :is="store.layout.component" :store="store" :layout-width="layoutWidth">
            <template v-slot:item="{ item }">
                <slot name="item" :item="item" :cards-store="store">
                    <target-card :target="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'sources' || item.family == 'groups'"></target-card>

                    <perspective-card :perspective="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'perspectives'"></perspective-card>

                    <topic-card :topic="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'topics'"></topic-card>

                    <list-card :list="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'lists'"></list-card>

                    <analysis-card :analysis="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'analyses'"></analysis-card>

                    <board-card :board="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'dashboards' && item.type == 'board'"></board-card>
                </slot>
            </template>

            <template v-slot:placeholder="{ item }">
                <slot name="placeholder" :item="item" :cards-store="store">
                    <perspective-placeholder-card v-if="store.layout.id == 'grid'"></perspective-placeholder-card>
                    <perspective-placeholder-row v-if="store.layout.id == 'table'"></perspective-placeholder-row>
                </slot>
            </template>
        </component>
    </div>
</template>

<script>
import AnalysisCard from '@/components/analysis/stream-cards/analysis-card'
import BoardCard from '@/components/dashboards/stream-cards/board-card'
import ListCard from '@/components/targets/stream-cards/list'
import PerspectiveCard from '@/components/content/stream-cards/perspective'
import PerspectivePlaceholderCard from '@/components/content/stream-cards/perspective-placeholder'
import PerspectivePlaceholderRow from '@/components/content/stream-cards/perspective-placeholder-row'
import TargetCard from '@/components/targets/stream-cards/target'
import TopicCard from '@/components/content/stream-cards/topic'

import { breakpoint } from '@/helpers'

export default {
    components: {
        AnalysisCard, BoardCard, ListCard, PerspectiveCard, PerspectivePlaceholderCard, PerspectivePlaceholderRow,
        TargetCard, TopicCard
    },

    data: () => ({
        breakpoint: breakpoint,
        layoutWidth: null
    }),

    methods: {
        updateLayouts() {
            this.layoutWidth = this.$el.getBoundingClientRect().width
            this.store.refreshLayouts && this.store.refreshLayouts(this.layoutWidth)
        }
    },

    watch: {
        'breakpoint.w'() { this.updateLayouts() },
        '$my.showSidebar'() { setTimeout(() => this.updateLayouts(), 200) }
    },

    mounted() {
        this.updateLayouts()
    }
}
</script>
