 <template>
    <div>
        <div class="mb-6 mx-auto">
            <div class="flex justify-between items-center">
                <div class="flex items-center gap-x-1.5">
                    <h1 class="text-2xl font-semibold leading-none" v-if="title">
                        {{title}}
                    </h1>
                </div>

                <div class="flex items-center space-x-4">
                    <div class="text-sm">
                        <span class="font-medium">{{ $number(store.items.length) }}</span>
                        results
                    </div>

                    <results-sorting :store="store" v-if="store.availableSortingOptions.length > 1"></results-sorting>
                    <results-view :store="store"></results-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ResultsSorting from './results-sorting'
import ResultsView from './results-view'

export default {
    props: [ 'title' ],

    components: {
        ResultsSorting, ResultsView
    }
}
</script>
