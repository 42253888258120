<template>
    <div class="flex items-center justify-center max-w-full" :class="{ 'mt-3': ! store.compactToolbar, 'ml-3': store.compactToolbar }">
        <div class="flex items-center flex-wrap relative gap-2" v-if="visibleFilters.length">
            <template v-for="filter in visibleFilters">
                <filter-date :filter="filter" :store="store" :key="filter.id" v-if="filter.type == 'date'"></filter-date>
                <filter-labels :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'labels'"></filter-labels>
                <filter-lists :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'target-list'"></filter-lists>
                <filter-targets :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'targets'"></filter-targets>
                <filter-media :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'media'"></filter-media>
                <filter-mentions :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'mentions'"></filter-mentions>
                <filter-metrics :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'metrics'"></filter-metrics>
                <filter-notification :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'notification'"></filter-notification>
                <filter-options :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'options'"></filter-options>
                <filter-semantic-query :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'semantic-query'"></filter-semantic-query>
                <filter-tags :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'tags'"></filter-tags>
                <filter-performance :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'performance'"></filter-performance>
                <filter-features :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'features'"></filter-features>
                <filter-deleted :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'deleted'"></filter-deleted>
                <filter-types :filter="filter" :store="store" :key="filter.id" v-else-if="filter.type == 'types'"></filter-types>
            </template>

            <div class="flex shrink-0 items-center" v-if="showClearButton">
                <div class="border-l border-gray-200 mx-1 h-7"></div>

                <a href="#" @click="store.removeAllFilters" class="flex items-center ml-2 px-3 leading-none h-7 text-sm font-medium hover:bg-gray-50 rounded-sm max-w-full">
                    <ui-icon name="x-rounded" class="mr-1"></ui-icon>
                    Clear filters
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import FilterDate from '@/components/ui/stream/filters/filter-date'
import FilterLabels from '@/components/ui/stream/filters/filter-labels'
import FilterLists from '@/components/ui/stream/filters/filter-lists'
import FilterTargets from '@/components/ui/stream/filters/filter-targets'
import FilterMedia from '@/components/ui/stream/filters/filter-media'
import FilterMetrics from '@/components/ui/stream/filters/filter-metrics'
import FilterMentions from '@/components/ui/stream/filters/filter-mentions'
import FilterNotification from '@/components/ui/stream/filters/filter-notification'
import FilterOptions from '@/components/ui/stream/filters/filter-options'
import FilterSemanticQuery from '@/components/ui/stream/filters/filter-semantic-query'
import FilterTags from '@/components/ui/stream/filters/filter-tags'
import FilterPerformance from '@/components/ui/stream/filters/filter-performance'
import FilterFeatures from '@/components/ui/stream/filters/filter-features'
import FilterDeleted from '@/components/ui/stream/filters/filter-deleted'
import FilterTypes from '@/components/ui/stream/filters/filter-types'

export default {
    components: {
        FilterDate, FilterLabels, FilterLists, FilterTargets, FilterMedia, FilterMetrics, FilterMentions, FilterNotification,
        FilterOptions, FilterSemanticQuery, FilterTags, FilterPerformance, FilterFeatures, FilterDeleted, FilterTypes
    },

    computed: {
        showClearButton() {
            return this.store.appliedFilters.filter(f => ! f.filter.hidden && ! f.filter.readOnly).length
        },

        visibleFilters() {
            return this.store.filters.filter(f => ! f.hidden)
        }
    }
}
</script>
