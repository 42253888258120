<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar family="targets" :name="result.title" :image="result.avatarImageUrl" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
                <ui-icon :name="`badges.target-${result.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-base" :class="{ 'grayscale': ! result.flags.includes('monitored') }"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-900">
                    <div class="truncate">{{result.title}}</div>
                    <div class="h-3 w-3 rounded-full bg-blue-500 text-white flex items-center justify-center text-2xs inline-flex ml-1"
                         v-if="result.flags.includes('verified')">
                        <ui-icon name="check"></ui-icon>
                    </div>
                </div>

                <div class="text-xs leading-4 text-gray-700 truncate">
                    {{typeName}} · <span class="capitalize">{{family}}</span> · {{result.description.length ? result.description.join(' · ') + ' · ' : ''}} {{$number(result.followers)}} {{ family == 'group' ? 'members' : 'followers' }}
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
import targetTypesStore from '@/stores/targets/target-types'

export default {
    props: [ 'focused', 'result' ],

    computed: {
        family() {
            return this.result.id.charAt(0) === 'G' ? 'groups' : 'sources'
        },

        to() {
            return { name: 'targets.target.information', params: { type: this.family, modelType: this.result.type, id: this.result.monitorId } }
        },

        typeName() {
            return targetTypesStore().typeToHuman(this.result.type)
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
