<template>
    <div class="flex-1 flex" v-if="value !== null">
        <div class="flex-1 flex items-center justify-between truncate">
            <div class="flex-1 pr-5 truncate">
                <p class="text-gray-700 text-sm font-medium">{{title}}</p>
                <p class="text-blue-600 text-xl font-semibold leading-none mt-1" :title="value">{{ $number(value) }}</p>
            </div>
        </div>
        <!--<div class="shrink-0 flex items-center justify-center w-32 text-white text-sm font-medium" v-if="analysisStore">-->
        <!--    <ui-spark-analysis :store="analysisStore"></ui-spark-analysis>-->
        <!--</div>-->
    </div>
</template>

<script>
export default {
    props: [ 'analysisStore', 'title', 'value' ]
}
</script>
