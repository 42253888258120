import api from '@/api'
import { number } from '@/helpers'

import { defineStore } from 'pinia'

export const defineOverviewSentimentShareStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        type: 'content',

        series: [{
            name: null,
            color: null,
            filters: null,
            aggregates: null,
            isLoading: false
        }],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{ ...series, aggregates: [], isLoading: false }]

            await this.load()

            this.isInitialized = true
        },

        async compareWith(series) {
            let seriesRaw

            this.series.push(seriesRaw = { ...series, aggregates: [], isLoading: false })

            series = this.series.find(s => s.id == series.id)

            await this.loadAggregates(series)
        },

        stopComparing(series) {
            this.series = this.series.filter(s => s.id != series.id)
        },

        toggleType() {
            this.type = this.type == 'content' ? 'replies' : 'content'
        },

        async load() {
            return Promise.all(this.series.map(series => this.loadAggregates(series)))
        },

        loadAggregates(series) {
//            series = this.series.find(s => s.id == series.id) // This fixes a reactivity issue, with updating the series data

            series.isLoading = true

            return api.route('monitor content')
                .query({
                    filters: series.filters.toJson(),
                    aggregates: 'contentSentiment:sum,repliesSentiment:sum'
                })
                .get()
                .json(res => {
                    series.aggregates = res.data
                    series.isLoading = false
                })
        },

        analysisOptions(series) {
            return {
                boost: { useGPUTranslations: true },
                chart: {
                    type: 'bar',
                    spacing: [0, 0, 0, 0]
                },
                credits: { enabled: false },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        grouping: false,
                        groupPadding: 0
                    }
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: { text: '' },
                    labels: {
                        align: 'center',
                        style: { color: '#8599a3' }
                    }
                },
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    hideDelay: 100,
                    padding: 8,
                    pointFormatter: function () {
                        return `<strong>${number(this.y)}</strong>`
                    },
                    shadow: false,
                    shared: true,
                    style: { color: '#fff', textAlign: 'center' },
                    xDateFormat: '%Y-%m-%d'
                },
                series: [
                    {
                        name: 'Sentiment',
                        color: '#10b981',
                        data: [{ name: 'Positive', y: series.aggregates[this.type == 'replies' ? 'repliesSentiment:sum' : 'contentSentiment:sum'].positive, x: 0 }]
                    },
                    {
                        name: 'Sentiment',
                        color: '#f43f5e',
                        data: [{ name: 'Negative', y: series.aggregates[this.type == 'replies' ? 'repliesSentiment:sum' : 'contentSentiment:sum'].negative, x: 1 }]
                    },
                    {
                        name: 'Sentiment',
                        color: '#d1d5db',
                        data: [{ name: 'Neutral', y: series.aggregates[this.type == 'replies' ? 'repliesSentiment:sum' : 'contentSentiment:sum'].neutral, x: 2 }]
                    },
                ],
                title: {
                    text: ''
                }
            }
        }
    }
})

export default defineOverviewSentimentShareStore
