<template>
    <div>
        <ui-header>
            <template v-slot:subsection>
                <stream-toolbar :store="$page.streamStore" :full-width="true"></stream-toolbar>
            </template>
        </ui-header>

        <div class="px-8 max-w-12xl mx-auto">
            <ui-stream :store="$page.streamStore">
                <template v-slot:item="{ item, layout }">
                    <target-card :target="item" :cards-store="targetsListDummyCardsStore" :mode="layout.id == 'table' ? 'row' : 'card'"></target-card>
                </template>

                <template v-slot:placeholder="{ layout }">
                    <target-placeholder-card v-if="layout.id == 'grid'"></target-placeholder-card>
                    <target-placeholder-row v-if="layout.id == 'table'"></target-placeholder-row>
                </template>
            </ui-stream>
        </div>
    </div>
</template>

<script>
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import TargetCard from './stream-cards/target'
import TargetPlaceholderCard from './stream-cards/target-placeholder'
import TargetPlaceholderRow from './stream-cards/target-placeholder-row'

import useTargetsListDummyCardsStore from '@/stores/targets/list-dummy-cards'

import { mapStores } from 'pinia'

export default {
    components: {
        StreamToolbar, TargetCard, TargetPlaceholderCard, TargetPlaceholderRow
    },

    computed: {
        ...mapStores(useTargetsListDummyCardsStore)
    }
}
</script>
