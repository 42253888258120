import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

import useMyTopicsStore from '@/stores/me/search-topics'

export const useContentModalsTopicDuplicateStore = defineForm({
    id: 'contentModalsTopicDuplicate',

    inputs: () => ({
        topic: {},
        name: null
    }),

    submitRequest() {
        return api.route('me topics duplicate', { id: this.inputs.topic.id })
            .json({ name: this.inputs.name })
            .post()
    },

    async onResponse(res) {
        useMyTopicsStore().reload()

        useModal().hide('content-topic-duplicate')
    },

    actions: {
        open(topic = {}) {
            this.reset()

            this.inputs.topic = topic
            this.inputs.name = `${topic.name} (copy)`

            useModal().show('content-topic-duplicate')
        },

        cancel() {
            useModal().hide('content-topic-duplicate')
        }
    }
})

export default useContentModalsTopicDuplicateStore
