<template>
    <div class="px-8 flex justify-center space-x-4 max-w-7xl mx-auto">
        <div class="w-1/4 space-y-4">
            <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md py-2 mb-4">
                <router-link :to="{ name: 'connections', params: { nodeId: $page.target.nodeId } }" class="flex items-center font-medium px-6 py-2 hover:text-blue-600" target="_blank">
                    <ui-icon name="share-2" class="mr-2"></ui-icon>
                    Explore
                    <ui-icon name="arrow-right-circle" class="ml-auto"></ui-icon>
                </router-link>
            </div>

            <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md py-2 mb-4">
                <router-link :to="{ name: 'targets.target.connections', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id, connectionsType: type.id } }" class="block font-medium px-6 py-2 hover:text-blue-600 group" :class="{'border-r-2 border-blue-500 text-blue-600': $page.connectionsType == type.id}" :key="type.id" v-for="type in types">
                    <div>{{ type.name }}</div>
                    <div class="text-xs font-normal group-hover:text-blue-500" :class="$page.connectionsType == type.id ? 'text-blue-500' : 'text-gray-800'">{{ type.description }}</div>
                </router-link>
            </div>
        </div>

        <div class="w-3/4">
            <connections-card title="Upstream Sources" :connections="sourcesUpstream" :connections-store="targetsTargetConnectionsStore" v-if="$page.connectionsType == 'upstream'"></connections-card>
            <connections-card title="Downstream Sources" :connections="sourcesDownstream" :connections-store="targetsTargetConnectionsStore" v-if="$page.connectionsType == 'downstream'"></connections-card>
            <connections-card title="Sources Sharing Properties" :connections="sourcesSharingProperties" :connections-store="targetsTargetConnectionsStore" v-if="$page.connectionsType == 'sharing-properties'"></connections-card>
            <connections-card title="Connected Features" :connections="sourcesConnectedFeatures" :connections-store="targetsTargetConnectionsStore" v-if="$page.connectionsType == 'connected-features'"></connections-card>
        </div>
    </div>
</template>

<script>
import ConnectionsCard from './connections/connections-card'

import useTargetsTargetConnectionsStore from '@/stores/targets/target/connections'

import { mapState, mapStores } from 'pinia'

export default {
    components: { ConnectionsCard },

    data: () => ({
        types: [
            { id: 'upstream', name: 'Upstream Sources', description: 'Sources this target shares content from.' },
            { id: 'downstream', name: 'Downstream Sources', description: 'Sources sharing content from this Target.' },
            { id: 'sharing-properties', name: 'Sources Sharing Properties', description: 'Sources sharing the same Features.' },
            { id: 'connected-features', name: 'Connected Features', description: 'Features connected to this source directly or via published content.' }
        ]
    }),

    computed: {
        ...mapState(useTargetsTargetConnectionsStore, [
            'sourcesUpstream', 'sourcesDownstream', 'sourcesSharingProperties', 'sourcesConnectedFeatures'
        ]),

        ...mapStores(useTargetsTargetConnectionsStore)
    }
}
</script>
