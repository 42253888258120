<template>
    <div>
        <ui-header :title="$page.category ? $page.category.name : 'Category'" :icon="$page.category ? $page.category.icon : null" :back="{ name: 'targets.curated-lists' }">
            <template v-slot:actions>
                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off"
                           spellcheck="false"
                           placeholder="Search curated lists..."
                           class="block w-full h-8 pl-8 pr-2 rounded placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm"
                    >
                </form>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-12xl mx-auto">
            <div class="mb-10" v-if="! $page.category || featuredLists.length">
                <h1 class="font-medium text-sm mb-4">Featured lists</h1>

                <ui-async :guards="$page.category" class="grid grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-6">
                    <template v-slot>
                        <list-card :list="list" :key="list.id" v-for="list in featuredLists"></list-card>
                    </template>

                    <template v-slot:loading>
                        <list-placeholder-card :key="i" v-for="v, i in Array.from({ length: 1 * perRow })"></list-placeholder-card>
                    </template>
                </ui-async>
            </div>

            <div class="mb-10" v-if="! $page.category || allLists.length">
                <h1 class="font-medium text-sm mb-4">All lists</h1>

                <ui-async :guards="$page.category">
                    <template v-slot>
                        <table class="rounded bg-white shadow w-full">
                            <list-row :list="list" :key="list.id" v-for="list in allLists"></list-row>
                        </table>
                    </template>

                    <template v-slot:loading>
                        <table class="rounded bg-white shadow w-full">
                            <list-placeholder-row :key="i" v-for="v, i in Array.from({ length: 1 * perRow })"></list-placeholder-row>
                        </table>
                    </template>
                </ui-async>
            </div>

            <div class="h-120 flex flex-col items-center justify-center text-gray-600" v-if="noSearchResults">
                <ui-icon name="wind" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">Check your spelling or try a different query.</div>
            </div>
        </div>
    </div>
</template>

<script>
import ListCard from './stream-cards/curated-list'
import ListPlaceholderCard from './stream-cards/list-placeholder'
import ListPlaceholderRow from './stream-cards/list-placeholder-row'
import ListRow from './stream-cards/curated-list-row'

import breakpoint from '@/helpers/breakpoint'
import { textSearch } from '@/helpers'

export default {
    components: {
        ListCard, ListPlaceholderCard, ListPlaceholderRow, ListRow
    },

    computed: {
        featuredLists() {
            return textSearch(this.$page.search, (this.$page.category?.lists || []).filter(list => list.featured), i => i.name)
        },

        allLists() {
            return textSearch(this.$page.search, this.$page.category?.lists || [], i => i.name)
        },

        perRow() {
            return { xs: 2, sm: 2, md: 2, lg: 3, xl: 3, '2xl': 3, '3xl': 4 }[breakpoint.is]
        },

        noSearchResults() {
            return this.$page.category && ! this.featuredLists.length && ! this.allLists.length
        }
    }
}
</script>
