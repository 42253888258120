<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-icon name="search" class="text-xl"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-700">
                    <div class="truncate">Search <span class="text-gray-900 font-medium">"{{query}}"</span> in All Content</div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'query' ],

    computed: {
        to() {
            return { name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[text]': `${this.query}|${this.$my.preferredLanguage}|` } }
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
