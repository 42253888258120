<template>
    <div class="bg-white border rounded-lg flex shadow-sm p-1" :class="{ 'border-gray-200': ! thread.unseenAgentMessagesCount, 'border-blue-300': thread.unseenAgentMessagesCount }">
        <a href="#" @click.prevent="showThread(thread)" class="flex items-center justify-between px-4 py-2 hover:bg-gray-50 rounded-lg w-full" v-tooltip="tooltip">
            <ui-icon class="text-3xl shrink-0" :class="{ 'text-gray-600': ! thread.unseenAgentMessagesCount, 'text-blue-600': thread.unseenAgentMessagesCount }" :filled="thread.unseenAgentMessagesCount" :name="thread.resolvedAt ? 'check' : 'message-circle'"></ui-icon>
            <div class="flex-1 pl-3">
                <div class="flex items-start">
                    <div class="font-medium line-clamp-1 flex-1 min-w-0">{{thread.name}}</div>
                    <div class="ml-1 text-xs text-gray-500 whitespace-nowrap">{{threadDate}}</div>
                </div>
                <div class="line-clamp-2 text-sm text-gray-700 leading-tight" v-if="preview">
                    {{preview}}
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import useSupportChatStore from '@/stores/support/chat'

import { removeHTML } from '@/helpers'

import { format, parseISO } from 'date-fns'
import { marked } from 'marked'
import { mapActions } from 'pinia'

export default {
    props: [ 'thread' ],
    
    computed: {
        threadDate() {
            let diffInSeconds = ((new Date) - parseISO(this.thread.createdAt)) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(parseISO(this.thread.createdAt), 'H:mm')
            } else {
                return format(parseISO(this.thread.createdAt), 'LLL do')
            }
        },
        
        preview() {
            return this.thread.lastMessage ? removeHTML(marked.parse(this.thread.lastMessage.body)) : ''
        },
        
        tooltip() {
            if (this.thread.resolvedAt) return `Resolved at ${this.$dateTime(this.thread.resolvedAt)}`
            if (this.thread.unseenAgentMessagesCount) return `${this.thread.unseenAgentMessagesCount} unread ${this.thread.unseenAgentMessagesCount > 1 ? 'messages' : 'message'}`
            return undefined
        }
    },
    
    methods: {
        ...mapActions(useSupportChatStore, [ 'showThread' ])
    }        
}
</script>
