<template>
    <ui-contextual-menu plain>
        <ui-dropdown-link :disabled="! isLanguageTranslatable(content.language)" icon="translate" v-on="isLanguageTranslatable(content.language) ? { click: () => translate(content) } : {}">
            Translate text
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <target-section inline icon="sources" name="Source..." :target="content.source" :stream-store="streamStore"></target-section>
        <target-section inline icon="sources" name="Group..." :target="content.group" :stream-store="streamStore" v-if="content.group"></target-section>
        <tags-dropdown align="right" :item="content"></tags-dropdown>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'targets.target.content', params: { type: content.source.family, modelType: content.source.type, id: content.source.id, contentId: content.id } }).href" target="_blank">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="link" @click="copyLink">
            Copy link
        </ui-dropdown-link>

        <ui-dropdown-link icon="external-link" :href="content.link" target="_blank" rel="noreferrer noopener" :disabled="! content.link">
            Open original
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="edit" @click="() => createNote(content)">
            Add note...
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="warning" @click="() => report('content', content.id)">
            Report
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(content)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import TagsDropdown from '@/components/me/tags/contextual-menu'
import TargetSection from '@/components/targets/contextual-menus/target'

import useMeModalsNoteStore from '@/stores/me/modals/note'
import useSupportReportStore from '@/stores/support/report'

import { isLanguageTranslatable, translate as _translate } from '@/helpers'

import { mapActions } from 'pinia'

export default {
    components: { TagsDropdown, TargetSection },

    props: [ 'content', 'streamStore' ],

    methods: {
        isLanguageTranslatable,

        translate(content) {
            _translate(content, {}, () => this.streamStore.triggerLayoutUpdate(), () => this.streamStore.triggerLayoutUpdate())
        },

        copyLink() {
            navigator.clipboard.writeText(
                window.location.origin + this.$router.resolve({ name: 'targets.target.content', params: { type: this.content.source.family, modelType: this.content.source.type, id: this.content.source.id, contentId: this.content.id } }).href
            )
        },

        ...mapActions(useMeModalsNoteStore, { createNote: 'create' }),
        ...mapActions(useSupportReportStore, [ 'report' ])
    }
}
</script>
