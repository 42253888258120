<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-telegram-channel"
        avatar-badge-tooltip="Telegram Channel"
        :parent-component="{ 'telegram-channel-post': _.type, 'telegram-group-message': TelegramGroupMessageCard }"
    >
        <!-- Metrics -->
        <template v-slot:metrics>
            <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="isShowingMetrics">
                <div class="flex justify-center items-center" v-if="content.metrics.interactions">
                    <ui-icon name="interactions-solid" class="text-lg shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-2">
                        <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions
                    </div>
                </div>

                <div class="flex justify-center items-center" v-if="content.metrics.views">
                  <ui-icon name="views" class="text-lg shrink-0 text-gray-400"></ui-icon>

                  <div class="leading-none ml-2">
                    <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                  </div>
                </div>
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'
import TelegramGroupMessageCard from './telegram-group-message'

import { markRaw } from 'vue'

export default {
    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    data: () => ({
        TelegramGroupMessageCard: markRaw(TelegramGroupMessageCard)
    }),

    computed: {
        isShowingMetrics() {
            return this.content.metrics?.interactions
                || this.content.metrics?.views
        }
    }
}
</script>
