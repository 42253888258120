import useTargetsTargetPage from '@/pages/targets-target'
import useTargetsTargetConnectionsStore from '@/stores/targets/target/connections'
import useTargetsTargetHistoryStore from '@/stores/targets/target/history'
import useTargetsTargetInformationHeatmapStore from '@/stores/targets/target/information-heatmap'
import useTargetsTargetInformationFacebookPageFollowersAnalysisStore from '@/stores/targets/target/information-facebook-page-followers-analysis'
import useTargetsTargetInformationGroupPublishedContentAnalysisStore from '@/stores/targets/target/information-group-published-content-analysis'
import useTargetsTargetInformationSourcePublishedContentAnalysisStore from '@/stores/targets/target/information-source-published-content-analysis'
import useTargetsTargetInformationTargetContentAnalysisStore from '@/stores/targets/target/information-target-content-analysis'
import useTargetsTargetInformationTargetAudienceAnalysisStore from '@/stores/targets/target/information-target-audience-analysis'
import useTargetsTargetInformationTelegramChannelSubscribersAnalysisStore from '@/stores/targets/target/information-telegram-channel-subscribers-analysis'
import useTargetsTargetInformationTelegramGroupMembersAnalysisStore from '@/stores/targets/target/information-telegram-group-members-analysis'
import useTargetsTargetInformationTopGroupsStore from '@/stores/targets/target/information-top-groups'
import useTargetsTargetInformationTopSourcesStore from '@/stores/targets/target/information-top-sources'
import useTargetsTargetInformationVKontakteCommunityMembersAnalysisStore from '@/stores/targets/target/information-vkontakte-community-members-analysis'
import useTargetsTargetInformationVKontakteUserFollowersAnalysisStore from '@/stores/targets/target/information-vkontakte-user-followers-analysis'
import useTargetsTargetInformationVKontakteUserFriendsAnalysisStore from '@/stores/targets/target/information-vkontakte-user-friends-analysis'
import useTargetsTargetInformationYoutubeChannelSubscribersAnalysisStore from '@/stores/targets/target/information-youtube-channel-subscribers-analysis'
import useTargetsTargetInformationYoutubeChannelViewsAnalysisStore from '@/stores/targets/target/information-youtube-channel-views-analysis'
import useTargetsTargetInformationTopContentStreamStore from '@/stores/targets/target/information-top-content-stream'

import { defineStore, mapState } from 'pinia'

export const useTargetsTargetInformationPage = defineStore({
    id: 'targetsTargetInformationPage',

    state: () => ({
        selectedHeatmap: 'Daily',
        topContentRange: 'week',
        to: null
    }),

    getters: {
        ...mapState(useTargetsTargetPage, [ 'type', 'target', 'model' ])
    },

    actions: {
        afterEnter(to) {
            this.to = to

            useTargetsTargetInformationTargetContentAnalysisStore().setTarget(this.target)
            useTargetsTargetInformationTargetContentAnalysisStore().loadSeries()

            useTargetsTargetInformationTargetAudienceAnalysisStore().setTarget(this.target)
            useTargetsTargetInformationTargetAudienceAnalysisStore().loadSeries()

            if (this.type == 'sources') {
                this.loadSparkAnalysis(useTargetsTargetInformationSourcePublishedContentAnalysisStore(), this.target.modelId)

                if (this.target.type == 'facebook-page') {
                    this.loadSparkAnalysis(useTargetsTargetInformationFacebookPageFollowersAnalysisStore(), this.target.modelId)
                } else if (this.target.type == 'telegram-channel') {
                    this.loadSparkAnalysis(useTargetsTargetInformationTelegramChannelSubscribersAnalysisStore(), this.target.modelId)
                } else if (this.target.type == 'telegram-group') {
                    this.loadSparkAnalysis(useTargetsTargetInformationTelegramGroupMembersAnalysisStore(), this.target.modelId)
                } else if (this.target.type == 'vkontakte-user') {
                    this.loadSparkAnalysis(useTargetsTargetInformationVKontakteUserFollowersAnalysisStore(), this.target.modelId)
                    this.loadSparkAnalysis(useTargetsTargetInformationVKontakteUserFriendsAnalysisStore(), this.target.modelId)
                } else if (this.target.type == 'youtube-channel') {
                    this.loadSparkAnalysis(useTargetsTargetInformationYoutubeChannelSubscribersAnalysisStore(), this.target.modelId)
                    this.loadSparkAnalysis(useTargetsTargetInformationYoutubeChannelViewsAnalysisStore(), this.target.modelId)
                }

                this.loadHeatmap()
                useTargetsTargetInformationTopGroupsStore().load(this.target)
            } else if (this.type == 'groups') {
                this.loadSparkAnalysis(useTargetsTargetInformationGroupPublishedContentAnalysisStore(), this.target.modelId)

                if (this.target.type == 'telegram-group') {
                    this.loadSparkAnalysis(useTargetsTargetInformationTelegramGroupMembersAnalysisStore(), this.target.modelId)
                } else if (this.target.type == 'vkontakte-community') {
                    this.loadSparkAnalysis(useTargetsTargetInformationVKontakteCommunityMembersAnalysisStore(), this.target.modelId)
                }

                useTargetsTargetInformationTopSourcesStore().load(this.target)
            }

            useTargetsTargetConnectionsStore().load(this.target)
            useTargetsTargetHistoryStore().load(this.target)

            this.initializeTopContentStream(to)
        },

        loadSparkAnalysis(store, id, name) {
            store.name = name || store.name
            store.filterAppends = { modelId: id }
            store.load()
        },

        loadHeatmap() {
            useTargetsTargetInformationHeatmapStore().initialize({
                configuration: {
                    series: [ { datasetId: this.target.id, datasetFamily: this.target.family, datasetName: this.target.title, meta: { type: 'daily' } } ],
                    styles: { 'heatmap.range': 'single' },
                    date: {
                        type: 'in-range',
                        date: {
                            gte: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                            lte: new Date()
                        }
                    }
                }
            })
        },

        initializeTopContentStream(to) {
            let stream = useTargetsTargetInformationTopContentStreamStore()

            stream.initialize()
            stream.navigatedTo(to, {
                'targets': [ { type: this.type, id: this.target.id, name: this.target.title } ],
                'date': { type: 'past', date: { past: '1', unit: `${this.topContentRange}s` } }
            })
            stream.setLayout('cards')
            stream.load()
        },

        selectTopContentRange(range) {
            this.topContentRange = range
            this.initializeTopContentStream(this.to)
        },

        selectHeatmap(type) {
            useTargetsTargetInformationHeatmapStore().analysisSeries = []

            if (type == 'hourly') {
                this.selectedHeatmap = 'Hourly'
                useTargetsTargetInformationHeatmapStore().series[0]['meta']['type'] = 'hourly'
            } else {
                this.selectedHeatmap = 'Daily'
                useTargetsTargetInformationHeatmapStore().series[0]['meta']['type'] = 'daily'
            }

            useTargetsTargetInformationHeatmapStore().loadSeries()
        }
    }
})

export default useTargetsTargetInformationPage
