import useModalsExportOptionsStore from '@/stores/modals/export-options'

import api from '@/api'
import { useModal } from '@/helpers'
import { targets } from '@/helpers/api-three-tables'

import useMyNotificationsStore from '@/stores/me/notifications'

import { defineStore } from 'pinia'

export const useMyMonitoredTargetsStore = defineStore({
    id: 'my-monitored-targets',

    state: () => ({
        items: [],

        search: '',
        filter: 'all-targets',

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,
        lastReloadTime: +new Date(),

        filterOptions: [
            { id: 'all-targets', name: 'All Targets', family: '', monitoringOptions: [] },
            { id: 'only-sources', name: 'Only Sources', family: 'sources', monitoringOptions: [] },
            { id: 'only-groups', name: 'Only Groups', family: 'groups', monitoringOptions: [] },
            null,
            { id: 'with-image-text-extraction', name: 'With Image Text Extraction', family: '', monitoringOptions: [ 'imageTextExtraction' ] },
            { id: 'with-video-download', name: 'With Video Download', family: '', monitoringOptions: [ 'videoDownload' ] },
            { id: 'with-video-transcription', name: 'With Video Transcription', family: '', monitoringOptions: [ 'videoTranscription' ] },
        ]
    }),

    getters: {
        selectedFilter() {
            return this.filterOptions.find(o => o?.id == this.filter)
        }
    },

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.targetsQuery()
                .get()
                .then(targets => {
                    this.items = targets

                    this.isLoading = false
                    this.isInitialized = true

                    this.loadingPromise = null
                })
        },

        async reload() {
            this.lastReloadTime = +new Date()
            return this.load(true)
        },

        async loadMore(infiniteScroll) {
            let lastItem = this.items[this.items.length - 1]

            if (! lastItem) return

            this.targetsQuery()
                .after({ id: lastItem.id, family: lastItem.family })
                .get()
                .then(targets => {
                    if (targets.length) {
                        this.items = [ ...this.items, ...targets ]
                        infiniteScroll.loaded()
                    } else {
                        infiniteScroll.complete()
                    }
                })
        },

        async all() {
            await this.load()
            return this.items
        },

        setFilter(filter) {
            this.filter = filter
            this.reload()
        },

        export() {
            useModalsExportOptionsStore().open({ query: this.targetsQuery() })
        },

        targetsQuery() {
            return targets()
                .family(this.selectedFilter.family)
                .filters('list', 'my-targets')
                .filters('title', { query: this.search })
                .filters('monitoring-options', this.selectedFilter.monitoringOptions)
                .include(['monitoring-options'])
                .sorting('name-asc')
        },

        async enableMonitoring(target) {
            await api.route('me targets monitoring enable', { family: target.family, id: target.id })
                .post()
                .error(400, () => useModal().show('targets-my-targets-resource-unavailable'))
                .res(() => {
                    useMyNotificationsStore().pushToast({
                        type: 'info',
                        title: 'Adding your target...',
                        text: 'This might take a few minutes, feel free to continue using the app.',
                        lifetime: 10
                    })
                })
        },

        isMonitored(target) {
            return target.lists?.find(l => l.type == 'my-targets')
        },

        toggleMonitoringOption(target, option) {
            return target.monitoringOptions.includes(option)
                ? this.disableMonitoringOption(target, option)
                : this.enableMonitoringOption(target, option)
        },

        enableMonitoringOption(target, option) {
            return api.route('me targets options store', { family: target.family, id: target.id, option })
                .post()
                .error(400, () => useModal().show('targets-my-targets-options-resource-unavailable'))
                .res(() => target.monitoringOptions.push(option))
        },

        disableMonitoringOption(target, option) {
            return api.route('me targets options delete', { family: target.family, id: target.id, option })
                .delete()
                .res(() => target.monitoringOptions = target.monitoringOptions.filter(o => o != option))
        }
    }
})

export default useMyMonitoredTargetsStore
