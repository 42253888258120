<template>
    <div class="py-1 px-5 bg-gray-50 rounded-b-lg">
        <div class="flex justify-between text-sm my-1">
            <a href="#" @click.prevent="openDetails(parent.id, streamStore)" class="block text-blue-600">
                Show <span class="font-medium">{{parent.conversationChildrenCount}}</span> replies
            </a>

            <ui-dropdown position="top" align="right" unstyled>
                <template v-slot:trigger>
                    <div class="my-2 h-1.5 bg-gray-300 rounded-full overflow-hidden flex w-24 cursor-pointer" v-tooltip="'Sentiment analysis'">
                        <div class="h-1.5 bg-emerald-500" :style="`width: ${parent.metrics.repliesSentiment.positive / repliesWithSentimentCount * 100}%;`"></div>
                        <div class="h-1.5 bg-indigo-500" :style="`width: ${parent.metrics.repliesSentiment.neutral / repliesWithSentimentCount * 100}%;`"></div>
                        <div class="h-1.5 bg-rose-500" :style="`width: ${parent.metrics.repliesSentiment.negative / repliesWithSentimentCount * 100}%;`"></div>
                    </div>
                </template>

                <template v-slot:content>
                    <div class="rounded shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left whitespace-nowrap">
                        <div v-if="parent.metrics.repliesSentiment.positive / (parent.metrics.repliesSentiment.positive + parent.metrics.repliesSentiment.negative) > 0.5">
                            <span class="font-semibold">Positive</span> sentiment
                        </div>
                        <div v-else-if="parent.metrics.repliesSentiment.negative / (parent.metrics.repliesSentiment.positive + parent.metrics.repliesSentiment.negative) > 0.5">
                            <span class="font-semibold">Negative</span> sentiment
                        </div>
                        <div v-else-if="(parent.metrics.repliesSentiment.positive + parent.metrics.repliesSentiment.negative) / repliesWithSentimentCount > 0.5">
                            <span class="font-semibold">Mixed</span> sentiment
                        </div>

                        <div class="text-sm text-gray-100 leading-4 mt-1">
                            <div>
                                <span class="font-semibold">{{parent.metrics.repliesSentiment.positive}}</span> positive replies
                            </div>
                            <div>
                                <span class="font-semibold">{{parent.metrics.repliesSentiment.negative}}</span> negative replies
                            </div>
                            <div>
                                <span class="font-semibold">{{parent.metrics.repliesSentiment.neutral}}</span> neutral replies
                            </div>
                        </div>
                    </div>
                </template>
            </ui-dropdown>
        </div>

        <div class="divide-y divide-gray-100" v-if="replies.length">
            <card-reply :parent="parent" :reply="reply" :stream-store="streamStore" v-for="reply in replies.slice().reverse()" :key="reply.id"></card-reply>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'pinia'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'

import CardReply from './reply'

export default {
    props: [ 'parent', 'replies', 'streamStore' ],

    components: { CardReply },

    computed: {
        repliesWithSentimentCount() {
            return this.parent.metrics.repliesSentiment.positive
                + this.parent.metrics.repliesSentiment.negative
                + this.parent.metrics.repliesSentiment.neutral
        }
    },

    methods: {
        ...mapActions(useContentModalsContentDetailStore, {
            openDetails: 'open'
        })
    }
}
</script>
