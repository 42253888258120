<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar family="features" :name="result.title" :image="result.avatarImageUrl" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
                <ui-icon :name="`badges.feature-${result.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-base"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="leading-5 font-medium text-gray-900">
                    <div class="truncate">{{result.title}}</div>
                </div>
                <div class="text-xs leading-4 text-gray-700 truncate">
                    {{type}}
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: 'features.feature.information', params: { type: this.result.type, id: this.result.id } }
        },

        type() {
            return {
                'domain': 'Domain',
                'email': 'Email Address',
                'facebook-media': 'Facebook Media',
                'hashtag': 'Hashtag',
                'iban': 'IBAN',
                'image': 'Image',
                'ip-address': 'IP Address',
                'location': 'Location',
                'name': 'Person Name',
                'phone-number': 'Phone Number',
                'url': 'URL'
            }[this.result.type]
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
