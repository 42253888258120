<template>
    <ui-async :guards="$page.target">
        <ui-header :title="$page.target.title" :avatar="$page.target.avatar"
            :badge="`badges.target-${$page.target.type}`" :badge-grayscale="! $page.target.monitored">
            <template v-slot:actions-start>
                <contextual-menu :target="$page.target" plain color="black" class="ml-2 mt-1" z-index="60"></contextual-menu>
            </template>

            <template v-slot:navigation>
                <router-link :to="{ name: 'targets.target.information', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="info" class="mr-2"></ui-icon> Information
                </router-link>
                <!--<router-link :to="{ name: 'targets.target.stream', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500">-->
                <!--    <ui-icon name="stream" class="mr-2"></ui-icon> Stream-->
                <!--</router-link>-->
                <router-link :to="{ name: 'targets.target.history', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="calendar" class="mr-2"></ui-icon> History
                </router-link>
                <router-link :to="{ name: 'targets.target.connections', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500" v-if="$page.type == 'sources'">
                    <ui-icon name="share" class="mr-2"></ui-icon> Connections
                </router-link>
                <router-link :to="{ name: 'targets.target.members', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500" v-if="$page.type == 'groups'">
                    <ui-icon name="users" class="mr-2"></ui-icon> Members
                </router-link>

                <div class="flex-1 flex justify-end">
                    <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': 'past:1|months', 'filter[targets]': `${$page.target.family == 'groups' ? 'group' : 'source'}|${$page.target.id}|${$page.target.title}` } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2">
                        <ui-icon name="stream" class="mr-2"></ui-icon> Stream <ui-icon name="chevron-right" class="ml-2"></ui-icon>
                    </router-link>
                </div>
            </template>

            <template v-slot:subsection>
                <div id="header-bottom"></div>
            </template>
        </ui-header>

        <router-view></router-view>
    </ui-async>
</template>

<script>
import ContextualMenu from '@/components/targets/contextual-menus/target'

export default {
    components: {
        ContextualMenu
    }
}
</script>
