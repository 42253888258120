<template>
    <ui-async :guards="! isLoadingRequests" class="divide-y divide-gray-100">
        <template :key="request.id" v-for="request in requests">
            <div class="flex items-center py-1 px-3 w-full">
                <div class="text-gray-700 text-2xs text-right w-24 mr-4">
                    {{$dateTime(request.createdAt)}}
                </div>
                <div class="flex items-center space-x-2">
                    <ui-icon :name="platformInfo(request.platform).icon" v-tooltip="platformInfo(request.platform).name"></ui-icon>
                    <span>{{request.url}}</span>
                </div>
                <div class="ml-auto">
                    <span class="flex items-center space-x-2 text-green-600" v-if="request.state == 'added'">
                        <ui-icon name="check-circle"></ui-icon>
                        <span>Success</span>
                    </span>
                    <span class="flex items-center space-x-2 text-red-600" v-else-if="request.state == 'failed'">
                        <ui-icon name="error-full-circle"></ui-icon>
                        <span>Failed</span>
                    </span>
                    <span class="flex items-center space-x-2 text-yellow-600" v-else>
                        <ui-spinner type="clip" color="#ca8a04"></ui-spinner>
                        <span>Pending</span>
                    </span>
                </div>
                <div class="ml-3 text-xl leading-none">
                    <a href="#" @click.prevent="toggleRequestDetails(request)" :class="{'text-gray-300': ! request.sources.length && ! request.groups.length}">
                        <ui-icon :name="showingRequestDetails(request) ? 'chevron-down' : 'chevron-right'"></ui-icon>
                    </a>
                </div>
            </div>

            <div v-if="showingRequestDetails(request)">
                <target-card :target="source" mode="row" :key="source.id" v-for="source in request.sources"></target-card>
                <target-card :target="group" mode="row" :key="group.id" v-for="group in request.groups"></target-card>
            </div>
        </template>
    </ui-async>
</template>

<script>
import TargetCard from '@/components/targets/stream-cards/target'

import useTargetsMyTargetsMonitoringRequestsStore from '@/stores/targets/my-targets/monitoring-requests'

import { mapState } from 'pinia'

export default {
    components: {
        TargetCard
    },

    props: {
        requests: Array
    },

    data: () => ({
        platforms: [
            { name: 'Facebook Page', id: 'facebook', icon: 'badges.target-facebook-page' },
            { name: 'Facebook Group', id: 'facebook-group', icon: 'badges.target-facebook-group' },
            { name: 'Imageboard Board', id: 'imageboard', icon: 'badges.target-imageboard-board' },
            { name: 'Instagram Profile', id: 'instagram-profile', icon: 'badges.target-instagram-profile' },
            { name: 'Reddit User or Subreddit', id: 'reddit', icon: 'badges.target-reddit-subreddit' },
            { name: 'Telegram Channel or Group', id: 'telegram', icon: 'badges.target-telegram-channel' },
            { name: 'TikTok User', id: 'tiktok', icon: 'badges.target-tiktok-user' },
            { name: 'Twitter User', id: 'twitter', icon: 'badges.target-twitter-user' },
            { name: 'VKontakte User, Page or Community', id: 'vkontakte', icon: 'badges.target-vkontakte-community' },
            { name: 'Web Feed', id: 'web-feeds', icon: 'badges.target-web-feed' },
            { name: 'Youtube Channel', id: 'youtube', icon: 'badges.target-youtube-channel' }
        ],

        requestDetailsOpen: []
    }),

    computed: {
        ...mapState(useTargetsMyTargetsMonitoringRequestsStore, { isLoadingRequests: 'isLoading' }),
    },

    methods: {
        platformInfo(platform) {
            return this.platforms.find(p => p.id == platform) || {}
        },

        showingRequestDetails(request) {
            return this.requestDetailsOpen.includes(request.id)
        },

        toggleRequestDetails(request) {
            if (! request.sources.length && ! request.groups.length) return

            if (this.requestDetailsOpen.includes(request.id)) {
                this.requestDetailsOpen = this.requestDetailsOpen.filter(id => id != request.id)
            } else {
                this.requestDetailsOpen.push(request.id)
            }
        }
    }
}
</script>
