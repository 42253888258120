<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="settings" @click="editBoard(board)">
            Settings
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="copy" @click="duplicateBoard(board)">
            Duplicate
        </ui-dropdown-link>

        <ui-dropdown-link icon="trash" @click="deleteBoard(board)">
            Delete
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'
import useModalsBoardDuplicateStore from '@/stores/dashboards/modals/board-duplicate'
import useMyDashboardsStore from '@/stores/me/dashboards'

import { mapActions } from 'pinia'

export default {
    props: [ 'board' ],

    methods: {
        ...mapActions(useMyDashboardsStore, {
            deleteBoard: 'delete'
        }),

        ...mapActions(useModalsBoardEditStore, {
            editBoard: 'open'
        }),

        ...mapActions(useModalsBoardDuplicateStore, {
            duplicateBoard: 'open'
        })
    }
}
</script>
