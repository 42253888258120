<template>
    <ui-dropdown class="mt-1" :disabled="! store.isBulkTaggingAvailable" width="w-56" v-tooltip="! store.isBulkTaggingAvailable ? 'Bulk Tagging is only available with less than 250 results' : ''" @opened="loadStreamTags(store)" @closed="resetStreamTags(store)" v-if="store.resultsToolbar.tags !== false">    
        <template v-slot:trigger>
            <a href="#" class="inline-flex items-center text-sm font-medium" :class="{ 'text-blue-500 hover:text-blue-800': store.isBulkTaggingAvailable, 'text-gray-400': ! store.isBulkTaggingAvailable }">
                <ui-icon name="tags" class="text-xl mr-1"></ui-icon>
                Tags
            </a>
        </template>
        <template v-slot:content>
            <ui-dropdown-link icon="ai" @click="addSmartTag(store)">Add Smart Tag...</ui-dropdown-link>
    
            <ui-dropdown-separator class="mb-3"></ui-dropdown-separator>
                
            <tags-dropdown-content :stream="store"></tags-dropdown-content>
        </template>
    </ui-dropdown>
</template>

<script>
import TagsDropdownContent from '@/components/me/tags/contextual-menu-content'

import useContentModalsSmartTagStore from '@/stores/content/modals/smart-tag'
import useTaggingStore from '@/stores/tagging/tagging'

import { mapActions } from 'pinia'

export default {
    components: {
        TagsDropdownContent
    },
    
    methods: {
        ...mapActions(useContentModalsSmartTagStore, { addSmartTag: 'open' }),
        ...mapActions(useTaggingStore, [ 'loadStreamTags', 'resetStreamTags' ])
    }
}
</script>
