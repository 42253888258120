<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="pin-off" @click="updateTopic(topic, { favorite: 1 })" v-if="! topic.pinned">
           Pin
        </ui-dropdown-link>
        <ui-dropdown-link icon="pin-off" @click="updateTopic(topic, { favorite: 0 })" v-if="topic.pinned">
           Un-pin
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="settings" @click="editTopic(topic)">
           Settings
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="copy" @click="duplicateTopic(topic)">
            Duplicate
        </ui-dropdown-link>
        <ui-dropdown-link icon="trash" @click="deleteTopic(topic)">
           Delete
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(topic)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useContentModalsTopicDuplicateStore from '@/stores/content/modals/topic-duplicate'
import useContentModalsTopicEditStore from '@/stores/content/modals/topic-edit'
import useMySearchTopicsStore from '@/stores/me/search-topics'

import { mapActions } from 'pinia'

export default {
    props: [ 'topic' ],

    methods: {
        ...mapActions(useMySearchTopicsStore, {
            'deleteTopic': 'delete',
            'updateTopic': 'update'
        }),

        ...mapActions(useContentModalsTopicEditStore, { 'editTopic': 'open' }),

        ...mapActions(useContentModalsTopicDuplicateStore, { 'duplicateTopic': 'open' })
    }
}
</script>
