<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-imageboard-board"
        avatar-badge-tooltip="Imageboard"
        :parent-component="{ 'imageboard-post': _.type }"
    >
        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'

export default {
    name: 'imageboard-board-card',

    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ]
}
</script>
