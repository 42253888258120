<template>
    <router-link :to="{ name: 'targets.target.information', params: { type: target.family, modelType: target.type, id: target.id } }" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-100 shadow hover:shadow-lg cursor-pointer relative group" :class="{ 'bg-gray-50': ! target.monitored }">
            <div class="flex items-center relative p-4">
                <div class="shrink-0 relative">
                    <ui-avatar :item="target" class="w-8 h-8"></ui-avatar>
                    <ui-icon :name="`badges.target-${target.type}`" v-tooltip="targetType" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! target.monitored }"></ui-icon>
                </div>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        <span v-tooltip="target.title">{{ target.title }}</span>
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        {{targetType}}
                        <span class="h-3 w-3 rounded-full bg-blue-400 text-white flex items-center justify-center text-xs inline-flex ml-0.5 -mb-1" v-if="target.verified">
                            <ui-icon name="check" class="text-[9px]"></ui-icon>
                        </span>
                        <span v-if="target.username" v-tooltip="target.username">
                            · @{{target.username}}
                        </span>
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :target="target" :list="list" plain></contextual-menu>
                </div>
            </div>

            <div class="relative mt-2 h-16" v-if="cardsStore.layoutSettings.showCharts">
                <div class="h-full flex flex-col items-center justify-center text-sm text-gray-700" v-if="! target.monitored">
                    <div class="font-semibold">Target not monitored.</div>
                    <div class="text-xs">Please enable monitoring to start data download.</div>
                </div>
                <div class="h-full flex flex-col items-center justify-center text-sm text-gray-700" v-else-if="! analysisSeries">
                    <div class="font-semibold">Analysis in progress.</div>
                    <div class="text-xs">Activity chart will be available shortly.</div>
                </div>

                <analysis class="h-16" :options="analysisOptions" ref="analysis" @mousemove="chartMouseMove" @mouseleave="chartMouseLeave" v-else-if="shouldRenderChart"></analysis>

                <div class="absolute -bottom-px z-10" :style="pointMarkerStyle" v-if="pointMarkerStyle">
                    <div class="w-0 h-0 absolute left-0 bottom-px border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-gray-200"></div>
                    <div class="w-0 h-0 absolute left-0 bottom-0 border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-white"></div>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-14 border-t border-gray-100 rounded-b">
                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        {{ target.family === 'sources' ? 'Followers' : 'Members' }}
                    </div>
                    <div class="font-semibold text-sm">
                        <template v-if="target.family === 'sources'">
                            {{stats.followers !== null ? $number(stats.followers) : '-'}}
                        </template>
                        <template v-else>
                            {{stats.members !== null ? $number(stats.members) : '-'}}
                        </template>
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.publishedPerWeek > averageStats.publishedPerWeek, 'text-red-600': stats.publishedPerWeek < averageStats.publishedPerWeek }">
                        {{stats.publishedPerWeek !== null ? $number(Math.round(stats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsPerWeek > averageStats.interactionsPerWeek, 'text-red-600': stats.interactionsPerWeek < averageStats.interactionsPerWeek }">
                        {{stats.interactionsPerWeek !== null ? $number(Math.round(stats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsRate > averageStats.interactionsRate, 'text-red-600': stats.interactionsRate < averageStats.interactionsRate }">
                        {{stats.interactionsRate !== null ? $number(stats.interactionsRate) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="mt-auto divide-y divide-gray-100 bg-gray-50 border-t border-gray-100 rounded-b-lg">
                <div class="flex items-center relative px-4 h-8 space-x-3 text-xs text-gray-700" v-if="isDisplayedViaCuratedList || isBookmarked(target) || isMonitored(target)">
                    <span class="flex items-center space-x-1" v-if="isMonitored(target)">
                        <ui-icon name="navigation.targets"></ui-icon>
                        <span>My Targets</span>
                    </span>

                    <span class="flex items-center space-x-1" v-if="isDisplayedViaCuratedList">
                        <ui-icon name="curated-list"></ui-icon>
                        <span>Via curated list</span>
                    </span>

                    <span class="flex items-center space-x-1" v-if="isBookmarked(target)">
                        <ui-icon name="bookmark"></ui-icon>
                        <span>Bookmarked</span>
                    </span>
                </div>

                <!-- Notes -->
                <card-notes :item="target"></card-notes>
            </div>
        </div>
    </router-link>

    <component :is="cardsStore.choose ? 'div' : 'router-link'" :to="{ name: 'targets.target.information', params: { type: target.family, modelType: target.type, id: target.id } }" class="block" v-else-if="mode == 'row'">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0">
                <div class="shrink-0 relative">
                    <ui-avatar :item="target" class="w-8 h-8"></ui-avatar>
                    <ui-icon :name="`badges.target-${target.type}`" v-tooltip="targetType" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! target.monitored }"></ui-icon>
                </div>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        <span v-tooltip="target.title">{{ target.title }}</span>
                        <div class="h-4 w-4 rounded-full bg-blue-500 text-white flex items-center justify-center text-xs inline-flex ml-2 -mb-1"
                             v-if="target.verified">
                            <ui-icon name="check" class="text-2xs"></ui-icon>
                        </div>
                    </h1>
                    <div class="text-gray-800 text-xs leading-tight truncate">
                        {{targetType}}
                        <span v-if="target.username" v-tooltip="target.username">
                            · {{target.username}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-end flex-1 space-x-2 text-gray-500">
                <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Monitored'" v-if="isMonitored(target)">
                    <ui-icon name="navigation.targets"></ui-icon>
                </span>
                <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Via curated list'" v-if="isDisplayedViaCuratedList">
                    <ui-icon name="curated-list"></ui-icon>
                </span>
                <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Bookmarked'" v-if="isBookmarked(target)">
                    <ui-icon name="bookmark"></ui-icon>
                </span>
            </div>

            <div class="flex items-center w-64 px-2 relative" v-if="cardsStore.layoutSettings.showCharts">
                <div class="h-full w-full flex flex-col items-center justify-center text-center text-sm text-gray-700" v-if="! target.monitored">
                    <div class="font-semibold">Target not monitored.</div>
                    <div class="text-xs">Please enable monitoring to start data download.</div>
                </div>
                <div class="h-full w-full flex flex-col items-center justify-center text-center text-sm text-gray-700" v-else-if="! analysisSeries">
                    <div class="font-semibold">Analysis in progress.</div>
                    <div class="text-xs">Activity chart will be available shortly.</div>
                </div>
                <analysis class="h-full w-full pt-1" :options="analysisOptions" ref="analysis" v-else-if="shouldRenderChart"></analysis>
            </div>

            <div class="flex items-center space-x-5 px-4 w-72 border-l border-gray-100">
                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        {{ target.family === 'sources' ? 'Followers' : 'Members' }}
                    </div>
                    <div class="font-semibold text-sm">
                        <template v-if="target.family === 'sources'">
                            {{stats.followers !== null ? $number(stats.followers) : '-'}}
                        </template>
                        <template v-else>
                            {{stats.members !== null ? $number(stats.members) : '-'}}
                        </template>
                    </div>
                </div>

                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.publishedPerWeek > averageStats.publishedPerWeek, 'text-red-600': stats.publishedPerWeek < averageStats.publishedPerWeek }">
                        {{stats.publishedPerWeek !== null ? $number(Math.round(stats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsPerWeek > averageStats.interactionsPerWeek, 'text-red-600': stats.interactionsPerWeek < averageStats.interactionsPerWeek }">
                        {{stats.interactionsPerWeek !== null ? $number(Math.round(stats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate {{selectedPoint === null ? '⌀' : ''}}
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': stats.interactionsRate > averageStats.interactionsRate, 'text-red-600': stats.interactionsRate < averageStats.interactionsRate }">
                        {{stats.interactionsRate !== null ? $number(stats.interactionsRate) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <contextual-menu :target="target" plain></contextual-menu>
                </slot>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(target)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </component>
</template>

<script>
import CardNotes from '@/components/me/notes/card-notes'
import ContextualMenu from '@/components/targets/contextual-menus/target'

import { Chart as Analysis } from 'highcharts-vue'

import { useDefaultCardsStore } from '@/stores/reusable/cards'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyMonitoredTargetsStore from '@/stores/me/monitored-targets'
import useMyTargetListsStore from '@/stores/me/target-lists'
import targetTypesStore from '@/stores/targets/target-types'

import { utcTimestamp } from '@/helpers/datetime'

import { group } from 'd3-array'
import { utcMondays } from 'd3-time'
import { format, startOfWeek, sub, parseISO } from 'date-fns'
import debounce from 'just-debounce-it'
import { mapActions } from 'pinia'
import mapValues from 'just-map-values'

export default {
    props: { cardsStore: { default: () => useDefaultCardsStore() }, list: {}, mode: { default: 'card' }, target: {} },

    components: { Analysis, CardNotes, ContextualMenu },

    data: () => ({
        shouldRenderChart: false,
        intersectionObserver: null,
        selectedPoint: null
    }),

    computed: {
        analysisOptions() {
            return {
                chart: {
                    backgroundColor: false,
                    spacing: [0, 15, 0, 15]
                },
                boost: {
                    useGPUTranslations: true
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'datetime',
                    visible: false,
                    min: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 })),
                    max: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 1 }))
                },
                yAxis: [
                    {
                        title: { text: '' },
                        labels: {
                            enabled: !! this.target.metrics.recent.performance,
                            align: 'left',
                            padding: 0,
                            x: 0,
                            y: 14,
                            zIndex: 1,
                            style: { color: '#b8c3c9', fontSize: '9px' }
                        },
                        tickPixelInterval: 50,
                        gridLineColor: '#f0f3f5',
                        max: this.target.metrics.recent.performance ? null : 10
                    },
                    {
                        title: { text: '' },
                        labels: {
                            enabled: !! this.target.metrics.recent.performance,
                            align: 'right',
                            padding: 0,
                            x: 0,
                            y: 14,
                            zIndex: 1,
                            style: { color: '#b8c3c9', fontSize: '9px' }
                        },
                        opposite: true,
                        tickPixelInterval: 50,
                        gridLineColor: '#f0f3f5',
                        max: this.target.metrics.recent.performance ? null : 10
                    }
                ],
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    distance: 0,
                    padding: 4,
                    formatter: function () { return `<span style="font-size:10px;font-weight:600;">${format(this.x, 'yyyy-MM-dd')}</span>` },
                    shadow: false,
                    shape: 'rect',
                    style: { color: '#fff', textAlign: 'center' }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            fillColor: '#FFFFFF',
                            radius: 4,
                            lineWidth: 2,
                            lineColor: null,
                        }
                    },
                    series: {
                        states: {
                            inactive: {
                                enabled: false
                            }
                        }
                    }
                },
                series: [
                    {
                        type: 'line',
                        name: 'Interactions',
                        color: this.analysisColor,
                        fillOpacity: 0.1,
                        data: this.analysisSeries[0],
                        yAxis: 0
                    },
                    {
                        type: 'column',
                        name: 'Activity',
                        color: this.analysisColor,
                        opacity: 0.4,
                        fillOpacity: 0.1,
                        data: this.analysisSeries[1],
                        yAxis: 1
                    }
                ],
                credits: {
                    enabled: false
                }
            }
        },

        analysisColor() {
            if (! this.target.metrics.recent.performance) return '#e0e5e8'

            return {
                'facebook-group': '#4267B2',
                'facebook-page': '#4267B2',
                'facebook-user': '#4267B2',
                'telegram-channel': '#4798BB',
                'telegram-group': '#4798BB',
                'telegram-user': '#4798BB',
                'twitter-user': '#01C5D3',
                'web-feed': '#F49E28',
                'web-site': '#A5D448',
                'youtube-channel': '#F14539'
            }[this.target.type] || '#e0e5e8'
        },

        analysisSeries() {
            if (! (this.target.metrics.recent.performance?.[0] instanceof Array)) return

            return this.target.metrics.recent.performance.map(series => {
                let data = series.map(({x, y}) => ({
                    x: parseISO(x), y: y ? Math.round(parseFloat(y) * 10000) / 10000 : 0
                }))

                let m = group(data, ({x, y}) => x)
                let timeRange = utcMondays(
                    sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 }),
                    sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 0 }),
                    1
                )

                return timeRange.map(date => m.get(date)?.[0] || { x: date, y: 0 })
            })
        },

        stats() {
            if (this.selectedPoint == null || ! this.analysisSeries) return this.averageStats

            let stats = {
                followers: this.target?.metrics?.followers,
                members: this.target?.metrics?.members,
                publishedPerWeek: this.analysisSeries?.[1]?.[this.selectedPoint]?.y,
                interactionsPerWeek: this.analysisSeries?.[0]?.[this.selectedPoint]?.y
            }

            stats.interactionsRate = stats.publishedPerWeek ? Math.round(stats.interactionsPerWeek / stats.publishedPerWeek * 100) / 100 : null

            return mapValues(stats, v => isNaN(v) ? null : v)
        },

        averageStats() {
            let stats = {
                followers: this.target?.metrics?.followers,
                members: this.target?.metrics?.members,
                publishedTotal: this.target.metrics.allTime.publishedTotal,
                publishedPerWeek: this.target.metrics.recent.publishedPerWeek,
                interactionsPerWeek: this.target.metrics.recent.interactionsPerWeek
            }

            stats.interactionsRate = stats.publishedPerWeek ? Math.round(stats.interactionsPerWeek / stats.publishedPerWeek * 100) / 100 : null

            return mapValues(stats, v => isNaN(v) ? null : v)
        },

        pointMarkerStyle() {
            if (! this.analysisSeries || ! this.target.monitored) return

            return `left: calc(10px + (${this.selectedPoint} * 2 + 1) * ((100% - 30px) / 10));`
        },

        targetType() {
            return this.typeToHuman(this.target.type)
        },

        healthClass() {
            if (this.target.health == 'bad') {
                return 'text-red-400'
            } else if (this.target.health == 'poor') {
                return 'text-orange-400'
            }

            return 'text-green-400'
        },

        isDisplayedViaCuratedList() {
            return this.list && (this.isCuratedList(this.list) || (! this.isCuratedList(this.list) && this.list.parentList && ! this.target.lists.some(l => l.id === this.list.id)))
        }
    },

    methods: {
        ...mapActions(useMyBookmarksStore, [ 'isBookmarked' ]),

        ...mapActions(useMyMonitoredTargetsStore, [ 'isMonitored' ]),

        ...mapActions(useMyTargetListsStore, {
            'isCuratedList': 'isCurated'
        }),

        ...mapActions(targetTypesStore, [ 'typeToHuman' ]),

        chartMouseMove(ev) {
            this.updateSelectedPoint(ev.offsetX, ev.currentTarget) // this is intentionally done in two steps to preserve the currentTarget reference
        },

        chartMouseLeave() {
            this.selectedPoint = null
        },

        updateSelectedPoint: debounce(function(offsetX, currentTarget) {
            let chartContainerWidth = currentTarget.offsetWidth

            if (offsetX <= 15) return this.selectedPoint = 0
            if (offsetX >= chartContainerWidth - 15) return this.selectedPoint = 4

            this.selectedPoint = Math.floor((offsetX - 15) / (chartContainerWidth - 30) * 5)
        }, 5)
    },

    mounted() {
        this.intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(e => e.isIntersecting)) {
                this.shouldRenderChart = true
                this.intersectionObserver.disconnect()
            }
        })
        this.intersectionObserver.observe(this.$el)
    },

    unmounted() {
        this.intersectionObserver?.disconnect()
    }
}
</script>
