<template>
    <div class="flex flex-1 flex-wrap relative gap-2 max-w-full">
        <a @click.prevent="openSeriesModal(store, series)" href="#" class="bg-gray-50 hover:bg-gray-100 rounded-lg cursor-default shrink-0 py-2 px-4" :key="index" v-for="series, index in store.series">
            <div class="flex items-center space-x-1 mb-1">
                <div class="w-3 h-3 rounded-full" :style="`background: ${series.color}`"></div>
                <p class="max-w-xs truncate font-semibold text-lg" :style="`color: ${series.color}`">
                    {{series.label}}
                </p>
            </div>
            <div class="flex items-center text-gray-800">
                <ui-avatar :item="datasetItem(series) || {}" :family="series.datasetType" class="mr-1 h-3 w-3 shrink-0 text-sm"></ui-avatar>
                <span class="max-w-xs tracking-tight truncate text-xs">{{datasetItem(series)?.name}}</span>
            </div>
            <div class="flex items-center space-x-1 text-gray-700 leading-tight">
                <ui-icon :name="datasetAggregation(series).icon" class="shrink-0 text-xs"></ui-icon>
                <p class="max-w-xs tracking-tight truncate text-xs">
                    {{datasetAggregation(series).name}}
                    <span v-if="datasetAggregation(series).aggregator == 'summary'">Σ</span>
                    <span v-if="datasetAggregation(series).aggregator == 'average'">⌀</span>
                </p>
            </div>
        </a>

        <a v-if="store.series.length < store.seriesOptions.limit" @click.prevent="openSeriesModal(store)" href="#" class="bg-gray-50 hover:bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-default shrink-0 px-4 text-gray-800">
            <div class="leading-tight">
                <ui-icon name="plus" class="text-xl"></ui-icon>
            </div>
            <div class="leading-tight">
                Add series
            </div>
        </a>
    </div>
</template>

<script>
import useAnalysisModalsContentPerformanceSeriesStore from '@/stores/analysis/modals/content-performance/series'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { mapActions } from 'pinia'

export default {
    methods: {
        ...mapActions(useAnalysisModalsContentPerformanceSeriesStore, { openSeriesModal: 'open' }),

        datasetItem(series) {
            if (series.datasetType == 'target-list') {
                return useMyTargetListsStore().find(series.datasetId)
            } else if (series.datasetType == 'perspective') {
                return useMyPerspectivesStore().find(series.datasetId)
            } else if (series.datasetType == 'search-topic') {
                return useMySearchTopicsStore().find(series.datasetId)
            }
        },

        datasetAggregation(series) {
            if (series.meta.aggregator == 'count') {
                return { icon: 'hash', name: 'Published Content' }
            } else if (series.meta.aggregator == 'prevalence') {
                return { icon: 'flag', name: 'Published Content Prevalence' }
            } else if (series.meta.metric == 'interactions') {
                return { icon: 'interactions', name: 'Interactions', aggregator: series.meta.aggregator }
            } else if (series.meta.metric == 'reactions') {
                return { icon: 'thumbs-up', name: 'Reactions', aggregator: series.meta.aggregator }
            } else if (series.meta.metric == 'comments') {
                return { icon: 'comment', name: 'Comments', aggregator: series.meta.aggregator }
            } else if (series.meta.metric == 'shares') {
                return { icon: 'share', name: 'Shares', aggregator: series.meta.aggregator }
            } else if (series.meta.metric == 'views') {
                return { icon: 'eye', name: 'Views', aggregator: series.meta.aggregator }
            }
        }
    }
}
</script>
