import useMyAnalysesStore from '@/stores/me/analyses'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useAnalysesChannel from '@/stores/analysis/analyses-channel'

import hasDatasetConcern from './concerns/has-dataset-concern'

import api from '@/api'
import { searchFilters } from '@/helpers'
import safeColors from '@/helpers/safe-colors'

import { defineStore } from 'pinia'
import debounce from 'just-debounce-it'
import { shallowRef } from 'vue'

export const defineContentTopFeaturesAnalysisStore = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            analysis: null,

            id: null,
            name: 'Untitled analysis',
            type: 'content-top-features',
            fingerprint: null,

            series: [],

            date: { type: 'past', date: { past: 1, unit: 'years' }, },
            granularity: false,

            isTakingTooLong: false,

            isLoading: false,
            isLoadingFresh: false,
            loadingPromise: null,
            abortLoading: null,

            isDirty: false,
            isSaving: false,

            analysisSeries: null,
            seriesGeneratedAt: null,

            seriesOptions: {
                limit: 10,
                hasColors: false,
                hasLabels: true
            },

            exportable: false,
            printable: false,

            passive: settings.passive || false,

            analysisRef: null
        }),

        getters: {
            analysisOptions: store => ({
                isLoading: store.isLoading,
                series: store.analysisSeries,
                seriesGeneratedAt: store.seriesGeneratedAt
            }),

            analysisConfiguration: store => ({
                series: store.series.map(series => ({
                    color: series.color,
                    datasetId: series.datasetId,
                    datasetType: series.datasetType,
                    datasetFilters: series.datasetFilters,
                    label: series.label,
                    meta: series.meta
                })),
                date: store.date,
                granularity: store.granularity
            }),

            hasValues: store => Object.keys(store.analysisSeries).length > 0
        },

        actions: {
            async initialize(analysis) {
                this.$reset()

                this.analysis = analysis

                this.id = analysis.id
                this.name = analysis.name

                this.date = analysis.configuration.date || this.date

                this.series = analysis.configuration.series || this.series

                this.loadSeries()
            },

            async loadSeries(fresh = false) {
                if (this.loadingPromise) this.abortLoading.abort()

                this.isLoading = true
                this.isLoadingFresh = fresh

                setTimeout(() => {
                    if (this.isLoading) {
                        this.isTakingTooLong = true
                    }
                }, 5000)

                await useAnalysesChannel().listen('SeriesFinishedBuilding', (payload) => {
                    if (payload.fingerprint === this.fingerprint) {
                        this.analysisSeries = payload.data

                        this.isLoading = this.isLoadingFresh = this.isTakingTooLong = false
                    }
                })

                return this.loadingPromise = api.route('me analyses series')
                    .json({
                        type: this.type,
                        configuration: this.analysisConfiguration,
                        background: true,
                        fresh: fresh
                    })
                    .signal(this.abortLoading = new AbortController())
                    .post()
                    .error(422, () => {
                        this.isLoading = false
                        this.fingerprint = null
                    })
                    .json(response => {
                        if (! response.fingerprint) {
                            this.fingerprint = null

                            this.analysisSeries = response.series
                            this.seriesGeneratedAt = new Date(response.cachedAt)

                            this.isLoading = this.isLoadingFresh = this.isTakingTooLong = false

                            if (! fresh && Math.abs(this.seriesGeneratedAt - new Date()) > 15 * 60 * 1000) {
                                this.loadSeries(true)
                            }
                        } else {
                            this.fingerprint = response.fingerprint
                        }
                    })
            },

            loadSeriesDebounced: debounce(function () {
                this.loadSeries()
            }, 500),

            addSeries(data) {
                this.series.push({
                    label: data.label,
                    color: data.color,
                    datasetType: data.datasetType,
                    datasetId: data.datasetId,
                    meta: data.meta || {},
                    values: data.values || []
                })

                this.isDirty = true
                this.loadSeries()
            },

            updateSeries(series, data) {
                Object.assign(series, {
                    label: data.label || series.label,
                    color: data.color || series.color,
                    datasetType: data.datasetType || series.datasetType,
                    datasetId: data.datasetId || series.datasetId,
                    meta: data.meta || series.meta,
                    values: data.values || series.values
                })

                this.isDirty = true
                this.loadSeries()
            },

            deleteSeries(series) {
                const index = this.series.indexOf(series)

                if (index >= 0) {
                    this.series.splice(index, 1)

                    this.isDirty = true
                    this.loadSeries()
                }
            },

            isValidSeries(data) {
                return data.label && data.datasetType && data.datasetId
            },

            async fromQuery(query) {
                if (query.date) this.date = searchFilters().unserializeFrom('uri', 'date', query.date)

                await useMyPerspectivesStore().initialize()

                let perspective = useMyPerspectivesStore().find(query['perspective'])

                if (perspective) {
                    this.addSeries({
                        label: perspective.name,
                        color: safeColors.data().safeColors[0],
                        datasetType: 'perspective',
                        datasetId: perspective.id
                    })
                }
            },

            setDate(date) {
                this.date = date
                this.isDirty = true

                this.loadSeries()
            },

            setAnalysisRef(analysis) {
                this.analysisRef = shallowRef(analysis)
            },

            save(notify = false) {
                this.isSaving = true

                return api.route(this.id ? 'me analyses update' : 'me analyses store', { id: this.id })
                    .json({
                        _method: this.id ? 'put' : 'post',
                        type: this.type,
                        name: this.name,
                        configuration: this.analysisConfiguration,
                        dependencies: this.resolveDependencies(),
                        notify: notify
                    })
                    .post()
                    .json(response => {
                        this.id = response.data.id
                    })
                    .finally(() => {
                        return useMyAnalysesStore().reload().then(() => {
                            this.isSaving = this.isDirty = false
                        })
                    })
            },

            resolveDependencies() {
                return {
                    'perspectives': this.series.filter(s => s.datasetType == 'perspective').map(s => s.datasetId),
                    'target-lists': this.series.filter(s => s.datasetType == 'target-list').map(s => s.datasetId),
                    'topics': this.series.filter(s => s.datasetType == 'search-topic').map(s => s.datasetId)
                }
            },

            abort() {
                if (this.abortLoading) this.abortLoading.abort()
            },

            ...hasDatasetConcern.actions
        }
    })
}

const useAnalysisAnalysesContentTopFeaturesStore = defineContentTopFeaturesAnalysisStore({id: 'contentTopFeaturesAnalysis'})

export default useAnalysisAnalysesContentTopFeaturesStore
