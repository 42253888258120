<template>
    <search-filter name="Types" :ids="['type', 'replies']" :filters="filters" @opened="reset">
        <template v-slot:applied>
            <div class="flex items-center">
                <div v-if="typeValue.length">
                    <ui-icon :name="typeValue[0].icon" class="mr-1" :class="typeValue[0].iconClass" v-if="typeValue.length === 1 && typeValue[0].icon"></ui-icon>

                    <span class="font-medium" v-if="typeValue.length > 1">
                        {{typeValue.length}} types
                    </span>
                    <span class="font-medium" v-else>
                        {{typeValue.length ? typeValue[0].name : typeValue.name}}
                    </span>
                </div>

                <div class="flex items-center font-medium" v-if="repliesValue">
                    <span v-if="typeValue.length" class="ml-1">+ comments</span>
                    <span v-else>All + comments</span>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-96 overflow-y-auto">
                <ui-dropdown-link :icon="type.icon" checkbox :selected="localTypeValues.includes(type.id)" @click="toggleTypeValue(type)" :key="type.id" v-for="type in types">
                    {{type.name}}
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link icon="message-square" :selected="localRepliesValue" @click="toggleRepliesValue" keep-open>
                    Include comments
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="filters.remove('type'); filters.remove('replies'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        types: [
            { name: 'Facebook Post', id: 'facebook-post', icon: 'badges.target-facebook-page' },
            { name: 'Facebook Group Post', id: 'facebook-group-post', icon: 'badges.target-facebook-group' },
            { name: 'Imageboard Post', id: 'imageboard-post', icon: 'badges.target-imageboard-board' },
            { name: 'Instagram Post', id: 'instagram-post', icon: 'badges.target-instagram-profile' },
            { name: 'Reddit Post', id: 'reddit-post', icon: 'badges.target-reddit-subreddit' },
            { name: 'Telegram Channel Posts', id: 'telegram-channel-post', icon: 'badges.target-telegram-channel' },
            { name: 'Telegram Group Messages', id: 'telegram-group-message', icon: 'badges.target-telegram-group' },
            { name: 'TikTok Video', id: 'tiktok-video', icon: 'badges.target-tiktok-user' },
            { name: 'Twitter Tweet', id: 'twitter-tweet', icon: 'badges.target-twitter-user' },
            { name: 'VKontakte Posts', id: 'vkontakte-post', icon: 'badges.target-vkontakte-community' },
            { name: 'Web Article', id: 'web-article', icon: 'badges.target-web-feed' },
            { name: 'Web Resource', id: 'web-resource', icon: 'badges.target-web-site' },
            { name: 'Youtube Video', id: 'youtube-video', icon: 'badges.target-youtube-channel' }
        ],

        localTypeValues: [],
        localRepliesValue: false
    }),

    computed: {
        typeValue() {
            return (this.filters.value('type') ?? []).map(type => this.types.find(t => t.id == type))
        },

        repliesValue() {
            return this.filters.value('replies') ?? false
        },
    },

    methods: {
        toggleTypeValue(item) {
            this.localTypeValues.includes(item.id)
                ? this.localTypeValues.splice(this.localTypeValues.findIndex(v => v == item.id), 1)
                : this.localTypeValues.push(item.id)
        },

        toggleRepliesValue() {
            this.localRepliesValue = ! this.localRepliesValue
        },

        reset() {
            this.localTypeValues = this.filters.value('type') ? [ ...this.filters.value('type') ] : []
            this.localRepliesValue = this.filters.value('replies') ?? false
            this.search = ''
        },

        apply() {
            this.filters.set({
                'replies': this.localRepliesValue,
                'type': this.localTypeValues.length ? this.localTypeValues : undefined
            })
        }
    }
}
</script>
