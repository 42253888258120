<template>
    <a href="#" @click.prevent="applySearch(streamStore, search)" class="block px-6 pb-1 pt-3 hover:bg-gray-50 hover:ring-1 ring-gray-200 ring-inset" :class="{ 'bg-gray-50 ring-1': focused }">
        <div class="flex flex-wrap items-center mt-2 gap-2 px-3">
            <div
                v-if="itemStreamStore.search.query"
                @click.stop="applySearchQuery(streamStore, itemStreamStore)"
                v-tooltip="`Apply search query: ${itemStreamStore.search.query}`"
                class="inline-flex items-center px-3 py-1.5 text-sm font-medium leading-none bg-gray-100 text-gray-900 rounded-sm max-w-full hover:bg-gray-300 leading-4"
            >
                <ui-icon name="search" class="text-xs mr-1 shrink-0"></ui-icon>
                <p class="line-clamp-3">{{ itemStreamStore.search.query }}</p>
            </div>

            <template v-for="filter in visibleFilters" :key="filter.id">
                <filter-date :filter="filter" :store="itemStreamStore" v-if="filter.type == 'date' && itemStreamStore.getFilterValue(filter.id)"></filter-date>
                <filter-labels :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'labels' && itemStreamStore.getFilterValue(filter.id)"></filter-labels>
                <filter-lists :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'target-list' && itemStreamStore.getFilterValue(filter.id)"></filter-lists>
                <filter-targets :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'targets' && itemStreamStore.getFilterValue(filter.id)"></filter-targets>
                <filter-media :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'media' && itemStreamStore.getFilterValue(filter.id)"></filter-media>
                <filter-mentions :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'mentions' && itemStreamStore.getFilterValue(filter.id)"></filter-mentions>
                <filter-metrics :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'metrics' && itemStreamStore.getFilterValue(filter.id)"></filter-metrics>
                <filter-options :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'options' && itemStreamStore.getFilterValue(filter.id)"></filter-options>
                <filter-semantic-query :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'semantic-query' && itemStreamStore.getFilterValue(filter.id)"></filter-semantic-query>
                <filter-tags :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'tags' && itemStreamStore.getFilterValue(filter.id)"></filter-tags>
                <filter-performance :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'performance' && (itemStreamStore.getFilterValue(filter.filters[0].id) || itemStreamStore.getFilterValue(filter.filters[1].id))"></filter-performance>
                <filter-features :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'features' && (itemStreamStore.getFilterValue(filter.filters[0].id) || itemStreamStore.getFilterValue(filter.filters[1].id))"></filter-features>
                <filter-deleted :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'deleted' && itemStreamStore.getFilterValue(filter.id)"></filter-deleted>
                <filter-types :filter="filter" :store="itemStreamStore" v-else-if="filter.type == 'types' && (itemStreamStore.getFilterValue(filter.filters[0].id) || itemStreamStore.getFilterValue(filter.filters[1].id))"></filter-types>
            </template>
        </div>

        <p class="text-2xs text-gray-700 shrink-0 text-right">{{ $dateTime(search.createdAt) }}</p>
    </a>
</template>

<script>
import FilterDate from '@/components/ui/stream/filters/filter-date'
import FilterLabels from '@/components/ui/stream/filters/filter-labels'
import FilterLists from '@/components/ui/stream/filters/filter-lists'
import FilterTargets from '@/components/ui/stream/filters/filter-targets'
import FilterMedia from '@/components/ui/stream/filters/filter-media'
import FilterMetrics from '@/components/ui/stream/filters/filter-metrics'
import FilterMentions from '@/components/ui/stream/filters/filter-mentions'
import FilterOptions from '@/components/ui/stream/filters/filter-options'
import FilterSemanticQuery from '@/components/ui/stream/filters/filter-semantic-query'
import FilterTags from '@/components/ui/stream/filters/filter-tags'
import FilterPerformance from '@/components/ui/stream/filters/filter-performance'
import FilterFeatures from '@/components/ui/stream/filters/filter-features'
import FilterDeleted from '@/components/ui/stream/filters/filter-deleted'
import FilterTypes from '@/components/ui/stream/filters/filter-types'

import defineStreamStore from '@/stores/reusable/stream'
import useModalsSearchHistoryStore from '@/stores/modals/search-history'

import { mapActions } from 'pinia'

export default {
    props: {
        search: {
            required: true
        },
        nextSearch: {},
        streamStore: {
            required: true
        },
        focused: {}
    },

    components: {
        FilterDate, FilterLabels, FilterLists, FilterTargets, FilterMedia, FilterMetrics, FilterMentions,
        FilterOptions, FilterSemanticQuery, FilterTags, FilterPerformance, FilterFeatures, FilterDeleted, FilterTypes
    },

    computed: {
        itemStreamStore() {
            let store = defineStreamStore({
                id: 'stream-store-' + this.search.id,
                filters: this.streamStore.filters.map(f => ({...f, readOnly: true})),
                sortingOptions: [ ...this.streamStore.sortingOptions ]
            })()

            store.appliedFilters = Object.entries(this.search.filters)
                .map(([filter, value]) => {
                    filter = store.filters.find(f => f.id == filter)
                    value = store.unserializeFilterFrom('api', filter, value)

                    return {filter, value}
                }).filter(v => v && v.value)

            store.search.query = store.appliedFilters.find(a => a.filter.search)?.value.query

            return store
        },

        visibleFilters() {
            return this.itemStreamStore.filters.filter(f => ! f.hidden)
        },

        visibleAppliedFilters() {
            return this.itemStreamStore.appliedFilters.filter(f => ! f.filter.hidden)
        }
    },

    methods: {
        ...mapActions(useModalsSearchHistoryStore, {
            applySearch: 'apply',
            applySearchQuery: 'applyQuery'
        }),

        open() {
            this.applySearch(this.streamStore, this.search)
        }
    }
}
</script>
