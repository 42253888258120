<template>
    <ui-dropdown align="right" z-index="50" width="w-56" v-if="['language', 'mixed'].includes(searchType) && selectedOption">
        <template v-slot:trigger>
            <div class="flex" v-tooltip="selectedOption.name">
                <ui-icon v-if="selectedOption.icon" :name="selectedOption.icon" class="text-2xl cursor-pointer border border-blue-600 hover:border-blue-900 rounded-full"></ui-icon>
            
                <a v-else href="#" class="text-2xs font-medium px-1 uppercase text-blue-600 hover:text-blue-900 cursor-pointer border border-blue-600 hover:border-blue-900 rounded-full">
                    {{selectedOption.id}}
                </a>
            </div>
        </template>

        <template v-slot:content="{ close }">
            <div @click.stop>
                <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1">
                    Input Language
                </div>

                <div class="px-2 py-1">
                    <div class="relative w-full h-7">
                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
                    </div>
                </div>

                <div class="max-h-48 my-0 overflow-y-auto">
                    <template :key="index" v-for="option, index in options">
                        <ui-dropdown-link :icon="option.icon" :selected="searchLanguage === option.id" @click="setSearchLanguage(option.id)" v-if="option.id">
                            {{option.name}}
                        </ui-dropdown-link>

                        <ui-dropdown-separator v-else></ui-dropdown-separator>
                    </template>

                    <div class="text-gray-700 text-sm text-center" v-if="! options.length">
                        No results
                    </div>
                </div>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
import languageOptions from '@/stores/reusable/stream/language-filter-options'

import { textSearch } from '@/helpers'

export default {
    data: () => ({
        search: ''
    }),

    computed: {
        options() {
            return textSearch(this.search, languageOptions.slice(0, -2), o => o.name || true)
        },

        searchType() {
            if (this.store.search.language?.startsWith('exact-')) { return 'exact' }
            if (this.store.search.language?.startsWith('mixed-')) { return 'mixed' }

            return 'language'
        },
        
        searchLanguage() {
            if (this.store.search.language?.startsWith('exact-')) return null
            if (this.store.search.language?.startsWith('mixed-')) return this.store.search.language.split('-')[1]

            return this.store.search.language
        },
        
        selectedOption() {
            return languageOptions.find(o => o.id == this.searchLanguage)
        }
    },

    methods: {
        setSearchLanguage(language) {
            if (this.searchType === 'exact') return
            if (this.searchType === 'mixed') return this.store.applySearchLanguage(`mixed-${language}`)

            return this.store.applySearchLanguage(language)
        }
    }
}
</script>
