<template>
    <div class="max-h-screen absolute top-0 right-0 w-full max-w-sm flex flex-col py-4 z-50" v-show="isShown" ref="toaster">
        <div class="h-10 w-full flex items-start justify-center absolute top-4" v-if="toasts.length > 2">
            <a href="#" @click.prevent="dismissAllToasts" class="flex items-center justify-center bg-gray-900 text-gray-400 rounded-xl py-1 px-2.5 leading-0 font-medium text-sm">
                <ui-icon name="x" class="mr-1"></ui-icon>
                <span>Dismiss all notifications</span>
            </a>
        </div>

        <transition-group
            tag="div"
            class="flex flex-col items-end overflow-y-auto px-4 pb-10" :class="{ 'pt-10': toasts.length > 2, 'pb-10': ! isScrolledToTheBottom }"
            enter-active-class="ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            appear
            @scroll="handleScroll">
            <div class="w-full shrink-0 bg-white shadow-lg rounded-lg pointer-events-auto border border-gray-200 flex items-center overflow-hidden mb-4 group relative" :key="toast.id" v-for="toast in toasts">
                <div class="shrink-0 flex items-center justify-center text-xl py-3 ml-4">
                    <ui-progress v-if="toast.progressTotalUnits && toast.progressCompletedUnits < toast.progressTotalUnits" :total-steps="toast.progressTotalUnits" :completed-steps="toast.progressCompletedUnits"></ui-progress>
                    <ui-icon :name="toast.icon" :class="{'text-green-600': toast.type == 'success', 'text-red-600': toast.type == 'error', 'text-blue-600': toast.type == 'info'}" v-else-if="toast.icon"></ui-icon>
                    <ui-icon name="check-full-circle" class="text-green-600" v-else-if="toast.type == 'success'"></ui-icon>
                    <ui-icon name="error-full-circle" class="text-red-500" v-else-if="toast.type == 'error'"></ui-icon>
                    <ui-icon name="info" class="text-blue-500" v-else-if="toast.type == 'info'"></ui-icon>
                </div>
                <div class="w-0 flex-1 py-3 ml-3 mr-2">
                    <div class="flex justify-between items-start">
                        <div class="text-sm font-medium" :class="{'text-green-600': toast.type == 'success', 'text-red-600': toast.type == 'error', 'text-blue-600': toast.type == 'info'}" v-html="sanitizeHtml(toast.title)"></div>
                        <div class="text-xs text-gray-500 ml-1">{{currentDate ? formatToastDate(toast.createdAt) : null}}</div>
                    </div>
                    <p class="mt-0.5 text-xs text-gray-800" v-if="toast.text" v-html="sanitizeHtml(toast.text)"></p>
                </div>
                <div class="hidden group-hover:flex justify-end bg-gradient-to-r from-transparent to-white absolute inset-0">
                    <div class="flex flex-col">
                        <div class="h-0 flex-1 flex">
                            <button class="w-full px-3 py-2 text-sm font-medium text-blue-600 hover:text-blue-900" @click="dismissToast(toast)">
                                Dismiss
                            </button>
                        </div>
                        <div class="h-0 flex-1 flex" v-if="toast.action">
                            <button class="w-full px-3 py-2 text-sm font-medium text-blue-600 hover:text-blue-900" @click="toast.onAction(toast)">
                                {{toast.action}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>

        <div class="absolute bottom-4 flex items-end justify-center h-10 w-full" v-if="! isScrolledToTheBottom">
            <div class="flex items-center justify-center bg-gray-900 rounded-full h-8 w-8">
                <ui-icon name="arrow-down" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </div>
</template>

<script>
import useMyNotifications from '@/stores/me/notifications'
import useSupportChatStore from '@/stores/support/chat'

import { sanitizeHTML } from '@/helpers'

import { format } from 'date-fns'
import { mapActions, mapState } from 'pinia'

export default {
    data: () => ({
        isScrolledToTheBottom: true,
        currentDate: +(new Date)
    }),

    mounted() {
        this.isScrolledToTheBottom = this.$refs.toaster.scrollHeight < window.innerHeight

        setInterval(() => this.currentDate = +(new Date), 60000)
    },

    computed: {
        ...mapState(useMyNotifications, [ 'toasts' ]),

        isShown() {
            return this.toasts.length && ! useMyNotifications().isOverlayShown && ! useSupportChatStore().isShown
        }
    },

    methods: {
        ...mapActions(useMyNotifications, [ 'dismissToast', 'dismissAllToasts' ]),

        sanitizeHtml(html) {
            return sanitizeHTML(html)
        },

        handleScroll(el) {
            this.isScrolledToTheBottom = (el.target.offsetHeight + el.target.scrollTop + 40) >= el.target.scrollHeight
        },

        formatToastDate(date) {
            let diffInSeconds = ((new Date) - date) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(date, 'H:mm')
            } else {
                return format(date, 'LLL do')
            }
        }
    }
}
</script>
