<template>
    <ui-modal name="note" title="Note" width="640" max-height="90vh" unstyled @opened="opened">
        <div class="bg-yellow-200 border border-yellow-600 shadow-xl flex flex-col h-auto w-full rounded-lg max-h-[90vh]">
            <div class="flex items-center justify-between px-8 pt-5 px-8">
                <div class="text-sm text-yellow-700">
                    Last updated: <span class="font-medium">{{ note ? $date(note.updatedAt) : '-' }}</span>
                </div>

                <div class="space-x-2 flex items-center">
                    <a href="#" class="text-xl text-yellow-800 hover:text-yellow-900 leading-none" @click.prevent="edit" v-tooltip="'Edit'">
                        <ui-icon name="edit"></ui-icon>
                    </a>
                    <a href="#" class="text-xl text-yellow-800 hover:text-yellow-900 leading-none" @click.prevent="remove" v-tooltip="'Delete'">
                        <ui-icon name="trash"></ui-icon>
                    </a>
                    <a href="#" class="text-xl text-yellow-800 hover:text-yellow-900 leading-none" @click.prevent="$modal.hide('note')" v-tooltip="'Close'">
                        <ui-icon name="remove"></ui-icon>
                    </a>
                </div>
            </div>

            <div v-if="! isEditing" class="flex-1 min-h-0 w-full overflow-y-auto">
                <div v-html="body" class="prose prose-sm prose-zinc px-8 py-5 max-w-full"></div>
            </div>

            <div v-else class="px-8 py-5 flex-1 min-h-0 flex w-full">
                <ui-form :store="meModalsNoteFormStore" v-slot="{ inputs, submitting, errors }" class="flex flex-col w-full">
                    <ui-editor class="flex flex-col min-h-[20rem] w-full" v-model="inputs.body" :features="['h1', 'h2', 'h3', 'bold', 'italic', 'list-unordered', 'list-ordered']" ref="editor"></ui-editor>

                    <div class="flex items-center justify-between mt-6">
                        <ui-access-input v-model="inputs.workspace" v-if="! note"></ui-access-input>
                        <div v-else></div>

                        <ui-button type="submit" color="blue" :disabled="submitting">
                            Save
                        </ui-button>
                    </div>
                </ui-form>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import UiEditor from '@/components/ui/editor'

import useMeModalsNoteStore from '@/stores/me/modals/note'
import useMeModalsNoteFormStore from '@/stores/me/modals/note-form'

import { sanitizeHTML } from '@/helpers'

import { marked } from 'marked'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: { UiEditor },

    computed: {
        ...mapStores(useMeModalsNoteFormStore),
        ...mapState(useMeModalsNoteStore, [ 'note', 'isEditing' ]),

        body() {
            return this.note?.body ? sanitizeHTML(marked.parse(this.note.body)) : ''
        }
    },

    methods: {
        ...mapActions(useMeModalsNoteStore, [ 'edit', 'remove' ]),
        
        opened() {
            this.$refs.editor?.editor?.commands.focus()
        }
    }
}
</script>
