<template>
    <ui-dropdown align="left" width="w-32" class="mt-1">
        <template v-slot:trigger>
            <a href="#" class="-mt-1 bg-white rounded-lg flex h-[36px] text-2xl flex items-center justify-center w-11 text-blue-600 hover:text-blue-900 hover:bg-gray-50">
                <ui-icon name="analysis" class="text-xl mr-1"></ui-icon>
            </a>
        </template>

        <template v-slot:content>
            <ui-dropdown-link @click="store.analyze(analysis.id)" :key="analysis.id" v-for="analysis in store.analyses">
                {{analysis.name}}
            </ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>
