import useContentPerspectiveStreamStore from '@/stores/content/perspective/stream'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedTopicsStore from '@/stores/me/curated-topics'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useTaggingStore from '@/stores/tagging/tagging'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { defineStore } from 'pinia'

export const useContentIndexPage = defineStore({
    id: 'contentIndexPage',

    getters: {
        title: () => 'Content',

        streamStore: store => useContentPerspectiveStreamStore(),

        showFilters: () => true
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            this.streamStore.whilePaused(store => store.reset())
            this.streamStore.filters.set({ date: { type: 'past', date: { past: 1, unit: 'months' } } })
            this.streamStore.initialize(to)

            useMyBookmarksStore().initialize()
            useMyCuratedTopicsStore().initialize()
            useMyPerspectivesStore().initialize()
            useMySearchTopicsStore().initialize()
            useTaggingStore().initialize()
            useMyTargetListsStore().initialize()
        },

        async afterLeave(to, from) {
            if (to.path != from.path) {
                this.streamStore.whilePaused(store => store.reset())
            }
        }
    }
})

export default useContentIndexPage
