<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Dashboards
    </h1>

    <navigation-section :items="topNavigation"></navigation-section>
</template>

<script>
export default {
    data: () => ({
        topNavigation: [
            { name: 'Overview', icon: 'navigation.sub.overview', to: { name: 'dashboards' } }
        ]
    })
}
</script>
