<template>
    <VMenu theme="link" placement="top" :delay="{ show: 750, hide: 200 }">
        <slot name="text">
            <span :class="textClass">{{title}}</span>
        </slot>
        <template #popper>
            <a :href="$router.resolve({ name: 'targets.target.information', params: { type: family, modelType: type, id } }).href" target="_blank" class="block">
                <div class="flex items-center w-64 p-1">
                    <div class="shrink-0 relative">
                        <ui-avatar :item="{ family, title, avatar }" class="w-8 h-8"></ui-avatar>
                        <ui-icon :name="`badges.target-${type}`" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! monitored }"></ui-icon>
                    </div>

                    <div class="flex-1 text-left min-w-0 px-3">
                        <h1 class="text-lg font-semibold truncate leading-tight">
                            {{ title }}
                        </h1>
                        <div class="text-gray-800 text-xs leading-tight truncate">
                            {{typeToHuman(type)}}
                        </div>
                    </div>

                    <ui-icon name="chevron-right" class="text-xl text-gray-800 ml-auto"></ui-icon>
                </div>
            </a>
        </template>
    </VMenu>
</template>

<script>
import targetTypesStore from '@/stores/targets/target-types'

import { mapActions } from 'pinia'

export default {
    props: [ 'avatar', 'family', 'id', 'monitored', 'title', 'textClass', 'type' ],

    methods: {
        ...mapActions(targetTypesStore, [ 'typeToHuman' ])
    }
}
</script>
