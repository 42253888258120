<template>
    <div class="max-w-12xl mx-auto px-8">
        <stream-toolbar-results :store="$page.streamStore"></stream-toolbar-results>

        <ui-cards :store="$page.streamStore">
            <template v-slot:item="{ item }">
                <content-facebook-group-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-if="item.type == 'facebook-group-post'"></content-facebook-group-post-card>
                <content-facebook-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'facebook-post'"></content-facebook-post-card>
                <content-facebook-post-comment-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'facebook-post-comment'"></content-facebook-post-comment-card>
                <content-facebook-user-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'facebook-user-post'"></content-facebook-user-post-card>
                <content-imageboard-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'imageboard-post'"></content-imageboard-post-card>
                <content-instagram-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'instagram-post'"></content-instagram-post-card>
                <content-reddit-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'reddit-post'"></content-reddit-post-card>
                <content-telegram-channel-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'telegram-channel-post'"></content-telegram-channel-post-card>
                <content-telegram-group-message-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'telegram-group-message'"></content-telegram-group-message-card>
                <content-tiktok-video-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'tiktok-video'"></content-tiktok-video-card>
                <content-tiktok-video-comment-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'tik-tok-video-comments'"></content-tiktok-video-comment-card>
                <content-twitter-tweet-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'twitter-tweet'"></content-twitter-tweet-card>
                <content-vkontakte-post-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'vkontakte-post'"></content-vkontakte-post-card>
                <content-web-article-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'web-article'"></content-web-article-card>
                <content-web-resource-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'web-resource'"></content-web-resource-card>
                <content-youtube-video-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'youtube-video'"></content-youtube-video-card>
                <content-youtube-video-comment-card :content="item" :stream-store="$page.streamStore" :key="item.id" v-else-if="item.type == 'youtube-video-comment'"></content-youtube-video-comment-card>
            </template>
        </ui-cards>

        <div v-if="$page.streamStore.items.error">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="frown" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">Ooops, something went wrong!</div>
                <div class="text-center text-sm">
                    <p>Looks like we are experiencing some technical issues (sorry about that).</p>
                    <p>Please try again later, if the problem persists, please <a class="underline" href="mailto:support@gerulata.com">contact us</a> for further assistance.</p>
                </div>
            </div>
        </div>

        <div v-if="! $page.streamStore.items.data?.length && ! $page.streamStore.items.pending && $page.streamStore.isInitialized">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="search" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">
                    <p>Check your spelling and try again, or try a different set of filters.</p>
                </div>
            </div>
        </div>

        <ui-infinite-loading class="mt-12 mb-8" :identifier="$page.streamStore.lastReloadTime" @infinite="$page.streamStore.loadMore($event)" v-if="$page.streamStore.items.data && $page.streamStore.items.data.length"></ui-infinite-loading>

        <Teleport to="#root">
            <stream-lightbox :store="$page.streamStore.mediaLightbox"></stream-lightbox>
        </Teleport>
    </div>
</template>

<script>
import ContentFacebookGroupPostCard from '@/components/content/stream-cards/facebook-group-post'
import ContentFacebookPostCard from '@/components/content/stream-cards/facebook-post'
import ContentFacebookPostCommentCard from '@/components/content/stream-cards/facebook-post-comment'
import ContentFacebookUserPostCard from '@/components/content/stream-cards/facebook-user-post'
import ContentImageboardPostCard from '@/components/content/stream-cards/imageboard-post'
import ContentInstagramPostCard from '@/components/content/stream-cards/instagram-post'
import ContentRedditPostCard from '@/components/content/stream-cards/reddit-post'
import ContentTelegramChannelPostCard from '@/components/content/stream-cards/telegram-channel-post'
import ContentTelegramGroupMessageCard from '@/components/content/stream-cards/telegram-group-message'
import ContentTiktokVideoCard from '@/components/content/stream-cards/tiktok-video'
import ContentTiktokVideoCommentCard from '@/components/content/stream-cards/tiktok-video-comment'
import ContentTwitterTweetCard from '@/components/content/stream-cards/twitter-tweet'
import ContentVkontaktePostCard from '@/components/content/stream-cards/vkontakte-post'
import ContentWebArticleCard from '@/components/content/stream-cards/web-article'
import ContentWebResourceCard from '@/components/content/stream-cards/web-resource'
import ContentYoutubeVideoCard from '@/components/content/stream-cards/youtube-video'
import ContentYoutubeVideoCommentCard from '@/components/content/stream-cards/youtube-video-comment'

import StreamLightbox from './stream/lightbox'
import StreamToolbarResults from './stream/toolbar'

export default {
    components: {
        ContentFacebookGroupPostCard, ContentFacebookPostCard, ContentFacebookPostCommentCard,
        ContentFacebookUserPostCard, ContentImageboardPostCard, ContentInstagramPostCard, ContentRedditPostCard,
        ContentTelegramChannelPostCard, ContentTelegramGroupMessageCard, ContentTwitterTweetCard, ContentTiktokVideoCard,
        ContentTiktokVideoCommentCard, ContentVkontaktePostCard, ContentWebArticleCard, ContentWebResourceCard,
        ContentYoutubeVideoCard, ContentYoutubeVideoCommentCard,
        StreamLightbox, StreamToolbarResults
    }
}
</script>
