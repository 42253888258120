<template>
    <div v-if="sources && sources.length" class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md px-6 py-4">
        <div class="mb-6">
            <h3 class="font-semibold text-gray-900">
                Most Active Members
            </h3>
        </div>

        <div>
            <router-link
                :to="{ name: 'targets.target.information', params: { type: 'sources', modelType: source.type, id: source.id } }"
                target="_blank"
                v-for="source in sources"
                :key="source.id"
                class="flex items-center justify-between py-1"
            >
                <div class="flex items-center">
                    <ui-avatar :item="source" class="w-5 h-5 mr-2"></ui-avatar>
                    <p class="tracking-tight">{{ source.name }}</p>
                </div>
                <div>
                    <span class="font-medium">{{ source.count }}</span> <span>posts</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import useTargetsTargetInformationTopSourcesStore from '@/stores/targets/target/information-top-sources'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useTargetsTargetInformationTopSourcesStore, [ 'sources' ])
    }
}
</script>
