import useMeModalsNoteStore from '@/stores/me/modals/note'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'

export const useMeModalsNoteFormStore = defineForm({
    id: 'meModalsNoteForm',

    inputs: () => ({
        body: '',
        workspace: null
    }),

    state: () => ({
        item: null,
        note: null
    }),

    submitRequest() {
        if (this.note) {
            return api.route('me notes update', { id: this.note.id })
                .json({ body: this.inputs.body })
                .put()
        } else {
            return api.route('me notes store')
                .json({
                    body: this.inputs.body,
                    subject: { family: this.item.family, id: this.item.id },
                    workspace: this.inputs.workspace
                })
                .post()
        }
    },

    async onResponse(res) {
        if (this.note) {
            Object.assign(this.note, res.data)
        } else {
            this.item.notes.push(res.data)
            useMeModalsNoteStore().note = res.data
        }

        useMeModalsNoteStore().isEditing = false
    },

    actions: {
        initialize(item, note) {
            this.reset()

            this.item = item
            this.note = note

            this.inputs.body = note?.body
        }
    }
})

export default useMeModalsNoteFormStore
