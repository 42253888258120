<template>
    <ui-modal name="content-notifications-manage" unstyled content-class="bg-white flex flex-col rounded-md h-128 max-h-full w-full" max-width="768">
        <div class="flex items-center justify-between px-8 py-6 border-b border-gray-200">
            <h1 class="font-semibold text-gray-900">
                Notifications
            </h1>

            <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="$modal.hide('content-notifications-manage')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>

        <div class="flex flex-1 min-h-0" v-if="mode == 'index'">
            <div class="flex flex-col w-1/3 p-2 pb-4 space-y-3 bg-gray-75 rounded-bl-md border-r border-gray-200">
                <div class="flex flex-col flex-1 space-y-1 overflow-y-auto">
                    <a href="#" :key="subscription.id" v-for="subscription in subscriptions" class="px-3 py-2 rounded" :class="{ 'bg-gray-200': subscription == selectedSubscription }" @click.prevent="editSubscription(subscription)">
                        <ui-icon :name="typeIcons[subscription.type]" class="mr-1"></ui-icon>
                        {{subscription.name}}
                    </a>
                </div>
                <div class="flex justify-center">
                    <a href="#" class="flex items-center text-blue-500 hover:text-blue-800 text-sm" @click.prevent="createSubscription()">
                        <ui-icon name="plus-circle" class="mr-1"></ui-icon>
                        Create Notification
                    </a>
                </div>
            </div>

            <ui-form :store="contentModalsNotificationsManageFormStore" v-slot="{ inputs, submitting, errors }" class="flex flex-col flex-1 p-4 rounded-r-md" v-if="selectedSubscription">
                <div class="pb-5 flex items-center justify-between border-b border-gray-200">
                    <span class="text-lg font-semibold">
                        <ui-icon :name="typeIcons[selectedSubscription.type]" class="mr-1"></ui-icon>
                        {{selectedSubscription.name}}
                    </span>
                    <ui-switch v-model="inputs.enabled"></ui-switch>
                </div>

                <div class="flex-1 overflow-y-auto py-5">
                    <div class="space-y-4 border-b border-gray-200 pb-5 mb-5">
                        <div>
                            <div class="flex items-center mb-3 font-medium text-gray-800">
                                <ui-icon name="bell" class="mr-1"></ui-icon>
                                <h2>In-App</h2>
                                <ui-switch @click="toggleChannel('inApp')" :value="inputs.channels.inApp" class="ml-auto"></ui-switch>
                            </div>

                            <div class="px-4 text-sm" v-if="inputs.channels.inApp">
                                <notifications-manage-users channel="inApp" :store="contentModalsNotificationsManageFormStore"></notifications-manage-users>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center mb-3 font-medium text-gray-800">
                                <ui-icon name="email" class="mr-1"></ui-icon>
                                <h2>Email</h2>
                                <ui-switch @click="toggleChannel('email')" :value="inputs.channels.email" class="ml-auto"></ui-switch>
                            </div>

                            <div class="px-4 text-sm" v-if="inputs.channels.email">
                                <notifications-manage-users channel="email" :store="contentModalsNotificationsManageFormStore"></notifications-manage-users>

                                <div class="mt-2 space-y-2">
                                    <div class="flex items-center space-x-3" :key="index" v-for="recipient, index in inputs.configuration.email.additionalRecipients">
                                        <ui-input size="sm" v-model="inputs.configuration.email.additionalRecipients[index]"></ui-input>
                                        <a href="#" @click.prevent="inputs.configuration.email.additionalRecipients.splice(index, 1)">
                                            <ui-icon name="trash"></ui-icon>
                                        </a>
                                    </div>

                                    <a href="#" @click.prevent="inputs.configuration.email.additionalRecipients.push('')" class="block text-blue-500 hover:text-blue-800">
                                        <ui-icon name="plus-circle"></ui-icon>
                                        Add additional recepient
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center mb-3 font-medium text-gray-800">
                                <ui-icon name="mattermost" class="mr-1"></ui-icon>
                                <h2>Mattermost</h2>
                                <ui-switch @click="toggleChannel('mattermost')" :value="inputs.channels.mattermost" class="ml-auto"></ui-switch>
                            </div>

                            <div class="px-4" v-if="inputs.channels.mattermost">
                                <label for="notifications-mattermost-webhookUrl" class="block text-sm font-medium text-gray-700">
                                    Webhook URL
                                </label>

                                <ui-input id="notifications-mattermost-webhookUrl" size="sm" v-model="inputs.configuration.mattermost.webhookUrl"></ui-input>

                                <div class="flex space-x-3 mt-3">
                                    <div class="flex-1">
                                        <label for="notifications-mattermost-channel" class="block text-sm font-medium text-gray-700">
                                            Channel
                                        </label>

                                        <ui-input id="notifications-mattermost-channel" size="sm" placeholder="(optional)" v-model="inputs.configuration.mattermost.channel"></ui-input>
                                    </div>

                                    <div class="flex-1">
                                        <label for="notifications-mattermost-username" class="block text-sm font-medium text-gray-700">
                                            Username
                                        </label>

                                        <ui-input id="notifications-mattermost-username" size="sm" placeholder="(optional)" v-model="inputs.configuration.mattermost.username"></ui-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="space-y-3">
                        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                            Name
                        </label>
                        <div>
                            <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
                        </div>

                        <template v-if="selectedSubscription.type == 'content-alert'">
                            <label for="scope" class="block text-sm text-gray-700 font-medium mb-1">
                                Scope
                            </label>

                            <div>
                                <div class="flex items-center">
                                    <span>Alert on pre-existing content</span>
                                    <ui-help-button class="ml-3" size="smaller" @click.prevent v-tooltip="'By default, we only notify on content published after an alert is created. This is to prevent getting too many notifications. Enabling \'Alert on pre-existing content\' will also scan for content published earlier than this alert was created. Useful for getting notifications on deleted content or when hitting certain performance metrics (eg. interactions or views).'"></ui-help-button>
                                    <ui-switch @click="toggleRetrospective" :value="inputs.configuration.retrospective" class="ml-auto"></ui-switch>
                                </div>
                            </div>
                        </template>

                        <template v-if="selectedSubscription.type == 'digest'">
                            <label for="frequency" class="block text-sm text-gray-700 font-medium mb-1">
                                Frequency
                            </label>
                            <div>
                                <ui-select id="frequency" :options="[ { id: 'daily', label: 'Daily' }, { id: 'weekly', label: 'Weekly' }, { id: 'monthly', label: 'Monthly' } ]" :reduce="v => v.id" v-model="inputs.configuration.frequency" :errors="errors['configuration.frequency']" ref="frequency">
                                </ui-select>
                            </div>

                            <label for="metric" class="block text-sm text-gray-700 font-medium mb-1">
                                Metric
                            </label>
                            <div>
                                <ui-select id="metric" :options="[ { id: 'interactions', label: 'Interactions' }, { id: 'reactions', label: 'Reactions' }, { id: 'comments', label: 'Comments' }, { id: 'shares', label: 'Shares' }, { id: 'views', label: 'Views' } ]" :reduce="v => v.id" v-model="inputs.configuration.metric" :errors="errors['configuration.metric']" ref="metric">
                                </ui-select>
                            </div>

                            <label for="count" class="block text-sm text-gray-700 font-medium mb-1">
                                Content Count (50 max)
                            </label>
                            <div>
                                <ui-input type="text" id="count" v-model="inputs.configuration.count" :errors="errors['configuration.count']" ref="count"></ui-input>
                            </div>
                        </template>

                        <template v-if="selectedSubscription.type == 'leaderboard'">
                            <label for="frequency" class="block text-sm text-gray-700 font-medium mb-1">
                                Frequency
                            </label>
                            <div>
                                <ui-select id="frequency" :options="[ { id: 'daily', label: 'Daily' }, { id: 'weekly', label: 'Weekly' }, { id: 'monthly', label: 'Monthly' } ]" :reduce="v => v.id" v-model="inputs.configuration.frequency" :errors="errors['configuration.frequency']" ref="frequency">
                                </ui-select>
                            </div>

                            <label for="metric" class="block text-sm text-gray-700 font-medium mb-1">
                                Metric
                            </label>
                            <div>
                                <ui-select id="metric" :options="[ { id: 'interactions', label: 'Interactions' }, { id: 'reactions', label: 'Reactions' }, { id: 'comments', label: 'Comments' }, { id: 'shares', label: 'Shares' }, { id: 'views', label: 'Views' } ]" :reduce="v => v.id" v-model="inputs.configuration.metric" :errors="errors['configuration.metric']" ref="metric">
                                </ui-select>
                            </div>

                            <label for="count" class="block text-sm text-gray-700 font-medium mb-1">
                                Sources Count (50 max)
                            </label>
                            <div>
                                <ui-input type="text" id="count" v-model="inputs.configuration.count" :errors="errors['configuration.count']" ref="count"></ui-input>
                            </div>
                        </template>
                    </div>

                    <div class="flex justify-between mt-8">
                        <ui-button color="red" :action="deleteSubscription">
                            Delete
                        </ui-button>

                        <ui-button type="submit" color="blue" :disabled="submitting">
                            Save
                        </ui-button>
                    </div>
                </div>
            </ui-form>
        </div>

        <div class="flex-1 flex items-center justify-center px-16" v-else-if="mode == 'create-subscription'">
            <div class="space-y-6 text-gray-800 w-full">
                <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer text-blue-500 hover:text-blue-800 bg-gray-50 hover:bg-blue-50 rounded-lg text-lg" @click.prevent="createSubscription('content-alert')">
                    <ui-icon :name="typeIcons['content-alert']" class="text-3xl mr-4"></ui-icon>
                    <div>
                        <h1 class="text-lg font-medium">Content Alert</h1>
                        <p>Get notified on every new post matching this perspective.</p>
                    </div>
                    <div class="ml-auto pl-4">
                        <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                    </div>
                </a>
                <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer text-blue-500 hover:text-blue-800 bg-gray-50 hover:bg-blue-50 rounded-lg text-lg" @click.prevent="createSubscription('digest')">
                    <ui-icon :name="typeIcons['digest']" class="text-3xl mr-4"></ui-icon>
                    <div>
                        <h1 class="text-lg font-medium">Digest</h1>
                        <p>Receive daily, weekly or monthly digests with top content.</p>
                    </div>
                    <div class="ml-auto pl-4">
                        <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                    </div>
                </a>
                <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer text-blue-500 hover:text-blue-800 bg-gray-50 hover:bg-blue-50 rounded-lg text-lg" @click.prevent="createSubscription('leaderboard')">
                    <ui-icon :name="typeIcons['leaderboard']" class="text-3xl mr-4"></ui-icon>
                    <div>
                        <h1 class="text-lg font-medium">Leaderboard</h1>
                        <p>Receive daily, weekly or monthly leaderboards with top sources.</p>
                    </div>
                    <div class="ml-auto pl-4">
                        <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                    </div>
                </a>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import NotificationsManageUsers from './notifications-manage-users'

import useContentModalsNotificationsManageStore from '@/stores/content/modals/notifications-manage'
import useContentModalsNotificationsManageFormStore from '@/stores/content/modals/notifications-manage-form'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    components: { NotificationsManageUsers },

    data: () => ({
        typeIcons: { 'content-alert': 'alert-triangle', 'digest': 'clipboard', 'leaderboard': 'award' }
    }),

    computed: {
        ...mapState(useContentModalsNotificationsManageStore, {
            subscriptions: store => [ ...store.subscriptions ].sort((a, b) => a.name.localeCompare(b.name)),
            mode: 'mode'
        }),

        ...mapWritableState(useContentModalsNotificationsManageStore, [ 'selectedSubscription' ]),

        ...mapStores(useContentModalsNotificationsManageFormStore)
    },

    methods: {
        ...mapActions(useContentModalsNotificationsManageStore, [ 'createSubscription', 'editSubscription' ]),
        ...mapActions(useContentModalsNotificationsManageFormStore, {
            'deleteSubscription': 'delete', 'toggleRetrospective': 'toggleRetrospective', 'toggleChannel': 'toggleChannel'
        })
    }
}
</script>
