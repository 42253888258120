import api from "@/api";
import { useModal } from "@/helpers";
import { defineForm } from "@/stores/reusable/form";

import useAccountSettingsStore from '@/stores/settings/account'

export const useTwoFactorAuthFormStore = defineForm({
    id: 'twoFactorAuthForm',

    state: () => ({
        isGenerating: false,
        isScannedOrCopied: false,
        isRecoveryPhraseBackedUp: false,
        isSuccessful: false,

        secret: null,
        secretDataUri: null,
        recoveryPhrase: null
    }),

    inputs: () => ({
        code: null,
        currentPassword: null
    }),

    submitRequest() {
        return api.route('settings two-factor store')
            .formData({
                code: this.inputs.code,
                secret: this.state,
                recoveryPhrase: this.recoveryPhrase
            })
            .post()
            .error(403, err => {
                this.submitting = false
                this.errors = { other: [ err.json.message ] }
            })
    },

    async onResponse(res) {
        this.refresh()
        useAccountSettingsStore().load()

        this.isSuccessful = true
    },

    actions: {
        open() {
            this.refresh()

            if (! useAccountSettingsStore().hasTwoFactorAuth) {
                this.isGenerating = true

                api.route('settings two-factor generate').get().json(res => {
                    this.secret = res.secret
                    this.secretDataUri = res.secretDataUri
                    this.recoveryPhrase = res.recoveryPhrase

                    this.isGenerating = false
                })
            }

            useModal().show('two-factor-auth');
        },

        remove() {
            api.route('settings two-factor delete')
                .formData({
                    _method: 'delete',
                    currentPassword: this.inputs.currentPassword
                })
                .post()
                .error(422, err => { this.errors = err.json.errors })
                .error(403, err => {
                    this.submitting = false
                    this.errors = { other: [ err.json.message ] }
                })
                .res(() => {
                    useModal().hide('two-factor-auth')
                    this.refresh()
                    useAccountSettingsStore().load()
                })
        },

        close() {
            useModal().hide('two-factor-auth')
        },

        refresh() {
            this.errors = {}
            this.submitting = false

            this.secret = null
            this.secretDataUri = null
            this.recoveryPhrase = null

            this.inputs.code = null
            this.inputs.currentPassword = null

            this.cancelScannedOrCopied()
            this.cancelRecoveryPhraseBackedUp()
            this.isSuccessful = false
        },

        confirmScannedOrCopied() {
            this.isScannedOrCopied = true
        },

        cancelScannedOrCopied() {
            this.isScannedOrCopied = false
        },

        confirmRecoveryPhraseBackedUp() {
            this.isRecoveryPhraseBackedUp = true
        },

        cancelRecoveryPhraseBackedUp() {
            this.isRecoveryPhraseBackedUp = false
        },
    }
})

export default useTwoFactorAuthFormStore
