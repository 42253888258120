<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg p-4 flex flex-col min-w-0" :class="isComparing ? 'h-80' : 'h-72'">
        <div class="flex justify-between pb-2">
            <h1 class="text-lg font-medium text-gray-700">Keywords</h1>
            <ui-data-quality-icon quality="processed"></ui-data-quality-icon>
        </div>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div v-for="series in series" :key="series.id" class="flex-1 min-w-0 flex flex-col relative">
                <div v-if="isComparing" class="mb-3">
                    <span class="rounded px-1.5 py-1 font-medium" :style="{ 'background-color': `${series.color}20`, 'color': series.color }">{{series.name}}</span>
                </div>

                <keywords :store="series.analysisStore()" ref="analysis" class="w-full min-h-0"></keywords>

                <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20': isInitialized, 'bg-white': ! isInitialized}" v-if="series.analysisStore().isLoading">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Keywords from '@/components/analysis/analyses/keywords/keywords'

import { mapLocalState } from '@/helpers'

export default {
    components: {
        Keywords
    },

    computed: {
        ...mapLocalState([ 'isComparing', 'isInitialized', 'series' ])
    }
}
</script>