<template>
    <router-link :to="{ name: 'features.feature.information', params: { type: feature.type, id: feature.id } }">
        <div class="rounded-sm bg-white shadow hover:shadow-lg cursor-pointer">
            <div class="flex items-center relative p-4 pb-3">
                <div class="shrink-0 relative">
                    <ui-avatar :item="feature" class="w-9 h-9"></ui-avatar>
                    <ui-icon :name="`badge-feature-${feature.type}`" class="absolute -right-px -bottom-px text-lg" style="filter: drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(1px 0 0 white);"></ui-icon>
                </div>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        <span>{{ feature.title }}</span>
                    </h1>
                    <div class="text-gray-800 text-xs leading-tight flex items-center mt-0.5">
                        <div>{{featureTypeLabel(feature.type)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'features-card',

    props: [ 'feature', 'streamStore' ],

    computed: {
        isSelected() {
            return this.streamStore.selectedItems.includes(this.feature)
        }
    },

    methods: {
        featureTypeLabel(type) {
            return {
                'domain': 'Domain',
                'email': 'Email Address',
                'facebook-media': 'Facebook Media',
                'hashtag': 'Hashtag',
                'iban': 'IBAN',
                'image': 'Image',
                'video': 'Video',
                'ip-address': 'IP Address',
                'location': 'Location',
                'name': 'Person Name',
                'phone-number': 'Phone Number',
                'url': 'URL'
            }[type]
        }
    }
}
</script>
