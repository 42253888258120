export default function registerComponents(app) {
    app.component('debug-modal', require('./components/ui/debug-modal.vue').default)

    app.component('delete-confirmation-modal', require('./components/modals/delete-confirmation.vue').default)
    app.component('workspace-switch-confirmation-modal', require('./components/modals/workspace-switch-confirmation.vue').default)
    app.component('help-overlay', require('./components/ui/help-overlay.vue').default)
    app.component('notifications-overlay', require('./components/me/notifications/overlay.vue').default)
    app.component('notifications-toaster', require('./components/me/notifications/toaster.vue').default)
    app.component('quick-search-overlay', require('./components/quick-search/overlay.vue').default)
    app.component('support-chat-overlay', require('./components/support/chat/overlay.vue').default)

    app.component('ui-access-icon', require('./components/ui/access-icon.vue').default)
    app.component('ui-access-input', require('./components/ui/access-input.vue').default)
    app.component('ui-async', require('./components/ui/async.vue').default)
    app.component('ui-avatar', require('./components/ui/avatar.vue').default)
    app.component('ui-avatar-input', require('./components/ui/avatar-input.vue').default)
    app.component('ui-avatar-upload', require('./components/ui/avatar-upload.vue').default)
    app.component('ui-button', require('./components/ui/button.vue').default)
    app.component('ui-cards', require('./components/ui/cards/cards.vue').default)
    app.component('ui-cards-toolbar', require('./components/ui/cards/toolbar/results.vue').default)
    app.component('ui-analysis', require('./components/ui/analysis.vue').default)
    app.component('ui-contextual-menu', require('./components/ui/contextual-menu.vue').default)
    app.component('ui-data-quality-icon', require('./components/ui/data-quality-icon.vue').default)
    app.component('ui-date-picker', require('./components/ui/date-picker.vue').default)
    app.component('ui-dropdown', require('./components/ui/dropdown.vue').default)
    app.component('ui-dropdown-link', require('./components/ui/dropdown-link.vue').default)
    app.component('ui-dropdown-separator', require('./components/ui/dropdown-separator.vue').default)
    app.component('ui-editor', require('./components/ui/editor.vue').default)
    app.component('ui-form', require('./components/ui/form.vue').default)
    app.component('ui-header', require('./components/ui/header.vue').default)
    app.component('ui-help-button', require('./components/ui/help-button.vue').default)
    app.component('ui-icon', require('./components/ui/icon.vue').default)
    app.component('ui-infinite-loading', require('./components/ui/infinite-loading.vue').default)
    app.component('ui-input', require('./components/ui/input.vue').default)
    app.component('ui-modal', require('./components/ui/modal.vue').default)
    app.component('ui-progress', require('./components/ui/progress.vue').default)
    app.component('ui-select', require('./components/ui/select.vue').default)
    app.component('ui-spark-analysis', require('./components/ui/spark-analysis.vue').default)
    app.component('ui-spinner', require('./components/ui/spinner.vue').default)
    app.component('ui-stream', require('./components/ui/stream/stream.vue').default)
    app.component('ui-switch', require('./components/ui/switch.vue').default)
    app.component('ui-table', require('./components/ui/table.vue').default)
    app.component('ui-target-link', require('./components/ui/links/target.vue').default)

    app.component('navigation-section', require('./components/ui/navigation-section.vue').default)
}
