<template>
    <router-view></router-view>

    <Teleport to="#root">
        <note-modal></note-modal>
        <targets-library-add-target-modal></targets-library-add-target-modal>
        <targets-list-duplicate-modal></targets-list-duplicate-modal>
        <targets-list-edit-modal></targets-list-edit-modal>
        <content-hash-modal></content-hash-modal>
    </Teleport>
</template>

<script>
import NoteModal from '@/components/me/notes/modal'
import TargetsLibraryAddTargetModal from './modals/library-add-target'
import TargetsListDuplicateModal from './modals/list-duplicate'
import TargetsListEditModal from './modals/list-edit'
import ContentHashModal from '@/components/content/modals/content-hash'

export default {
    components: {
        NoteModal, TargetsLibraryAddTargetModal, TargetsListDuplicateModal, TargetsListEditModal, ContentHashModal
    }
}
</script>
