<template>
    <div class="relative flex-1 h-9" v-click-away="blur">
        <div class="absolute -inset-x-4 -top-4 z-40 pt-4 px-4" :class="{ 'bg-white ring-2 ring-gray-100 rounded-sm shadow-lg': isFocused }">
            <div>
                <div class="absolute top-4 left-8 h-9 flex items-center pointer-events-none text-xl z-40">
                    <ui-icon name="search" :class="{ 'text-gray-600': ! isFocused, 'text-blue-600': isFocused }"></ui-icon>
                </div>

                <search-input
                    v-model="store.searchQuery"
                    :language="store.supportsSearchLanguage ? store.searchLanguage : null"
                    @focus="focus"
                    @blur="blur"
                    @transaction="transaction"
                    @submit="submit"
                    :allow-multi-line="store.multiLineSearchInput"
                    :allow-highlighting="store.table === 'content'"
                    :allow-suggesting-topics="store.table === 'content'"
                    :allow-creating-topics="store.table === 'content'"
                    :allow-editing-topics="store.table === 'content'"
                    :allow-unwrapping-topics="store.table === 'content'"
                ></search-input>

                <div class="absolute top-4 right-8 h-9 flex items-center space-x-2 z-40">
                    <div class="flex items-center justify-center space-x-2 px-2 text-xl" v-if="store.supportsSearchLanguage">
                        <div class="py-1 px-1.5 border text-2xs rounded leading-none uppercase border-gray-400 text-gray-500 cursor-default">
                            {{ store.searchLanguage }}
                        </div>

                        <div class="py-1 px-1.5 border text-2xs rounded leading-none uppercase border-gray-400 text-gray-500" v-tooltip="'Searching in Images Text'" v-if="isSearchingIn('images')">
                            <ui-icon name="image"></ui-icon>
                        </div>

                        <div class="py-1 px-1.5 border text-2xs rounded leading-none uppercase border-gray-400 text-gray-500" v-tooltip="'Searching in Video Transcription'" v-if="isSearchingIn('videos')">
                            <ui-icon name="video"></ui-icon>
                        </div>
                    </div>

                    <a href="#" class="flex items-center justify-center focus:outline-none transition w-6 h-6 text-base rounded-full bg-gray-300 hover:bg-gray-400 text-gray-700 hover:text-gray-900" @click.prevent="clearSearch" v-tooltip="'Clear Search Text'">
                        <ui-icon name="x"></ui-icon>
                    </a>

                    <search-settings :store="store" v-if="store.supportsSearchSettings"></search-settings>

                    <search-help></search-help>
                </div>
            </div>

            <div class="max-h-72 overflow-y-auto text-gray-900 text-sm border-t border-gray-200 pt-3 pb-1.5" v-show="isFocused">
                <div v-if="foundSuggestions.length">
                    <div class="flex items-center mb-2">
                        <h3 class="text-xs leading-4 font-bold uppercase tracking-tight text-gray-700">Suggested keywords</h3>
                        <ui-spinner v-if="isLoadingSuggestions" type="clip" class="ml-1.5"></ui-spinner>
                    </div>
                    <div class="flex flex-wrap -ml-1">
                        <a href="#" @click.prevent="applySuggestion(suggestion)" :key="index" v-for="suggestion, index in suggestions" class="px-2 py-1 text-sm leading-4 bg-gray-100 text-gray-900 hover:bg-gray-700 hover:text-gray-50 rounded-sm max-w-16 m-1">
                            {{ suggestion.word }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchHelp from './search/help'
import SearchInput from './search/input'
import SearchSettings from './search/settings'

import useSuggestionsStore from '@/stores/reusable/suggestions'
import useSupportHelpStore from '@/stores/support/help'
import useModalsSearchHistoryStore from '@/stores/modals/search-history'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        SearchHelp,
        SearchInput,
        SearchSettings
    },

    data: () => ({
        isFocused: false
    }),

    computed: {
        ...mapState(useSuggestionsStore, [ 'suggestions', 'isLoadingSuggestions' ]),

        foundSuggestions() {
            return this.suggestions && this.suggestions.length ? this.suggestions.filter(s => s.distance >= 0.6) : []
        }
    },

    methods: {
        ...mapActions(useSuggestionsStore, [ 'suggest' ]),
        ...mapActions(useModalsSearchHistoryStore, { openSearchHistoryModal: 'open' }),
        ...mapActions(useSupportHelpStore, { showHelp: 'show' }),

        async focus() {
            this.isFocused = true

            await this.$nextTick()
        },

        blur() {
            this.isFocused = false
        },

        transaction({ editor, transaction }) {
            if (transaction.docChanged) {
                this.$nextTick(() => {
                    let text = editor.getText('\n').replaceAll(/\/topic\/(\d+)/ig, ' ')

                    let left = text.substring(0, transaction.selection.$anchor.pos).match(/[\p{L}\d]+$/u)?.[0] || ''
                    let right = text.substring(transaction.selection.$anchor.pos).match(/^[\p{L}\d]+/u)?.[0] || ''

                    if ((left.length + right.length) < 3) return

                    this.suggestForWord(left + right)
                })
            }
        },

        suggestForWord(word) {
            return this.suggest(word, this.store.searchLanguage)
        },

        clearSearch() {
            this.store.removeSearch()
        },

        submit() {
            this.store.applySearchQuery()
        },

        isSearchingIn(type) {
            return this.store.searchTypes.includes(type)
        },

        searchIn(type) {
            this.store.toggleSearchType(type)
        }
    }
}
</script>
