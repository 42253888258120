<template>
    <div class="relative">
        <div :key="index" v-for="group, index in itemGroups" v-if="store.items.data && store.items.data.length">
            <div class="py-6 text-center text-xs text-gray-600 flex items-center justify-center space-x-2" v-if="index > 0">
                <div class="border-b border-gray-300 w-16"></div>
                <div>
                    <ui-icon :name="store.sorting.icon"></ui-icon>
                    {{ store.sorting.type == 'date' ? $dateTime(store.sorting.value(group[0]).value) : $number(store.sorting.value(group[0]).value) }}
                </div>
                <div class="border-b border-gray-300 w-16"></div>
            </div>

            <div :class="containerClass" :style="containerStyle">
                <template v-for="item in group">
                    <slot name="item" :item="item" :layout="store.layout"></slot>
                </template>
            </div>
        </div>

        <div v-else-if="store.items.isFetching">
            <div :class="containerClass" :style="containerStyle">
                <template v-for="v, i in Array.from({ length: 10 })">
                    <slot name="placeholder" :index="i" :layout="store.layout">
                        <stream-pending-placeholder data-id="-1" :large="!! (i % 2)"></stream-pending-placeholder>
                    </slot>
                </template>
            </div>
        </div>

        <div class="absolute w-[calc(100%+3rem)] h-[calc(100%+3rem)] -top-6 -left-6 z-10 bg-gray-75 bg-opacity-50 backdrop-blur-sm rounded-md" v-if="store.items.isFetchingFirst">
            <div class="h-screen w-full absolute -top-28 left-0 flex items-center justify-center">
                <div class="bg-gray-900 bg-opacity-75 text-white font-medium px-6 py-4 rounded-lg shadow-sm flex items-center">
                    <ui-spinner type="clip" color="white" class="mr-2"></ui-spinner>
                    Updating results...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StreamPendingPlaceholder from '../cards/pending-placeholder'

import debounce from 'just-debounce-it'
import split from 'just-split'

export default {
    components: { StreamPendingPlaceholder },

    props: [ 'layoutWidth' ],

    computed: {
        itemGroups() {
            return this.store.items.data ? split(this.store.items.data, this.store.perPage) : []
        },

        containerClass() {
            let gridCols = 'grid-cols-1'
            if (this.layoutWidth >= 1800) gridCols = 'grid-cols-4'
            else if (this.layoutWidth >= 1300) gridCols = 'grid-cols-3'
            else if (this.layoutWidth >= 800) gridCols = 'grid-cols-2'

            return `content-group grid ${gridCols} gap-4 mx-auto`
        },

        containerStyle() {
            return 'grid-auto-rows:8px; max-width:3120px;'
        }
    },

    methods: {
        updateLayout(items = []) {
            let updatedGroups = items.length
                ? [ ...new Set(items.map(item => this.$el.querySelector(`.content-card[data-id="${item.id}"]`)?.closest('.content-group'))) ]
                : this.$el.querySelectorAll('.content-group')

            updatedGroups.forEach(groupEl => {
                if (! groupEl) return

                let resizeCard = el => {
                    let containerStyle = window.getComputedStyle(groupEl)
                    let cardHeight = el.querySelector('.content-card-content').getBoundingClientRect().height

                    let rowHeight = parseInt(containerStyle.getPropertyValue('grid-auto-rows'))
                    let rowGap = parseInt(containerStyle.getPropertyValue('grid-row-gap'))
                    let rowSpan = Math.ceil((cardHeight + rowGap) / (rowHeight + rowGap))

                    el.style.gridRowEnd = `span ${rowSpan}`
                }

                groupEl.querySelectorAll('.content-card').forEach(el => {
                    resizeCard(el)
                    el.querySelectorAll('img').forEach(img => img.addEventListener('load', () => resizeCard(el)))
                    el.querySelectorAll('svg').forEach(svg => svg.addEventListener('load', () => resizeCard(el)))
                })
            })
        }
    },

    watch: {
        'store.items.isFetching'() { this.$nextTick(() => this.updateLayout([{ id: '-1' }])) },
        'store.layout'() { this.$nextTick(() => this.updateLayout()) },
        'store.lastLayoutUpdate'() { this.$nextTick(() => this.updateLayout(this.store.lastLayoutUpdate.items)) }
    },

    mounted() {
        this.updateLayout()

        window.addEventListener('resize', debounce(() => {
            this.$nextTick(() => this.updateLayout())
        }, 50))
    }
}
</script>
