<template>
    <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md mb-4">
        <div class="px-6 py-4">
            <h3 class="font-semibold text-gray-900">
                History
            </h3>
        </div>

        <ui-async :guards="shownEvents" class="px-6" :class="{ 'py-6': ! compact, 'py-4': compact }">
            <div class="flow-root" v-if="shownEvents && shownEvents.length">
                <ul>
                    <li>
                        <div class="relative" :class="{ 'pb-8': ! compact || index != shownEvents.length - 1 }" :key="index" v-for="record, index in shownEvents">
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-100" aria-hidden="true" v-if="index != shownEvents.length - 1"></span>
                            <div class="relative flex space-x-3">
                                <div>
                                    <span class="h-8 w-8 rounded-full bg-blue-500 text-white text-2xl flex items-center justify-center ring-8 ring-white" :class="{ 'bg-blue-500': record.type == 'discovered' }">
                                        <ui-icon name="flag" v-if="record.type == 'created'"></ui-icon>
                                        <ui-icon name="eye" v-else-if="record.type == 'discovered'"></ui-icon>
                                        <ui-icon name="person" v-else-if="record.type == 'name-updated'"></ui-icon>
                                        <ui-icon name="email" v-else-if="record.type == 'email-updated'"></ui-icon>
                                        <template v-else-if="record.type == 'phone-number-updated'">#</template>
                                        <ui-icon name="globe" v-else-if="record.type == 'website-updated'"></ui-icon>
                                        <ui-icon name="check" v-else-if="record.type == 'verified' || record.type == 'unverified'"></ui-icon>
                                        <ui-icon name="trending-up" v-else-if="record.type == 'fan-count-reached'"></ui-icon>
                                        <ui-icon name="trending-down" v-else-if="record.type == 'fan-count-fallen'"></ui-icon>
                                        <ui-icon name="info" v-else></ui-icon>
                                    </span>
                                </div>
                                <div class="min-w-0 flex-1 flex items-center justify-between space-x-4">
                                    <div class="text-sm leading-tight">
                                        <div class="text-2xs whitespace-nowrap text-gray-800" v-if="compact">
                                            <time>{{ $dateTime(record.recordedAt) }}</time>
                                        </div>
                                        <p v-if="record.type == 'created'">
                                            Target <span class="font-semibold">created</span>.
                                        </p>
                                        <p v-else-if="record.type == 'discovered'">
                                            Target <span class="font-semibold">first discovered</span>.
                                        </p>
                                        <p v-else-if="record.type == 'updated:name'">
                                            <template v-if="record.change[0]">
                                                Changed name from <span class="font-semibold">{{ record.change[0] }}</span> to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                            </template>
                                            <template v-else>
                                                Changed name to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                            </template>
                                        </p>
                                        <p v-else-if="record.type == 'updated:email'">
                                            <template v-if="record.change[0]">
                                                Changed e-mail address from <span class="font-semibold">{{ record.change[0].email }}</span> to <span class="font-semibold">{{ record.change[1] ? record.change[1].email : 'none' }}</span>
                                            </template>
                                            <template v-else>
                                                Changed e-mail address to <span class="font-semibold">{{ record.change[1] ? record.change[1].email : 'none' }}</span>
                                            </template>
                                        </p>
                                        <p v-else-if="record.type == 'updated:phoneNumber'">
                                            <template v-if="record.change[0]">
                                                Changed phone number from <span class="font-semibold">{{ record.change[0].canonical_phone_number }}</span> to <span class="font-semibold">{{ record.change[1] ? record.change[1].canonical_phone_number : 'none' }}</span>
                                            </template>
                                            <template v-else>
                                                Changed phone number to <span class="font-semibold">{{ record.change[1] ? record.change[1].canonical_phone_number : 'none' }}</span>
                                            </template>
                                        </p>
                                        <p v-else-if="record.type == 'updated:website'">
                                            <template v-if="record.change[0]">
                                                Changed website from <span class="font-semibold">{{ record.change[0] }}</span> to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                            </template>
                                            <template v-else>
                                                Changed website to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                            </template>
                                        </p>
                                        <p v-else-if="record.type == 'verified'">
                                            Facebook page was <span class="font-semibold">verified</span>.
                                        </p>
                                        <p v-else-if="record.type == 'unverified'">
                                            Facebook page was <span class="font-semibold">unverified</span>.
                                        </p>
                                        <p v-else-if="record.type == 'fan-count-reached'">
                                            Facebook page <span class="font-semibold">reached {{ record.threshold }} fans</span>.
                                        </p>
                                        <p v-else-if="record.type == 'fan-count-fallen'">
                                            Facebook page <span class="font-semibold">dropped below {{ record.threshold }} fans</span>.
                                        </p>
                                        <p v-else-if="record.type == 'subscribers-count-reached'">
                                            Subscriber count <span class="font-semibold">reached {{ record.threshold }} subscribers</span>.
                                        </p>
                                        <p v-else-if="record.type == 'subscribers-count-fallen'">
                                            Subscriber count <span class="font-semibold">dropped below {{ record.threshold }} subscribers</span>.
                                        </p>
                                        <p v-else-if="record.type == 'members-count-reached'">
                                            Members count <span class="font-semibold">reached {{ record.threshold }} members</span>.
                                        </p>
                                        <p v-else-if="record.type == 'members-count-fallen'">
                                            Members count <span class="font-semibold">dropped below {{ record.threshold }} members</span>.
                                        </p>
                                        <p v-else-if="record.type == 'updated:username'">
                                            <template v-if="record.change[0]">
                                                Changed username from <span class="font-semibold">{{ record.change[0] }}</span> to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                            </template>
                                            <template v-else>
                                                Changed username to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                            </template>
                                        </p>
                                        <p v-else-if="record.type == 'updated:description'">
                                            Description changed to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                        </p>
                                        <p v-else-if="record.type == 'updated:contactAddress'">
                                            Contact address changed to <span class="font-semibold">{{ record.change[1] || 'none' }}</span>
                                        </p>
                                    </div>
                                    <div class="text-right text-xs whitespace-nowrap text-gray-800 pt-1" v-if="! compact">
                                        <time>{{ $dateTime(record.recordedAt) }}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="text-center text-sm mt-8" v-if="showShowMoreLink">
                <router-link :to="{ name: 'targets.target.history', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="text-blue-500 hover:text-blue-700">
                    Show More
                </router-link>
            </div>

            <div class="py-12 text-gray-600 text-center text-sm" v-if="! shownEvents || ! shownEvents.length">
                <p>We have found no history for this Target.</p>
            </div>
        </ui-async>
    </div>
</template>

<script>
import useTargetsTargetHistoryStore from '@/stores/targets/target/history'

import { mapState } from 'pinia'

export default {
    props: [ 'compact' ],

    computed: {
        ...mapState(useTargetsTargetHistoryStore, [ 'events' ]),

        shownEvents() {
            if (this.events) {
                return this.compact ? [ ...this.events ].reverse().slice(0, 3) : this.events
            }

            return null
        },

        showShowMoreLink() {
            return this.compact && this.events?.length > 3
        }
    }
}
</script>
