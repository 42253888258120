<template>
    <div class="bg-white border border-gray-200 bg-opacity-30 w-full overflow-hidden shadow-sm rounded-md px-6" v-if="$page.target.monitored || $page.target.metrics.contentCount">
        <div class="flex justify-between items-center py-4">
            <h1 class="font-semibold">
                Top Content
            </h1>

            <div class="flex items-center space-x-3">
                <ui-dropdown align="right" width="w-48">
                    <template v-slot:trigger>
                        <button class="flex items-center bg-gray-200 hover:bg-gray-400 text-gray-700 hover:text-gray-900 text-sm focus:outline-none focus:text-gray-900 transition px-3 py-1 rounded-full">
                            {{selectedTimeRange.name}}
                            <ui-icon name="chevron-down" class="ml-1"></ui-icon>
                        </button>
                    </template>

                    <template v-slot:content>
                        <ui-dropdown-link @click="$page.selectTopContentRange(range.id)" :key="range.id" v-for="range in timeRanges">
                            {{range.name}}
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown>

                <ui-help-button background="darker" v-tooltip="'Content with most interactions in the selected time frame.'"></ui-help-button>
            </div>
        </div>

        <ui-stream :store="targetsTargetInformationTopContentStreamStore"></ui-stream>
    </div>
</template>

<script>
import useTargetsTargetInformationTopContentStreamStore from '@/stores/targets/target/information-top-content-stream'

import { mapStores } from 'pinia'

export default {
    data: () => ({
        timeRanges: [
            { id: 'day', name: 'Past Day' },
            { id: 'week', name: 'Past Week' },
            { id: 'month', name: 'Past Month' }
        ]
    }),

    computed: {
        ...mapStores(useTargetsTargetInformationTopContentStreamStore),

        selectedTimeRange() {
            return this.timeRanges.find(r => r.id == this.$page.topContentRange)
        }
    }
}
</script>
