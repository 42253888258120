import { asyncResource } from '@/api'
import { sortAlphabetically, useRoute, useRouter } from '@/helpers'
import { trackEvent as _trackEvent } from '@/analytics'

import perspectivesConcern from './stream/perspectives-concern'

import defineStreamMediaLightbox from './stream/media-lightbox'
import useMyStore from '@/stores/me/my'
import useMyLabelsStore from '@/stores/me/labels'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTaggingStore from '@/stores/tagging/tagging'

import filter from 'just-filter'
import mitt from 'mitt'
import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const defineStreamStore = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            table: settings.table,

            resultsToolbar: settings.resultsToolbar ?? true,
            compactToolbar: settings.compactToolbar === true,
            multiLineSearchInput: settings.multiLineSearchInput === true,
            showsResultsBar: settings.showsResultsBar !== false,
            supportsPerspectives: settings.supportsPerspectives !== false,
            supportsSearchLanguage: settings.supportsSearchLanguage === true,
            supportsSearchSettings: settings.supportsSearchSettings === true,
            supportsSearchHistory: settings.supportsSearchHistory === true,
            exactCount: settings.exactCount !== false,
            updatesQuery: settings.updatesQuery !== false,
            itemsLimit: settings.itemsLimit || null,
            exportable: settings.exportable || false,
            importable: settings.importable || false,
            polling: settings.polling || false,
            perPage: settings.perPage || 25,
            showFilters: true,

            filters: settings.filters || [],
            analyses: settings.analyses || [],

            sortingOptions: settings.sortingOptions || [],

            layoutOptions: settings.layoutOptions || [
                { name: 'Masonry', id: 'masonry', icon: 'view-grid', available: true, minWidth: 1100 },
                { name: 'Cards', id: 'cards', icon: 'view-cards', available: true },
                { name: 'Images', id: 'images', icon: 'image', available: true, mediaFilter: media => media.family == 'images' },
                { name: 'Videos', id: 'videos', icon: 'video', available: true, mediaFilter: media => media.family == 'videos' }
            ],

            stackingOptions: settings.stackingOptions || [],

            items: asyncResource({
                request(api, store) {
                    return api.route(store.apiEndpoint)
                        .query({ ...store.apiQuery, includeTotal: store.exactCount ? 0 : 1, historize: store.supportsSearchHistory })
                },

                transform(data, store) {
                    return store.filterDuplicates(data)
                }
            }),

            itemsAdditional: asyncResource({
                request(api, store) {
                    return api.route(store.apiEndpoint)
                        .query({ ...store.apiQuery, ...this.sortingQuery(store) })
                },

                sortingQuery(store) {
                    let key = store.sorting.direction == 'desc' ? 'before' : 'after'

                    return { [key]: store.sorting.value(store.lastItem, store) }
                },

                transform(data, store) {
                    return store.filterDuplicates(data)
                }
            }),

            itemsCountTotal: asyncResource({
                collection: false,

                request(api, store) {
                    return api.route(store.apiEndpoint)
                        .query({ ...store.apiQuery, count: true })
                }
            }),

            itemsNewCount: asyncResource({
                collection: false,

                request(api, store) {
                    return api.route(store.apiEndpoint)
                        .query({ ...store.apiQuery, ...this.sortingQuery(store), count: true })
                },

                sortingQuery(store) {
                    let key = store.sorting.direction == 'desc' ? 'after' : 'before'

                    return { [key]: store.sorting.value(store.firstItem, store) }
                }
            }),

            itemsNew: asyncResource({
                request(api, store) {
                    return api.route(store.apiEndpoint)
                        .query({ ...store.apiQuery, ...this.sortingQuery(store) })
                },

                sortingQuery(store) {
                    let key = store.sorting.direction == 'desc' ? 'after' : 'before'

                    return { [key]: store.sorting.value(store.firstItem, store) }
                },

                transform(data, store) {
                    return store.filterDuplicates(data)
                }
            }),

            appliedFilters: [],

            ...perspectivesConcern.state,

            searchQuery: null,
            searchLanguage: null,
            searchTypes: [ 'images', 'videos' ],
            searchContext: settings.searchContext,

            selectedSortingOption: null,
            selectedLayoutOption: null,
            selectedStackingOption: null,

            isInSelectMode: false,
            selectedItems: [],

            expandedStacks: [],

            pollTimeout: null,

            lastReloadTime: +new Date(),
            lastLayoutUpdate: { time: +new Date(), items: [] },

            mediaLightbox: defineStreamMediaLightbox({
                id: `${settings.id}MediaLightbox`,
                name: `stream-content-lightbox-${settings.id}`
            })(),

            filterTypes: {
                'date': {
                    apiSerialize: value => value.type == 'past'
                        ? value
                        : { type: value.type, date: { gte: value.date.gte instanceof Date ? value.date.gte.toISOString() : '', lte: value.date.lte instanceof Date ? value.date.lte.toISOString() : '' } },
                    apiUnserialize: value => value.type == 'past'
                        ? value
                        : { type: value.type, date: { gte: value.date.gte ? new Date(value.date.gte) : null, lte: value.date.lte ? new Date(value.date.lte) : null } },
                    uriSerialize: value => value.type == 'past'
                        ? `${value.type}:${value.date.past}|${value.date.unit}`
                        : `${value.type}:${value.date.gte instanceof Date ? value.date.gte.toISOString() : ''}|${value.date.lte instanceof Date ? value.date.lte.toISOString() : ''}`,
                    uriUnserialize: value => {
                        let [ type, ...date ] = value.split(':')
                        date = date.join(':').split('|')
                        return type == 'past'
                            ? { type, date: { past: date[0], unit: date[1] } }
                            : { type, date: { gte: date[0] ? new Date(date[0]) : null, lte: date[1] ? new Date(date[1]) : null } }
                    },
                    multipleValues: () => false
                },
                'labels': {
                    serialize: value => value.id,
                    unserialize: value => useMyLabelsStore().find(value),
                    multipleValues: () => true
                },
                'sentiments': {
                    serialize: value => value.id,
                    unserialize: (value, filter) => filter.sentiments.find(o => o.id == value),
                    multipleValues: () => true
                },
                'media': {
                    uriSerialize: value => `${value.type}:${value.id}`,
                    uriUnserialize: value => {
                        let [ type, id ] = value.split(':')
                        return { type, id }
                    },
                    multipleValues: () => false
                },
                'mentions': {
                    apiSerialize: value => ({ type: value.type.id, id: value.id, name: value.name, exclude: value.exclude }),
                    apiUnserialize: (value, filter, store) => ({
                        type: store.mentionFilterTypes.find(t => t.id == value.type), id: value.id, name: value.name, exclude: value.exclude
                    }),
                    uriSerialize: value => `${value.exclude ? '-' : ''}${value.type.id}|${value.id}|${value.name}`,
                    uriUnserialize: (value, filter, store) => {
                        let [ type, id, name ] = value.split('|')
                        let exclude = false

                        if (type[0] == '-') {
                            type = type.substring(1)
                            exclude = true
                        }

                        return { type: store.mentionFilterTypes.find(t => t.id == type), id, name, exclude }
                    },
                    multipleValues: () => true
                },
                'metrics': {
                    apiSerialize: value => ({ metric: value.metric.id, gte: value.gte, lte: value.lte }),
                    apiUnserialize: (value, filter) => ({
                        metric: filter.metrics.find(m => m.id == value.metric), gte: value.gte, lte: value.lte
                    }),
                    uriSerialize: value => `${value.metric.id}:${value.gte}-${value.lte}`,
                    uriUnserialize: (value, filter) => {
                        let [ metric, range ] = value.split(':')
                        return { metric: filter.metrics.find(m => m.id == metric), gte: range.split('-')[0], lte: range.split('-')[1] }
                    },
                    multipleValues: () => true,
                    multupleValuesKey: value => value.metric
                },
                'deleted': {
                    serialize: value => value,
                    unserialize: value => value,
                    multipleValues: () => false
                },
                'notification': {
                    uriSerialize: value => `${value.id}|${value.name}|${value.sentAt.toISOString()}`,
                    uriUnserialize: value => {
                        let [ id, name, sentAt ] = value.split('|')
                        return { id, name, sentAt: new Date(sentAt) }
                    },
                    multipleValues: () => false
                },
                'options': {
                    serialize: value => value.id,
                    unserialize: (value, filter) => filter.options.find(o => o.id == value),
                    multipleValues: filter => filter.multiple
                },
                'raw': {
                    uriSerialize: value => value,
                    uriUnserialize: value => value,
                    multipleValues: () => false
                },
                'search-query': {
                    uriSerialize: value => `${value.query}|${value.language}|${value.types ? value.types.join(',') : ''}`,
                    uriUnserialize: value => {
                        let tokens = value.split('|')
                        return {
                            query: tokens.slice(0, -2).join('|'),
                            language: tokens[tokens.length - 2],
                            types: tokens[tokens.length - 1].split(',')
                        }
                    },
                    multipleValues: () => false
                },
                'semantic-query': {
                    uriSerialize: value => `${value.query}|${value.sensitivity}`,
                    uriUnserialize: value => {
                        let tokens = value.split('|')
                        return { query: tokens.slice(0, -1).join('|'), sensitivity: tokens[tokens.length - 1] }
                    },
                    multipleValues: () => false
                },
                'target-list': {
                    serialize: value => value.id,
                    unserialize: value => useMyTargetListsStore().find(value) || useMyCuratedListsStore().findList(value),
                    multipleValues: () => false
                },
                'targets': {
                    apiSerialize: value => ({ type: value.type, id: value.id, name: value.name, exclude: value.exclude }),
                    apiUnserialize: value => ({ type: value.type, id: value.id, name: value.name, exclude: value.exclude }),
                    uriSerialize: value => `${value.exclude ? '-' : ''}${value.type}|${value.id}|${value.name}`,
                    uriUnserialize: value => {
                        let [ type, id, name ] = value.split('|')
                        let exclude = false

                        if (type[0] == '-') {
                            type = type.substring(1)
                            exclude = true
                        }

                        return { type, id, name, exclude }
                    },
                    multipleValues: () => true
                },
                'tags': {
                    apiSerialize: value => ({ mode: value.mode, tags: value.tags.map(t => t.id) }),
                    apiUnserialize: value => ({ mode: value.mode, tags: value.tags.map(t => useTaggingStore().find(t)) }),
                    uriSerialize: value => value.mode + '|' + value.tags.map(t => t.id).join(','),
                    uriUnserialize: value => {
                        let tokens = value.split('|')
                        return { mode: tokens[0], tags: tokens[1].split(',').map(t => useTaggingStore().find(t)) }
                    },
                    multipleValues: () => false
                },
                'replies': {
                    uriSerialize: value => value,
                    uriUnserialize: value => value === 'true' ?? false,
                    multipleValues: () => false
                },
            },

            mentionFilterTypes: [
                { name: 'Email Address', id: 'email', icon: 'badge-feature-email', types: [ 'email' ] },
                { name: 'Location', id: 'location', icon: 'badge-feature-location', types: [ 'location' ] },
                { name: 'Phone Number', id: 'phone-number', icon: 'badge-feature-phone-number', types: [ 'phone-number' ] },
                { name: 'Bank Account', id: 'iban', icon: 'badge-feature-iban', types: [ 'iban' ] },
                { name: 'Domain Name', id: 'domain', icon: 'badge-feature-domain', types: [ 'domain' ] },
                { name: 'IP Address', id: 'ip-address', icon: 'badge-feature-ip-address', types: [ 'ip-address' ] },
                { name: 'Hashtag', id: 'hashtag', icon: 'badge-feature-hashtag', types: [ 'hashtag' ] },
                { name: 'Person Name', id: 'name', icon: 'badge-feature-person-name', types: [ 'name' ] },
                { name: 'Url', id: 'url', icon: 'badge-feature-url', types: [ 'url' ] },
                { name: 'Image Cluster', id: 'image-cluster', icon: 'badge-feature-image', types: [ 'image-cluster' ], hidden: true },
                { name: 'Video Cluster', id: 'video-cluster', icon: 'badge-feature-video', types: [ 'video-cluster' ], hidden: true }
            ],

            filterProxies: markRaw({}),

            apiInclude: settings.apiInclude || [],
            apiQueryExtraParameters: settings.apiQueryExtraParameters || {},

            statistics: settings.statistics || null,
            shouldShowQuickInspect: false,

            initialized: false,

            events: mitt(),

            pauseTriggeringUpdates: false,

            ...(settings.state ? settings.state() : [])
        }),

        getters: {
            availableSortingOptions(store) {
                return store.sortingOptions.filter(option => {
                    return option.available === undefined || option.available === true || option.available(store)
                })
            },

            sorting(store) {
                let option = store.availableSortingOptions.find(o => o.id && o.id == store.selectedSortingOption)

                if (! option) option = store.availableSortingOptions.find(o => o.default)

                return option
            },

            layout(store) {
                let layout = store.layoutOptions.find(o => o.id == store.selectedLayoutOption)

                return layout && layout.available ? layout : store.layoutOptions.find(o => o.available)
            },

            stacking(store) {
                return store.stackingOptions.find(o => o.id == store.selectedStackingOption) ?? null
            },

            firstItem(store) {
                if (store.itemsNew.data && store.itemsNew.data.length) return store.itemsNew.data[0]
                if (store.items.data && store.items.data.length) return store.items.data[0]
            },

            lastItem(store) {
                if (store.items.data) return store.items.data[store.items.data.length - 1]
            },

            hasVisibleFilters(store) {
                return store.filters.some(f => ! f.hidden)
            },

            apiEndpoint(store) {
                return `monitor ${store.table}`
            },

            exportEndpoint(store) {
                return `monitor ${store.table} export`
            },

            apiQuery(store) {
                return {
                    perspective: store.appliedPerspective?.id,
                    filters: store.getContentQueryFilters(),
                    sorting: `${store.sorting.apiId || store.sorting.id}-${store.sorting.direction || 'asc'}`,
                    limit: store.perPage,
                    include: store.apiInclude.join(','),
                    ...store.apiQueryExtraParameters
                }
            },

            isShowingQuickInspect() {
                return this.isQuickInspectAvailable
                    && this.table == 'content'
                    && this.shouldShowQuickInspect
            },

            isQuickInspectAvailable() {
                return this.statistics !== null && this.appliedFilters.length
            },

            isBulkTaggingAvailable() {
                return this.itemsCountTotal.data && this.itemsCountTotal.data < 250
            },

            ...perspectivesConcern.getters
        },

        actions: {
            async initialize() {
                if (this.initialized) return

                if (this.supportsSearchLanguage) {
                    this.searchLanguage = useMyStore().preferredLanguage
                }

                this.initialized = true
            },

            async load() {
                this.clear()

                this.items.fetch(this).then(() => {
                    this.pollNewItems()

                    this.lastReloadTime = +new Date()

                    if (this.items.data) {
                        if (! this.exactCount) this.itemsCountTotal.data = this.items.res.total

                        this.items.data = this.stack(this.items.data)

                        this.events.emit('load', this.items.data)

                        this.triggerLayoutUpdate(this.items.data)
                    }
                })

                if (! this.appliedFilters.length) return

                if (this.exactCount) this.itemsCountTotal.fetch(this)
                if (this.isShowingQuickInspect) this.loadStatistics()
            },

            loadStatistics() {
                if (this.statistics?.values) {
                    Object.keys(this.statistics.values)
                        .filter(k => this.statistics.values[k].fetch)
                        .forEach(k => this.statistics.values[k].fetch(this))
                }

                if (this.statistics?.analyses) {
                    Object.keys(this.statistics.analyses).forEach(k => {
                        this.statistics.analyses[k].load(this.statistics.analyses[k].store(), this.getContentQueryFilters())
                    })
                }
            },

            clear() {
                clearTimeout(this.pollTimeout)

                if (this.statistics?.values) {
                    Object.keys(this.statistics.values).forEach(k => this.statistics.values[k].reset())
                }

                if (this.statistics?.analyses) {
                    Object.keys(this.statistics.analyses).forEach(k => {
                        this.statistics.analyses[k].reset && this.statistics.analyses[k].reset(this.statistics.analyses[k].store())
                    })
                }

                this.items.reset()
                this.itemsAdditional.reset()
                this.itemsCountTotal.reset()
                this.itemsNew.reset()
                this.itemsNewCount.reset()

                this.selectedItems = []
            },

            reset() {
                this.clear()

                this.appliedFilters = []
                this.appliedPerspective = null
                this.searchQuery = ''
            },

            abort() {
                clearTimeout(this.pollTimeout)

                this.items.abort()
                this.itemsAdditional.abort()
                this.itemsCountTotal.abort()
                this.itemsNew.abort()
                this.itemsNewCount.abort()
            },

            async loadAdditional(infiniteScroll) {
                if (! this.lastItem) return infiniteScroll.complete()

                await this.itemsAdditional.fetch(this)

                this.items.data.push(...this.itemsAdditional.data)

                this.itemsAdditional.data.length ? infiniteScroll.loaded() : infiniteScroll.complete()

                this.items.data = this.stack(this.items.data)

                this.events.emit('load', this.items.data)

                this.triggerLayoutUpdate(this.items.data)
            },

            analyze(type, aggregator = null, color = null) {
                window.open(useRouter().resolve({
                    name: 'analysis.analysis.details',
                    query: {
                        'perspective-label': this.appliedPerspective.name,
                        'perspective-id': this.appliedPerspective.id,
                        'perspective-aggregator': aggregator,
                        'perspective-color': color
                    },
                    params: { type: type, id: 'new' }
                }).href, '_blank')
            },

            toggleQuickInspect() {
                if (! this.isQuickInspectAvailable) return

                this.shouldShowQuickInspect = ! this.shouldShowQuickInspect

                if (this.isShowingQuickInspect) this.loadStatistics()
            },

            setSorting(option, direction) {
                if (! this.sortingOptions.find(o => o.id == option)) return

                this.selectedSortingOption = option

                this.triggerQueryChange()

                this.trackEvent('stream', 'sorting-set', `${option}-${direction}`)
            },

            setLayout(layout) {
                this.selectedLayoutOption = layout

                this.trackEvent('stream', 'layout-set', layout)
            },

            updateAvailableLayouts(streamWidth) {
                this.layoutOptions.forEach(layout => {
                    layout.available = ! layout.minWidth || streamWidth >= layout.minWidth
                })
            },

            setStacking(stacking) {
                this.selectedStackingOption = stacking
                this.items.data = this.stack(this.items.data)

                this.triggerLayoutUpdate(this.items.data)

                this.trackEvent('stream', 'stacking-set', stacking)
            },

            toggleStacking(stacking) {
                if (this.selectedStackingOption === stacking) {
                    return this.setStacking(null)
                }

                this.setStacking(stacking)
            },

            pollNewItems() {
                if (! this.polling || this.sorting.id != 'latest') return

                this.pollTimeout = setTimeout(async () => {
                    if (this.firstItem) {
                        await this.itemsNewCount.fetch(this)
                    }

                    this.pollNewItems()
                }, 30000)
            },

            async showNewItems() {
                if (this.itemsNew.data && this.itemsNew.data.length) {
                    this.items.data.unshift(...this.itemsNew.data)

                    if (this.itemsLimit) this.items.data.slice(0, this.itemsLimit)

                    this.triggerLayoutUpdate(this.items.data)
                }

                this.itemsNew.reset()
                this.itemsNewCount.reset()

                this.itemsNew.fetch(this).then(() => {
                    this.triggerLayoutUpdate(this.itemsNew.data)
                })
            },

            toggleSelectMode() {
                this.isInSelectMode = ! this.isInSelectMode

                if (! this.isInSelectMode) {
                    this.selectedItems = []
                }
            },

            toggleSelection(item) {
                if (! this.isInSelectMode) return

                this.selectedItems = this.selectedItems.includes(item)
                    ? this.selectedItems.filter(i => i != item)
                    : [ ...this.selectedItems, item ]
            },

            filter(filterId) {
                if (this.filterProxies[filterId]) return this.filterProxies[filterId]

                let store = this
                let filter = this.getFilter(filterId)

                return this.filterProxies[filterId] = new Proxy({
                    get value() { return store.getFilterValue(filterId) },
                    set: val => this.addFilter(filterId, val),
                    remove: () => this.removeFilter(filterId)
                }, {
                    get(target, name) {
                        if (! [ 'value', 'set', 'remove' ].includes(name)) return filter[name]
                        return Reflect.get(...arguments)
                    },
                    set(target, name, value) {
                        if (! [ 'value', 'set', 'remove' ].includes(name)) return filter[name] = value
                    }
                })
            },

            addFilter(filterId, value) {
                let filter = this.getFilter(filterId)
                let applied = this.appliedFilters.find(a => a.filter.id == filterId)

                this.appliedFilters = this.appliedFilters.filter(a => a !== applied)

                this.appliedFilters.push({ filter, value })

                this.triggerQueryChange()

                this.trackEvent('stream', 'filter-added', filterId)
            },

            removeSearch() {
                this.searchQuery = ''

                let searchFilter = this.filters.find(f => f.search)

                if (searchFilter) {
                    this.removeFilter(searchFilter.id)
                }

                this.triggerQueryChange()
            },

            removeFilter(filterId) {
                let applied = this.appliedFilters.find(a => a.filter.id == filterId)

                if (! applied) return

                this.appliedFilters = this.appliedFilters.filter(a => a != applied)

                this.triggerQueryChange()
            },

            removeFilters(filters) {
                let applied = this.appliedFilters.filter(a => filters.some(f => f.id == a.filter.id))

                if (! applied || ! applied.length) return

                this.appliedFilters = this.appliedFilters.filter(a => ! applied.some(f => f.filter.id == a.filter.id))

                this.triggerQueryChange()
            },

            removeAllFilters() {
                if (! this.appliedFilters.length) return

                this.appliedFilters = []
                this.searchQuery = ''

                this.triggerQueryChange()
            },

            applyFiltersQuery(query) {
                Object.entries(query).forEach(([ key, value ]) => {
                    let matches = key.match(/^filter\[(.*?)\]$/)

                    if (! matches || ! value) return

                    let filter = this.filters.find(f => f.id == matches[1])

                    this.filter(filter.id).set(this.unserializeFilterFrom('uri', filter, value))
                })
            },

            getFilter(filterId) {
                return this.filters.find(f => f.id == filterId)
            },

            getFilterValue(filterId) {
                return this.appliedFilters.find(a => a.filter.id == filterId)?.value
            },

            getContentQueryFilters(includeEphemeral = true) {
                let filters = includeEphemeral ? this.appliedFilters : this.appliedFilters.filter(a => ! a.filter.ephemeral)

                filters = Object.fromEntries(sortAlphabetically(this.appliedFilters.map(a => [
                    a.filter.id, this.serializeFilterFor('api', a.filter, a.value)
                ]), 0))

                return JSON.stringify(filters)
            },

            serializeFilterFor(target, filter, value) {
                let filterType = this.filterTypes[filter.type]
                let serializeMethod = target == 'api' ? 'apiSerialize' : 'uriSerialize'

                let serializeFunction = filter[serializeMethod] || filterType?.[serializeMethod]
                    || filter['serialize'] || filterType?.['serialize']
                    || (v => v)

                let serializeValue = target == 'uri'
                    ? (v => serializeFunction(v, filter, this).toString())
                    : (v => serializeFunction(v, filter, this))
                let serializeCollection = target == 'uri'
                    ? (c => c.map(v => serializeValue(v).replace(/,/g, '')).join(','))
                    : (c => c.map(v => serializeValue(v)))

                return filterType?.multipleValues(filter) ? serializeCollection(value) : serializeValue(value, filter, this)
            },

            unserializeFilterFrom(target, filter, value) {
                let filterType = this.filterTypes[filter.type]
                let unserializeMethod = target == 'api' ? 'apiUnserialize' : 'uriUnserialize'

                let unserializeValue = filter[unserializeMethod] || filterType?.[unserializeMethod]
                    || filter['unserialize'] || filterType?.['unserialize']
                    || (v => v)
                let unserializeCollection = target == 'uri'
                    ? (c => c.split(',').map(v => unserializeValue(v, filter, this)))
                    : (c => c.map(v => unserializeValue(v, filter, this)))

                return filterType?.multipleValues(filter) ? unserializeCollection(value).filter(v => v) : unserializeValue(value, filter, this)
            },

            applySuggestion(suggestion) {
                this.searchQuery = this.searchQuery + ` OR ${suggestion}`
            },

            ...perspectivesConcern.actions,

            applySearchQuery(query) {
                if (query !== undefined) this.searchQuery = query

                let searchFilter = this.filters.find(f => f.search)

                if (this.searchQuery) {
                    this.addFilter(searchFilter.id, {
                        query: this.searchQuery,
                        language: this.searchLanguage,
                        types: this.searchTypes
                    })
                } else {
                    this.removeFilter(searchFilter.id)
                }
            },

            applySearchLanguage(language) {
                this.searchLanguage = language

                if (this.searchQuery) this.applySearchQuery()
            },

            toggleSearchType(type) {
                this.searchTypes.includes(type)
                    ? this.searchTypes = this.searchTypes.filter(t => t != type)
                    : this.searchTypes.push(type)

                if (this.searchQuery) this.applySearchQuery()
            },

            filterDuplicates(items) {
                let currentItems = this.items.data || []
                let newItems = this.itemsNew.data || []

                let loadedIds = [ ...currentItems, ...newItems ].map(i => i.id)

                return items.filter(i => ! loadedIds.includes(i.id))
            },

            stack(items) {
                items.map(i => {
                    i.stackedParent = false
                    i.stackedChild = false
                })

                if (this.stacking) {
                    let stacks = {}

                    items.map((v, i) => {
                        if (! this.expandedStacks.includes(this.stacking.value(v))) {
                            if (this.stacking.value(v) in stacks) {
                                items[stacks[this.stacking.value(v)]].stackedParent = true
                                v.stackedChild = true
                            } else {
                                stacks[this.stacking.value(v)] = i
                            }
                        }

                        return v
                    })
                }

                return items
            },

            expand(parent) {
                this.expandedStacks.push(this.stacking.value(parent))

                this.items.data.map(v => {
                    if (this.stacking.value(v) == this.stacking.value(parent)) {
                        parent.stackedParent = false
                        v.stackedChild = false

                        this.triggerLayoutUpdate(this.items.data)
                    }

                    return v
                })
            },

            navigatedTo(to, forcedFilters = {}, defaultFilters = {}) {
                this.abort()

                let originalQuery = to.query

                this.withoutTriggeringUpdates(() => {
                    this.fromQuery(to.query, forcedFilters, defaultFilters)
                })

                let queryHasChanged = JSON.stringify({ ...originalQuery, ...this.toQuery()}) != JSON.stringify(originalQuery)

                if (queryHasChanged) {
                    this.triggerQueryChange()
                } else {
                    this.load()
                }
            },

            fromQuery(query, forcedFilters = {}, defaultFilters = {}) {
                this.selectedSortingOption = null

                this.appliedFilters = []

                Object.entries(defaultFilters).forEach(([ filter, value ]) => {
                    this.filter(filter).set(value)
                })

                this.applyFiltersQuery(query)

                Object.entries(forcedFilters).forEach(([ filter, value ]) => {
                    this.filter(filter).set(value)
                    this.filter(filter).readOnly = true
                })

                this.searchQuery = this.appliedFilters.find(a => a.filter.search)?.value.query
                this.searchLanguage = this.appliedFilters.find(a => a.filter.search)?.value.language || useMyStore().preferredLanguage
                this.searchTypes = this.appliedFilters.find(a => a.filter.search)?.value.types || [ 'images', 'videos' ]

                if (query.sorting) {
                    this.setSorting(...query.sorting.split('-'))
                }

                let perspective = useMyPerspectivesStore().find(query.perspective)
                this.applyPerspective(perspective, query)
            },

            toQuery() {
                return {
                    ...(this.appliedPerspective ? { perspective: this.appliedPerspective.id.toString() } : {}),

                    ...Object.fromEntries(this.appliedFilters.map(applied => [
                        `filter[${applied.filter.id}]`, this.serializeFilterFor('uri', applied.filter, applied.value)
                    ])),

                    sorting: `${this.sorting.id}-${this.sorting.direction || 'asc'}`
                }
            },

            withoutTriggeringUpdates(callback) {
                if (this.pauseTriggeringUpdates) return callback()

                this.pauseTriggeringUpdates = true
                let ret = callback()
                this.pauseTriggeringUpdates = false

                return ret
            },

            triggerQueryChange() {
                if (this.pauseTriggeringUpdates) return
                if (! this.updatesQuery) return this.load()

                let query = filter(useRoute().query, key => {
                    return ! (key.match(/filter\[.+?\]/) || [ 'perspective', 'sorting' ].includes(key))
                })

                useRouter().replace(
                    { query: { ...query, ...this.toQuery() } },
                    null,
                    error => { if (error.name != 'NavigationDuplicated') throw error }
                )
            },

            triggerLayoutUpdate(items = []) {
                this.lastLayoutUpdate = { time: +new Date(), items: [ ...items ] }
            },

            trackEvent(category, action, name = null, value = null) {
                if (! this.pauseTriggeringUpdates) {
                    _trackEvent(category, action, name, value)
                }
            },

            ...settings.actions
        }
    })
}

import contentStreamSettings from './stream/content-stream-settings'
import featuresStreamSettings from './stream/features-stream-settings'
import targetsStreamSettings from './stream/targets-stream-settings'

export const defineContentStreamStore = (settings) => {
    return defineStreamStore(Object.assign({}, contentStreamSettings(settings), settings))
}

export const defineFeaturesStreamStore = (settings) => {
    return defineStreamStore(Object.assign({}, featuresStreamSettings(settings), settings))
}

export const defineTargetsStreamStore = (settings) => {
    return defineStreamStore(Object.assign({}, targetsStreamSettings(settings), settings))
}

export default defineStreamStore
