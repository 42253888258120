<template>
    <a href="#" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar family="topics" :name="result.title" :color="result.avatarColor" :icon="result.avatarIcon" :image="result.avatarImageUrl" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-700">
                    <div class="truncate"><span class="font-medium text-gray-900">{{result.title}}</span></div>
                </div>

                <div class="text-xs leading-4 text-gray-700 truncate">
                    Topic
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    methods: {
        open() {
        }
    }
}
</script>
