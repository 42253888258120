import { defineContentStreamStore } from '../reusable/stream'

export const useContentStreamStore = defineContentStreamStore({
    id: 'contentStream',

    exportable: true,
    polling: true,

    supportsSearchHistory: true,
    
    analyses: [
        { id: 'content-performance', name: 'Performance' },
        { id: 'content-breakdown-by-source', name: 'Top Sources' },
        { id: 'content-breakdown-by-group', name: 'Top Groups' },
        { id: 'content-top-platforms', name: 'Top Platforms' },
//        { id: 'content-breakdown-by-topic', name: 'Topic Modelling' },
        { id: 'content-publish-times', name: 'Publish Times' },
        { id: 'content-pull-push', name: 'Pull-Push' },
        { id: 'content-sentiment', name: 'Sentiment' },
        { id: 'content-keywords', name: 'Keywords' }
    ]
})

export default useContentStreamStore
