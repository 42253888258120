<template>
    <ui-modal name="delete-confirmation" :title="`Are you sure you want to delete this ${entity}?`">
        <div class="text-sm text-gray-700" v-if="hasDependents">
            <div class="text-gray-900">
                The follwing items use this {{entity}}:
            </div>

            <div class="mt-3" v-if="dependents.analyses && Object.values(dependents.analyses).length">
                <h4 class="font-medium">
                    <ui-icon name="analysis"></ui-icon>
                    Analyses
                </h4>
                <div>
                    <div :key="index" v-for="analysis, index in dependents.analyses">
                        {{analysis}}
                    </div>
                </div>
            </div>

            <div class="mt-3" v-if="dependents.dashboards && Object.values(dependents.dashboards).length">
                <h4 class="font-medium">
                    <ui-icon name="dashboard"></ui-icon>
                    Dashboards
                </h4>
                <div>
                    <div :key="index" v-for="dashboard, index in dependents.dashboards">
                        {{dashboard}}
                    </div>
                </div>
            </div>

            <div class="mt-3" v-if="dependents.perspectives && Object.values(dependents.perspectives).length">
                <h4 class="font-medium">
                    <ui-icon name="search"></ui-icon>
                    Perspectives
                </h4>
                <div>
                    <div :key="index" v-for="perspective, index in dependents.perspectives">
                        {{perspective}}
                    </div>
                </div>
            </div>

            <div class="mt-3" v-if="dependents.topics && Object.values(dependents.topics).length">
                <h4 class="font-medium">
                    <ui-icon name="message-circle"></ui-icon>
                    Topics
                </h4>
                <div>
                    <div :key="index" v-for="topic, index in dependents.topics">
                        {{topic}}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-end space-x-4" :class="{ 'mt-8': hasDependents }">
            <ui-button type="button" :action="cancel" :disabled="submitting">
                Cancel
            </ui-button>
            <ui-button type="button" :action="confirm" color="red" :disabled="submitting">
                Delete
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import modalsDeleteConfirmationStore from '@/stores/modals/delete-confirmation'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(modalsDeleteConfirmationStore),
        ...mapState(modalsDeleteConfirmationStore, [ 'dependents', 'entity', 'hasDependents', 'submitting' ])
    },

    methods: {
        ...mapActions(modalsDeleteConfirmationStore, [ 'cancel', 'confirm' ])
    }
}
</script>
