import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox'

import { content } from '@/api'

import { defineStore } from 'pinia'

export const defineOverviewTopMediaStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        series: [{
            name: null,
            color: null,
            filters: null,
            items: [],
            isLoading: false
        }],

        isInitialized: false,

        mediaLightbox: defineStreamMediaLightbox({
            id: `${settings.id}MediaLightbox`,
            name: `lightbox-${settings.id}`
        })()
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{ ...series, items: [], isLoading: false }]

            await this.load()

            this.isInitialized = true
        },

        async compareWith(series) {
            this.series.push(series = { ...series, items: [], isLoading: false })

            this.loadItems(series)
        },

        stopComparing(series) {
            this.series = this.series.filter(s => s.id != series.id)
        },

        openOverlay(content, media, index) {
            this.mediaLightbox.open(media, index, content)
        },

        async load() {
            return Promise.all(this.series.map(series => this.loadItems(series)))
        },

        async loadItems(series) {
            series = this.series.find(s => s.id == series.id) // This fixes a reactivity issue, with updating the series data

            series.isLoading = true

            return content()
                .filters({ ...series.filters.toPerspective(), 'media': { type: 'contains-media', id: [] } })
                .sorting('interactions-desc')
                .limit(24)
                .get(content => {
                    series.items = content
                    series.isLoading = false
                })
        }
    }
})

export default defineOverviewTopMediaStore
