<template>
    <router-view></router-view>

    <Teleport to="#root">
        <content-lightbox :store="contentDetailMediaLightbox"></content-lightbox>

        <notifications-manage-modal></notifications-manage-modal>
        <perspective-edit-modal></perspective-edit-modal>
        <topic-edit-modal></topic-edit-modal>
        <perspective-duplicate-modal></perspective-duplicate-modal>
        <review-overlay></review-overlay>
        <search-history-modal :stream-store="$page.streamStore"></search-history-modal>
        <sentiment-edit-modal></sentiment-edit-modal>
        <smart-tag-modal></smart-tag-modal>
        <targets-list-duplicate-modal></targets-list-duplicate-modal>
        <targets-list-edit-modal></targets-list-edit-modal>
        <topic-duplicate-modal></topic-duplicate-modal>
        <note-modal></note-modal>
        <content-hash-modal></content-hash-modal>
    </Teleport>
</template>

<script>
import ContentLightbox from '@/components/ui/stream/lightbox/lightbox'
import NotificationsManageModal from './modals/notifications-manage'
import PerspectiveEditModal from './modals/perspective-edit.vue'
import TopicEditModal from './modals/topic-edit'
import PerspectiveDuplicateModal from './modals/perspective-duplicate'
import ReviewOverlay from './modals/review-overlay'
import SearchHistoryModal from '@/components/modals/search-history'
import SentimentEditModal from '@/components/content/modals/sentiment-edit'
import SmartTagModal from '@/components/content/modals/smart-tag'
import TargetsListDuplicateModal from '@/components/targets/modals/list-duplicate'
import TargetsListEditModal from '@/components/targets/modals/list-edit'
import TopicDuplicateModal from './modals/topic-duplicate'
import NoteModal from '@/components/me/notes/modal'
import ContentHashModal from '@/components/content/modals/content-hash'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'

import { mapState } from 'pinia/dist/pinia'

export default {
    components: {
        ContentLightbox, NoteModal, NotificationsManageModal, PerspectiveEditModal, TargetsListDuplicateModal,
        TargetsListEditModal, TopicEditModal, PerspectiveDuplicateModal, SearchHistoryModal, SentimentEditModal,
        SmartTagModal, TopicDuplicateModal, ReviewOverlay, ContentHashModal
    },

    computed: {
        ...mapState(useContentModalsContentDetailStore, { contentDetailMediaLightbox: 'mediaLightbox' })
    }
}
</script>
