<template>
    <div v-if="groups && groups.length" class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md px-6 py-4">
        <div class="mb-6">
            <h3 class="font-semibold text-gray-900">
                Most Active In Groups
            </h3>
        </div>

        <div>
            <router-link
                :to="{ name: 'targets.target.information', params: { type: 'groups', modelType: group.type, id: group.id } }"
                target="_blank"
                v-for="group in groups"
                :key="group.id"
                class="flex items-center justify-between py-1"
            >
                <div class="flex items-center">
                    <ui-avatar :item="group" class="h-5 w-5 mr-2"></ui-avatar>
                    <p class="tracking-tight">{{ group.name }}</p>
                </div>
                <div>
                    <span class="font-medium">{{ group.count }}</span> <span>posts</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import useTargetsTargetInformationTopGroupsStore from '@/stores/targets/target/information-top-groups'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useTargetsTargetInformationTopGroupsStore, [ 'groups' ])
    }
}
</script>
