import defineCards from '@/stores/reusable/cards'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'

import { sortNaturally } from '@/helpers'

import { defineStore } from 'pinia'

export const useContentIndexCardsStore = defineCards({
    id: 'contentIndexCards',

    source: defineStore({
        id: 'contentIndexCardsSource',
        getters: {
            items() {
                return [
                    ...useMyPerspectivesStore().items.filter(item => ! item.virtual),
                    ...useMySearchTopicsStore().items
                ]
            },
            isInitialized() {
                return useMyPerspectivesStore().isInitialized && useMySearchTopicsStore().isInitialized
            },
            isLoading() {
                return useMyPerspectivesStore().isLoading || useMySearchTopicsStore().isLoading
            }
        }
    })(),

    filter: items => items.filter(item => item.pinned || item.recent),

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: true,
            apply: items => sortNaturally(items)
        }
    ]
})

export default useContentIndexCardsStore
