import { differenceInDays, parseISO, subDays, subHours, subMonths, subWeeks, subYears } from 'date-fns'

export function determineGranularity(value) {
    let from, to

    if (value.type == 'past') {
        if (value.date.unit == 'days') {
            [ from, to ] = [ subDays(new Date(), value.date.past * 2), subDays(new Date(), value.date.past) ]
        } else if (value.date.unit == 'hours') {
            [ from, to ] = [ subHours(new Date(), value.date.past * 2), subHours(new Date(), value.date.past) ]
        } else if (value.date.unit == 'weeks') {
            [ from, to ] = [ subWeeks(new Date(), value.date.past * 2), subWeeks(new Date(), value.date.past) ]
        } else if (value.date.unit == 'months') {
            [ from, to ] = [ subMonths(new Date(), value.date.past * 2), subMonths(new Date(), value.date.past) ]
        } else if (value.date.unit == 'years') {
            [ from, to ] = [ subYears(new Date(), value.date.past * 2), subYears(new Date(), value.date.past) ]
        }
    } else if (value.type == 'in-range') {
        [ from, to ] = [ value.date.gte instanceof Date ? value.date.gte : parseISO(value.date.gte), value.date.lte instanceof Date ? value.date.lte : parseISO(value.date.lte) ]
    } else if (value.type == 'after') {
        [ from, to ] = [ value.date.gte instanceof Date ? value.date.gte : parseISO(value.date.gte), new Date() ]
    } else {
        return 'month'
    }

    if (differenceInDays(to, from) <= 3) {
        return 'hour'
    } else if (differenceInDays(to, from) <= 31) {
        return 'day'
    } else if (differenceInDays(to, from) <= 365) {
        return 'week'
    } else {
        return 'month'
    }
}
