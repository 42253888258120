<template>
    <div class="relative w-full h-full bg-gray-75" ref="pageContainer">
        <template v-if="$page.graph.inspectedNodeIds.length">
            <div class="w-72 absolute top-4 left-4 z-20">
                <div class="bg-white w-full overflow-hidden shadow rounded py-2 mb-4">
                    <a href="#" class="flex items-center font-medium px-6 py-2" @click.prevent="toggleControls">
                        <ui-icon name="share-2" class="mr-2"></ui-icon>
                        Explore
                        <ui-icon :name="controls != 'hidden' ? 'chevron-down' : 'chevron-right'" class="ml-auto"></ui-icon>
                    </a>
                    <div class="border-t border-gray-200 mt-2 pt-2" v-if="controls != 'hidden'">
                        <template v-if="controls == 'shown'">
                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'type'">
                                <div class="flex-1">
                                    <div>{{selectedType.name}}</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500">{{selectedType.description}}</div>
                                </div>
                                <ui-icon name="arrow-right-circle" class="shrink-0"></ui-icon>
                            </a>

                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'targets'">
                                <div class="flex-1">
                                    <div v-if="$page.graph.shownTargets.length == targets.length">Show all Targets</div>
                                    <div v-else-if="$page.graph.shownTargets.length">Show some Targets</div>
                                    <div v-else>Don't show Targets</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500" v-if="$page.graph.shownTargets.length && $page.graph.shownTargets.length != targets.length">
                                        {{ shownTargetNames.join(', ') }}
                                    </div>
                                </div>
                                <ui-icon name="arrow-right-circle" class="shrink-0"></ui-icon>
                            </a>

                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'features'">
                                <div class="flex-1">
                                    <div v-if="$page.graph.shownFeatures.length == features.length">Show all Features</div>
                                    <div v-else-if="$page.graph.shownFeatures.length">Show some Features</div>
                                    <div v-else>Don't show Features</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500" v-if="$page.graph.shownFeatures.length && $page.graph.shownFeatures.length != features.length">
                                        {{ shownFeatureNames.join(', ') }}
                                    </div>
                                </div>
                                <ui-icon name="arrow-right-circle" class="shrink-0"></ui-icon>
                            </a>
                        </template>

                        <template v-if="controls == 'type'">
                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" :class="{ 'text-blue-600': selectedType.id == type.id }" @click.prevent="selectType(type.id)" :key="type.id" v-for="type in types">
                                <div class="flex-1">
                                    <div>{{type.name}}</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500">{{type.description}}</div>
                                </div>
                                <ui-icon name="check" class="shrink-0" :class="{ 'text-gray-400': selectedType.id != type.id }"></ui-icon>
                            </a>
                        </template>

                        <template v-if="controls == 'targets'">
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" :class="{ 'text-blue-600': $page.graph.shownTargets.includes(target.type) }" @click.prevent="toggleShownTarget(target.type)" :key="target.type" v-for="target in targets">
                                <ui-icon :name="target.icon" class="mr-2"></ui-icon>
                                <div>{{target.name}}</div>
                                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! $page.graph.shownTargets.includes(target.type) }"></ui-icon>
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownTarget(true)">
                                Show All
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownTarget(false)">
                                Show None
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'shown'">
                                <ui-icon name="arrow-left" class="mr-2"></ui-icon>
                                Back
                            </a>
                        </template>

                        <template v-if="controls == 'features'">
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" :class="{ 'text-blue-600': $page.graph.shownFeatures.includes(feature.type) }" @click.prevent="toggleShownFeature(feature.type)" :key="feature.type" v-for="feature in features">
                                <ui-icon :name="feature.icon" class="mr-2"></ui-icon>
                                <div>{{feature.name}}</div>
                                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! $page.graph.shownFeatures.includes(feature.type) }"></ui-icon>
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownFeature(true)">
                                Show All
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownFeature(false)">
                                Show None
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'shown'">
                                <ui-icon name="arrow-left" class="mr-2"></ui-icon>
                                Back
                            </a>
                        </template>
                    </div>
                </div>
            </div>

            <div class="absolute bottom-4 left-4 z-20 flex shadow rounded bg-white divide-x divide-gray-200">
                <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:bg-gray-50" @click.prevent="$page.graph.zoomOut">
                    <ui-icon name="zoom-out"></ui-icon>
                </a>
                <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:bg-gray-50" @click.prevent="$page.graph.zoomIn">
                    <ui-icon name="zoom-in"></ui-icon>
                </a>
                <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:bg-gray-50" @click.prevent="toggleFullscreen">
                    <ui-icon :name="isFullscreen ? 'minimize-2' : 'maximize-2'"></ui-icon>
                </a>
            </div>

            <div class="w-72 absolute top-4 right-4 z-20" v-if="this.searchQuery || nodes.length">
                <div class="bg-white w-full overflow-hidden shadow rounded py-2 mb-4">
                    <a href="#" class="flex items-center font-medium px-6 py-2" @click.prevent="toggleNodesList">
                        {{$page.graph.nodes.length}} connections
                        <ui-icon :name="nodesList != 'hidden' ? 'chevron-down' : 'chevron-right'" class="ml-auto" v-if="nodes.length"></ui-icon>
                    </a>
                    <div class="border-t border-gray-200 mt-2 pt-2" v-if="nodesList != 'hidden'">
                        <div class="relative px-2">
                            <div class="absolute left-2 top-0 h-full w-10 flex items-center justify-center z-30">
                                <ui-icon name="search"></ui-icon>
                            </div>
                            <ui-input type="search" class="pl-8" placeholder="Search..." v-model="searchQuery"></ui-input>
                        </div>
                        <div class="p-2 overflow-y-auto max-h-80" v-if="nodes.length">
                            <div :key="node.id" v-for="node in nodes" class="flex items-center justify-between rounded transition hover:bg-blue-400 hover:text-white group" @mouseenter="highlightNode(node.id)" @mouseleave="cancelHighlightedNodes()">
                                <a href="#" class="py-1 px-2 truncate" @click.prevent="focusNode(node.id)">
                                    <ui-icon :name="node.icon" class="shrink-0 mr-1"></ui-icon>
                                    <span>{{node.name}}</span>
                                </a>
                                <a href="#" class="py-1 hidden group-hover:block" @click.prevent="$page.graph.hideNode(node.id)" title="Hide node" v-if="!this.$page.graph.hiddenNodes.includes(node.id) && this.$page.graph.inspectedNodeIds[0] != node.id">
                                    <ui-icon name="eye-off" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
                                </a>
                                <a href="#" class="py-1" @click.prevent="$page.graph.showNode(node.id)" title="Show node" v-if="this.$page.graph.hiddenNodes.includes(node.id)">
                                    <ui-icon name="eye-off" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
                                </a>
                            </div>
                        </div>
                        <div class="border-t border-gray-200 pt-2">
                            <a @click.prevent="$page.graph.showAll" href="#" class="flex items-center font-medium px-6 py-1" :class="$page.graph.isLoading ? 'text-gray-400' : 'hover:text-blue-600'">
                                Show Hidden Nodes
                                <ui-icon name="eye" class="ml-auto"></ui-icon>
                            </a>
                            <a @click.prevent="$page.graph.hideAll" href="#" class="flex items-center font-medium px-6 py-1" :class="$page.graph.isLoading ? 'text-gray-400' : 'hover:text-blue-600'">
                                Hide All Nodes
                                <ui-icon name="eye-off" class="ml-auto"></ui-icon>
                            </a>

                            <a @click.prevent="$page.graph.export" href="#" class="flex items-center font-medium px-6 py-1" :class="$page.graph.isLoading ? 'text-gray-400' : 'hover:text-blue-600'">
                                Export
                                <ui-icon name="download" class="ml-auto"></ui-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="absolute top-96 z-10 flex justify-center w-full" v-if="$page.graph.isLoading">
                <div class="bg-white shadow rounded flex items-center px-4 py-2">
                    <ui-spinner type="clip" class="mr-1"></ui-spinner> Updating graph data...
                </div>
            </div>

            <div class="absolute top-96 z-10 flex justify-center w-full" v-if="!$page.graph.isLoading && !$page.graph.nodes.length">
                <ui-icon name="slash" class="pt-1" style="height: 2em; width: 2em"></ui-icon>
                <div class="text-2xl mt-0.5 ml-0.5">There's no data for this graph yet</div>
            </div>
        </template>

        <template v-else>
            <div class="absolute inset-0 flex items-center justify-center" v-if="$page.graph.isLoading">
                <ui-spinner></ui-spinner>
            </div>

            <div class="w-full h-full flex items-center justify-center" v-else>
                <div class="bg-white border border-gray-200 shadow-sm rounded-md px-8 py-12 flex flex-col items-center text-center space-y-6 w-96">
                    <h1 class="text-xl font-semibold">
                        <ui-icon name="share" class="mr-1"></ui-icon>
                        Connections Explorer
                    </h1>

                    <div>
                        Connections Explorer allows you to explore the network of interconnected Targets in a visual way.
                    </div>

                    <div>
                        Choose a target to use as a starting point.
                    </div>

                    <ui-button @click.prevent="$page.chooseTarget" color="blue" size="lg">
                        Choose a target
                    </ui-button>
                </div>
            </div>
        </template>

        <ui-async :guards="$page.graph">
            <div class="w-full h-full" id="connections-graph-container"></div>
        </ui-async>
    </div>

    <Teleport to="#root">
        <connections-modals-node-details></connections-modals-node-details>
    </Teleport>
</template>

<script>
import ConnectionsModalsNodeDetails from '@/components/connections/modals/node-details'

import { textSearch } from '@/helpers'

export default {
    components: {
        ConnectionsModalsNodeDetails
    },

    data: () => ({
        controls: 'shown',
        nodesList: 'shown',

        searchQuery: '',
        isFullscreen: false,

        types: [
            { id: 'actor-feature', name: 'Curated Connections', description: 'Sources and features that are related to this Target in meaningful way.' },
            { id: 'all', name: 'All Connections', description: 'Sources and features that are related to this Target in any way.' },
            { id: 'upstream', name: 'Upstream Sources', description: 'Sources this target shares content from.' },
            { id: 'downstream', name: 'Downstream Sources', description: 'Sources sharing content from this Target.' },
            { id: 'shared-properties', name: 'Sources Sharing Properties', description: 'Sources sharing the same Features.' },
            { id: 'connected-features', name: 'Connected Features', description: 'Features connected to this source directly or via published content.' }
        ],

        targets: [
            { type: 'facebook-page', name: 'Facebook Pages', icon: 'badges.target-facebook-page' },
            { type: 'facebook-user', name: 'Facebook Users', icon: 'badges.target-facebook-user' },
            { type: 'facebook-group', name: 'Facebook Groups', icon: 'badges.target-facebook-group' },
            { type: 'telegram-channel', name: 'Telegram Channels', icon: 'badges.target-telegram-channel' },
            { type: 'telegram-group', name: 'Telegram Groups', icon: 'badges.target-telegram-group' },
            { type: 'telegram-user', name: 'Telegram Users', icon: 'badges.target-telegram-user' },
            { type: 'twitter-user', name: 'Twitter Users', icon: 'badges.target-twitter-user' },
            { type: 'vkontakte-community', name: 'VKontakte Community', icon: 'badges.target-vkontakte-community' },
            { type: 'vkontakte-user', name: 'VKontakte User', icon: 'badges.target-vkontakte-user' },
            { type: 'web-feed', name: 'Web Feeds', icon: 'badges.target-web-feed' },
            { type: 'web-site', name: 'Web Sites', icon: 'badges.target-web-site' },
            { type: 'youtube-channel', name: 'Youtube Channels', icon: 'badges.target-youtube-channel' }
        ],

        features: [
            { type: 'image', name: 'Images', icon: 'badges.feature-image' },
            { type: 'email', name: 'Email Addresses', icon: 'badges.feature-email' },
            { type: 'location', name: 'Locations', icon: 'badges.feature-location' },
            { type: 'phone-number', name: 'Phone Numbers', icon: 'badges.feature-phone-number' },
            { type: 'iban', name: 'Bank Accounts', icon: 'badges.feature-iban' },
            { type: 'domain', name: 'Domain Names', icon: 'badges.feature-domain' },
            { type: 'ip-address', name: 'IP Addresses', icon: 'badges.feature-ip-address' },
            { type: 'hashtag', name: 'Hashtags', icon: 'badges.feature-hashtag' },
            { type: 'name', name: 'Person Names', icon: 'badges.feature-name' },
            { type: 'url', name: 'URLs', icon: 'badges.feature-url' }
        ]
    }),

    computed: {
        selectedType() {
            return this.types.find(t => t.id == this.$page.graph.type)
        },

        shownTargetNames() {
            return this.$page.graph.shownTargets.map(target => this.targets.find(t => t.type == target).name)
        },

        shownFeatureNames() {
            return this.$page.graph.shownFeatures.map(feature => this.features.find(t => t.type == feature).name)
        },

        nodes() {
            let nodes = this.searchQuery ? textSearch(this.searchQuery, this.$page.graph.nodes, n => n.attributes.label) : this.$page.graph.nodes

            return nodes.map(n => {
                if (n.attributes.label) {
                    if (n.attributes.nodeFamily == 'source' || n.attributes.nodeFamily == 'group') {
                        return {
                            id: n.key,
                            name: n.attributes.label,
                            type: n.attributes.nodeType,
                            icon: this.targets.find(t => t.type == n.attributes.nodeType).icon
                        }
                    } else if (n.attributes.nodeFamily == 'feature') {
                        return {
                            id: n.key,
                            name: n.attributes.label,
                            type: n.attributes.nodeType,
                            icon: this.features.find(t => t.type == n.attributes.nodeType).icon
                        }
                    }
                }
            })
                .filter(n => n)
                .sort((a, b) => a.name.localeCompare(b.name))
        }
    },

    methods: {
        toggleControls() {
            this.controls = this.controls == 'hidden' ? 'shown' : 'hidden'
        },

        toggleNodesList() {
            this.nodesList = this.nodesList == 'hidden' ? 'shown' : 'hidden'
        },

        selectType(type) {
            this.$page.graph.setType(type)
            this.controls = 'shown'
        },

        toggleShownTarget(targetType) {
            let shownTargets = [ ...this.$page.graph.shownTargets ]

            if (targetType === true) {
                shownTargets = this.targets.map(t => t.type)
            } else if (targetType === false) {
                shownTargets = []
            } else if (targetType) {
                if (shownTargets.includes(targetType)) {
                    shownTargets = shownTargets.filter(t => t != targetType)
                } else {
                    shownTargets = [ ...shownTargets, targetType ]
                }
            }

            this.$page.graph.setShownTargets(shownTargets)
        },

        toggleShownFeature(featureType) {
            let shownFeatures = [ ...this.$page.graph.shownFeatures ]

            if (featureType === true) {
                shownFeatures = this.features.map(t => t.type)
            } else if (featureType === false) {
                shownFeatures = []
            } else if (featureType) {
                if (shownFeatures.includes(featureType)) {
                    shownFeatures = shownFeatures.filter(t => t != featureType)
                } else {
                    shownFeatures = [ ...shownFeatures, featureType ]
                }
            }

            this.$page.graph.setShownFeatures(shownFeatures)
        },

        toggleFullscreen() {
            if (this.isFullscreen) {
                document.exitFullscreen().then(() => this.isFullscreen = false)
            } else {
                this.$refs.pageContainer.requestFullscreen().then(() => this.isFullscreen = true)
            }
        },

        focusNode(key) {
            this.$page.graph.focusNode(key)
            this.searchQuery = ''
        },

        highlightNode(nodeId) {
            this.$page.graph.highlightNode(nodeId)
        },

        cancelHighlightedNodes() {
            this.$page.graph.cancelHighlightedNodes()
        }
    }
}
</script>
