import { defineStore } from 'pinia'
import { useModal } from '@/helpers'
import { content } from '@/helpers/api-three-tables'

import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox'

export const useContentModalsContentDetailStore = defineStore({
    id: 'content-modals-content-detail',

    state: () => ({
        content: null,
        replies: [],
        streamStore: null,

        mediaLightbox: defineStreamMediaLightbox({
            id: `contentModalsContentDetailMediaLightbox`,
            name: `stream-content-lightbox-content-detail`
        })(),

        sorting: 'latest',
        sortingOptions: [
            { name: 'Latest', id: 'latest', icon: 'calendar', value: i => i.publishedAt, apiId: 'date-desc', direction: 'desc', default: true },
            { name: 'Oldest', id: 'oldest', icon: 'none', value: i => i.publishedAt, apiId: 'date-asc' },
            {},
            { name: 'Most Interactions', id: 'interactions', icon: 'interactions', value: i => i.metrics.interactions, apiId: 'interactions-desc', direction: 'desc' }
        ],
        sort: null,

        loading: false,
        lastReloadTime: +new Date()
    }),

    getters: {
        sortingOption(store) {
            return store.sortingOptions.find(o => o.id == store.sorting) || store.sortingOptions.find(o => o.default)
        }
    },

    actions: {
        async open(id, streamStore) {
            if (this.content && this.content.id !== id) {
                this.content = null
                this.replies = []
            }

            this.streamStore = streamStore

            useModal().show('content-detail')

            this.loading = true

            await content()
                .details('extended')
                .replies(false)
                .find(id, res => {
                    this.loading = false
                    this.lastReloadTime = +new Date()
                    this.content = res
                })
        },

        inline(content, streamStore) {
            if (this.content && this.content.id !== content.id) {
                this.content = null
                this.replies = []
            }

            this.streamStore = streamStore

            this.lastReloadTime = +new Date()
            this.content = content
        },

        async loadAdditionalReplies(parent, infiniteScroll) {
            let replies = parent ? parent.replies : this.replies

            await content()
                .conversation(this.content.id)
                .parent(parent?.id)
                .when(replies.length && this.sort, c => c.after(this.sort))
                .sorting(this.sortingOption.apiId)
                .limit(30)
                .get((data, res) => {
                    replies.push(...data)
                    this.sort = res.sort

                    if (infiniteScroll) data.length ? infiniteScroll.loaded() : infiniteScroll.complete()
                })
        },

        setSorting(sorting) {
            this.sorting = sorting
            this.replies = []

            this.loadAdditionalReplies().then(() => this.lastReloadTime = +new Date())
        },

        cancel() {
            useModal().hide('content-detail')
        }
    }
})

export default useContentModalsContentDetailStore
