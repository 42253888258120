<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-twitter-user"
        avatar-badge-tooltip="Twitter User"
        :parent-component="{ 'twitter-tweet': _.type }"
    >
        <template v-slot:no-content>
            <div class="font-semibold text-gray-800 italic text-sm" v-if="content.parentType == 'retweeted'">
                <ui-icon name="repeat"></ui-icon> Retweeted
            </div>
        </template>

        <!-- Metrics -->
        <template v-slot:metrics>
            <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="content.metrics.interactions">
                <div class="flex items-center" v-if="content.metrics.interactions">
                    <ui-dropdown align="left" position="top" unstyled>
                        <template v-slot:trigger>
                            <div class="flex justify-center items-center cursor-pointer">
                                <ui-icon name="interactions-solid" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                <div class="leading-none ml-2">
                                    <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions
                                    <template v-if="content.forecasts && content.forecasts.length">
                                        <ui-icon name="chevron-right"></ui-icon>
                                        <span class="font-bold">{{ $number(latestForecast.reactions) }}</span> by tomorrow
                                    </template>
                                </div>
                            </div>
                        </template>

                        <template v-slot:content>
                            <div class="rounded-sm shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left space-y-3 whitespace-nowrap">
                                <div class="flex items-center" v-if="content.metrics.twitter.retweets">
                                    <ui-icon name="repeat" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.twitter.retweets) }}</span> retweets
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.comments">
                                    <ui-icon name="at-sign" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.comments) }}</span> replies
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.reactions">
                                    <ui-icon name="thumbs-up" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.reactions) }}</span> likes
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.twitter.quotes">
                                    <ui-icon name="corner-down-right" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.twitter.quotes) }}</span> quotes
                                    </div>
                                </div>
                            </div>
                        </template>
                    </ui-dropdown>
                </div>

                <div class="flex justify-center items-center" v-if="content.metrics.views">
                    <ui-icon name="views" class="text-lg shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-2">
                        <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'

export default {
    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    computed: {
        latestForecast() {
            if (this.content.forecasts && this.content.forecasts.length) {
                return this.content.forecasts[this.content.forecasts.length - 1]
            }

            return null
        }
    }
}
</script>
