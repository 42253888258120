<template>
    <ui-modal name="content-review-overlay" unstyled width="100%" height="100%" max-width="100vw" max-height="100vh" @keydown="keyPressed">
        <div class="w-full h-full flex flex-col">
            <div class="flex items-center justify-center py-10">
                <div class="bg-gray-900 border border-gray-800 bg-opacity-90 backdrop-blur-sm text-white py-2 pb-3 px-6 shadow-xl rounded-lg w-full max-w-xl relative overflow-hidden">
                    <div class="flex items-center">
                        <div>
                            <span class="text-lg font-medium">Review mode</span>
                            &middot;
                            <span class="text-gray-300">{{$number(itemsLeft)}} items left</span>
                        </div>
                        
                        <a @click.prevent="close" href="#" class="ml-auto">
                            <ui-icon name="x" class="text-lg"></ui-icon>
                        </a>
                    </div>
                        
                    <div class="h-0.5 bg-gray-400 rounded-full overflow-hidden flex w-full absolute bottom-0 left-0">
                        <div class="h-0.5 bg-blue-600 rounded-full" :style="`width: ${itemsReviewed / itemsTotal * 100}%;`"></div>
                    </div>
                </div>
            </div>
    
            <div class="flex-1 min-h-0 flex items-center justify-center my-1">
                <div class="flex-1 flex items-center justify-center">
                    <a @click.prevent="previous" href="#" class="bg-gray-800/80 shadow w-12 h-12 flex items-center justify-center rounded-full text-[26px] text-white" v-if="itemsReviewed > 1">
                        <ui-icon name="arrow-left" class="drop-shadow"></ui-icon>
                    </a>
                </div>
            
                <component :is="cardComponent" :content="content" mode="modal" :stream-store="streamStore" :key="content.id" v-if="content" class="max-w-xl shadow-xl rounded-lg">
                    <!--<template v-slot:details>-->
                    <!--    <content-detail-replies :replies="replies"></content-detail-replies>-->
                    <!--</template>-->
                </component>
                
                <div class="text-5xl" v-else>
                    <ui-spinner type="clip" color="#fff"></ui-spinner>
                </div>

                <div class="flex-1 flex items-center justify-center">
                    <a @click.prevent="next" href="#" class="bg-gray-800/80 shadow w-12 h-12 flex items-center justify-center rounded-full text-[26px] text-white" v-if="itemsLeft">
                        <ui-icon name="arrow-right" class="drop-shadow"></ui-icon>
                    </a>
                </div>
            </div>
            
            <div class="flex items-center justify-center py-10">
                <div class="bg-gray-900 border border-gray-800 bg-opacity-90 backdrop-blur-sm text-white shadow-xl rounded-lg divide-x divide-gray-800 flex">
                    <ui-dropdown>
                        <template v-slot:trigger>
                            <div class="p-1">
                                <div class="px-3 py-1 hover:bg-gray-800 bg-opacity-50 rounded-lg cursor-pointer">
                                    {{tagGroup?.name || 'All Tags'}} <ui-icon name="select"></ui-icon>
                                </div>
                            </div>
                        </template>
                        
                        <template v-slot:content>
                            <ui-dropdown-link @click="selectTagGroup(null)" :selected="! tagGroup">
                                All Tags
                            </ui-dropdown-link>
                            <ui-dropdown-link @click="selectTagGroup(group)" :selected="tagGroup?.id == group.id" :key="group.id" v-for="group in tagGroups">
                                {{group.name}}
                            </ui-dropdown-link>
                        </template>
                    </ui-dropdown>
                    
                    <div class="p-1" v-for="tag, index in tags.slice(0, 9)" :key="tag.id">
                        <a @click.prevent="toggleTag(tag)" href="#" class="px-2 py-1 rounded-lg cursor-pointer flex items-center" :class="hasTag(tag) ? 'bg-blue-700' : 'hover:bg-gray-800'">
                            <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${tag.color} mr-1`"></span>
                            {{tag.name}}
                            <span class="font-medium text-xs ml-3 border rounded px-1.5" :class="hasTag(tag) ? 'text-gray-400 border-gray-400' : 'text-gray-700 border-gray-700'">{{index + 1}}</span>
                        </a>
                    </div>

                    <ui-dropdown>
                        <template v-slot:trigger>
                            <div class="p-1">
                                <div class="px-3 py-1 hover:bg-gray-800 bg-opacity-50 rounded-lg cursor-pointer">
                                    <ui-icon name="more-horizontal"></ui-icon>
                                </div>
                            </div>
                        </template>
                        
                        <template v-slot:content>
                            <ui-dropdown-link @click="toggleTag(tag)" :icon="true" :selected="hasTag(tag)" :checkbox="true" keep-open :key="tag.id" v-for="tag in tags">
                                <template v-slot:icon>
                                    <div class="w-4 flex justify-center items-center text-center">
                                        <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${tag.color}`"></span>
                                    </div>
                                </template>
                                <template v-slot:default>
                                    {{tag.name}}
                                </template>
                            </ui-dropdown-link>
                        </template>        
                    </ui-dropdown>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import ContentDetailReplies from './partials/content-detail-replies'

import ContentFacebookGroupPostCard from '@/components/content/stream-cards/facebook-group-post'
import ContentFacebookPostCard from '@/components/content/stream-cards/facebook-post'
import ContentFacebookUserPostCard from '@/components/content/stream-cards/facebook-user-post'
import ContentInstagramPostCard from '@/components/content/stream-cards/instagram-post'
import ContentTelegramChannelPostCard from '@/components/content/stream-cards/telegram-channel-post'
import ContentTelegramGroupMessageCard from '@/components/content/stream-cards/telegram-group-message'
import ContentTiktokVideoCard from '@/components/content/stream-cards/tiktok-video'
import ContentTwitterTweetCard from '@/components/content/stream-cards/twitter-tweet'
import ContentVkontaktePostCard from '@/components/content/stream-cards/vkontakte-post'
import ContentWebArticleCard from '@/components/content/stream-cards/web-article'
import ContentWebResourceCard from '@/components/content/stream-cards/web-resource'
import ContentYoutubeVideoCard from '@/components/content/stream-cards/youtube-video'

import useContentModalsReviewOverlayStore from '@/stores/content/modals/review-overlay'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ContentDetailReplies
    },

    computed: {
        ...mapState(useContentModalsReviewOverlayStore, [
            'loading', 'content', 'replies', 'streamStore', 'isShown', 'itemsLeft', 'itemsReviewed', 'itemsTotal',
            'previous', 'next', 'tagGroup', 'tagGroups', 'tags'
        ]),

        cardComponent() {
            return {
                'facebook-group-post': ContentFacebookGroupPostCard,
                'facebook-post': ContentFacebookPostCard,
                'facebook-user-post': ContentFacebookUserPostCard,
                'instagram-post': ContentInstagramPostCard,
                'telegram-channel-post': ContentTelegramChannelPostCard,
                'telegram-group-message': ContentTelegramGroupMessageCard,
                'tiktok-video': ContentTiktokVideoCard,
                'twitter-tweet': ContentTwitterTweetCard,
                'vkontakte-post': ContentVkontaktePostCard,
                'web-article': ContentWebArticleCard,
                'web-resource': ContentWebResourceCard,
                'youtube-video': ContentYoutubeVideoCard
            }[this.content.type]
        },
    },

    methods: {
        ...mapActions(useContentModalsReviewOverlayStore, [ 'close', 'selectTagGroup', 'toggleTag', 'hasTag' ]),
        
        keyPressed(ev) {
            useContentModalsReviewOverlayStore().keyPressed(ev.key)
        }
    }
}
</script>
