<template>
  <div class="rounded-lg bg-white shadow">
    <div class="h-32 flex items-center justify-center text-3xl text-gray-200">
      <ui-icon name="activity"></ui-icon>
    </div>
    <div class="p-4">
    <svg
      role="img"
      width="476"
      height="48"
      aria-labelledby="loading-aria"
      viewBox="0 0 476 48"
      preserveAspectRatio="none"
    >
      <title id="loading-aria">Loading...</title>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        clip-path="url(#list-placeholder-clip-path)"
        style='fill: url("#list-placeholder-fill");'
      ></rect>
      <defs>
        <clipPath id="list-placeholder-clip-path">
            <circle cx="12" cy="12" r="12" />
            <rect x="30" y="6" rx="7" ry="7" width="160" height="14" />
            <rect x="0" y="34" rx="5" ry="5" width="120" height="10" />
        </clipPath>
        <linearGradient id="list-placeholder-fill">
          <stop
            offset="0.599964"
            stop-color="#f3f3f3"
            stop-opacity="1"
          >
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
          <stop
            offset="1.59996"
            stop-color="rgb(59, 130, 246)"
            stop-opacity="1"
          >
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
          <stop
            offset="2.59996"
            stop-color="#f3f3f3"
            stop-opacity="1"
          >
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  </div>
</template>
