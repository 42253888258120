<template>
    <div>
        <ui-header title="Dashboards">
            <template v-slot:actions>
                <ui-button icon="plus-square" color="blue" :action="createBoard">
                    New Board
                </ui-button>

                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                        autocomplete="off" spellcheck="false"
                        class="block w-full h-8 pl-8 pr-2 rounded placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
                </form>
            </template>
        </ui-header>

        <div class="pb-6 px-8 max-w-12xl mx-auto">
            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="navigation.dashboards" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to the Dashboard section!</h1>
                <p class="text-lg my-1">In this section you can <span class="font-medium">create dashboards</span> providing useful insights and situational awareness.</p>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Boards</h2>
                        <p class="text-justify my-2">Arrange various types of widgets to create a <span class="font-medium">Board</span> giving you a quick overview of particular situation. Useful for on-screen overview or day-to-day analytics</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Widgets</h2>
                        <p class="text-justify my-2">Various types of <span class="font-medium">widgets</span> are available, like analyses, streams, keyword clouds, texts or more.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can try to <span class="font-medium">build your first board or report</span>.
                </p>
                <div class="flex items-center space-x-3 mt-8">
                    <ui-button icon="plus-square" color="blue" size="lg" :action="createBoard">
                        New Board
                    </ui-button>
                </div>
            </div>

            <ui-cards-toolbar :store="dashboardsIndexCardsStore"></ui-cards-toolbar>

            <ui-cards :store="dashboardsIndexCardsStore">
            </ui-cards>
        </div>
    </div>
</template>

<script>
import useDashboardsIndexCardsStore from '@/stores/dashboards/dashboards'
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'
import useDashboardsManagePage from '@/pages/dashboards-index'

import { mapActions, mapStores, mapState } from 'pinia'

export default {
    computed: {
        ...mapStores(useDashboardsIndexCardsStore),

        ...mapState(useDashboardsManagePage, [ 'type' ]),

        isShowingWelcomeMessage() {
            return this.dashboardsIndexCardsStore.isInitialized && ! this.dashboardsIndexCardsStore.items.length
        }
    },

    methods: {
        ...mapActions(useModalsBoardEditStore, {
            createBoard: 'open'
        })
    }
}
</script>
