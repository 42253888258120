<template>
    <div class="w-full shrink-0 bg-white shadow-lg rounded-lg pointer-events-auto border border-gray-200 flex items-center overflow-hidden mb-4 group relative">
        <div class="shrink-0 flex items-center justify-center text-xl py-3 ml-4">
            <ui-progress v-if="task && task.progressTotalUnits && task.progressCompletedUnits < task.progressTotalUnits" :total-steps="task.progressTotalUnits" :completed-steps="task.progressCompletedUnits"></ui-progress>
            <ui-icon :name="notification.data.icon" :class="{'text-green-600': notification.data.level == 'success', 'text-red-600': notification.data.level == 'error', 'text-blue-600': notification.data.level == 'info'}" v-else-if="notification.data.icon"></ui-icon>
            <ui-icon name="check-full-circle" class="text-green-600" v-else-if="notification.data.level == 'success'"></ui-icon>
            <ui-icon name="error-full-circle" class="text-red-500" v-else-if="notification.data.level == 'error'"></ui-icon>
            <ui-icon name="info" class="text-blue-500" v-else-if="notification.data.level == 'info'"></ui-icon>
        </div>
        <div class="w-0 flex-1 py-3 ml-3 mr-2">
            <div class="flex justify-between items-start">
                <div class="text-sm font-medium" :class="{'text-green-600': notification.data.level == 'success', 'text-red-600': notification.data.level == 'error', 'text-blue-600': notification.data.level == 'info'}" v-html="sanitizeHtml(notification.data.title)"></div>
                <div class="text-xs text-gray-500 ml-1">{{createdAt}}</div>
            </div>
            <p class="mt-0.5 text-xs text-gray-800" v-if="notification.data.description" v-html="sanitizeHtml(notification.data.description)"></p>
        </div>
        <div class="hidden group-hover:flex justify-end bg-gradient-to-r from-transparent to-white absolute inset-0">
            <div class="flex flex-col">
                <div class="h-0 flex-1 flex" v-if="notification.data.action">
                    <button class="w-full px-3 py-2 text-sm font-medium text-blue-600 hover:text-blue-900" @click="notification.onAction(notification)">
                        {{notification.data.action}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useMyNotificationsStore from '@/stores/me/notifications'

import { sanitizeHTML } from '@/helpers'

import { format, parseISO } from 'date-fns'

export default {
    props: [ 'notification' ],

    data: () => ({
        currentDate: +(new Date)
    }),

    mounted() {
        setInterval(() => this.currentDate = +(new Date), 60000)
    },

    computed: {
        createdAt() {
            let date = parseISO(this.notification.createdAt)
            let diffInSeconds = (this.currentDate - date) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(date, 'H:mm')
            } else {
                return format(date, 'LLL do')
            }
        },
        
        task() {
            return this.notification.taskId ? useMyNotificationsStore().tasks.find(t => t.id == this.notification.taskId) : null
        }
    },
    
    methods: {
        sanitizeHtml(html) {
            return sanitizeHTML(html)
        },

        formatDate(date) {
            let diffInSeconds = ((new Date) - date) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(date, 'H:mm')
            } else {
                return format(date, 'LLL do')
            }
        }
    }
}
</script>
