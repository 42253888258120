import { asyncResource } from '@/api'
import { content as contentApi } from '@/helpers/api-three-tables'

import { defineStore } from 'pinia'

export const useTargetsTargetContentFacebookPostSharesStore = defineStore({
    id: 'targetsTargetContentFacebookPostSharesStore',

    state: () => ({
        contentId: null,
        shares: asyncResource({
            method: 'post',
            request: (api, store, payload) => contentApi()
                .parent(store.contentId)
                .sorting('date-asc')
                .limit(25)
                .query(payload)
                .toRequest(),
            paginated: true
        }),

        totalSharesCount: null,
        unavailableSharesCount: null,
    }),

    getters: {
        lastShare(store) {
            if (store.shares.data) return store.shares.data[store.shares.data.length - 1]
        }
    },

    actions: {
        load(content) {
            this.contentId = content.id
            this.totalSharesCount = content.metrics.shares

            this.shares.fetchFirst(this)
        },

        async loadMore(infiniteScroll) {
            if (! this.lastShare) return

            let items = await this.shares.fetchNext(this)

            items.length ? infiniteScroll.loaded() : infiniteScroll.complete()

            this.unavailableSharesCount = this.totalSharesCount - (this.shares.data?.length ?? 0)
        }
    }
})

export default useTargetsTargetContentFacebookPostSharesStore
