<template>
    <div class="relative">
        <div class="w-full h-48 p-2 cursor-pointer relative" @click="showMedia">
            <img :src="image.src" class="w-full h-full object-contain" style="filter: drop-shadow(0 -8px 0 white) drop-shadow(0 8px 0 white) drop-shadow(-8px 0 0 white) drop-shadow(8px 0 0 white) drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.05));">
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'image', 'item', 'streamStore' ],

    methods: {
        showMedia() {
            this.streamStore.mediaLightbox.open([ this.image ], 0)
        }
    }
}
</script>
