import { route } from './helpers'

import filter from 'just-filter'
import wretch from 'wretch'
import { retry } from 'wretch/middlewares/retry'

import AbortAddon from 'wretch/addons/abort'
import FormDataAddon from 'wretch/addons/formData'
import QueryStringAddon from 'wretch/addons/queryString'

import useMyStore from '@/stores/me/my'
import useMyNotificationsStore from '@/stores/me/notifications'

let api = wretch()
    .addon(AbortAddon()).addon(FormDataAddon).addon(QueryStringAddon)
    .url(process.env.NODE_ENV !== 'development' ? process.env.VUE_APP_API_URL : '')
    .options({ credentials: 'omit' })
    .headers({
        'Accept': 'application/json'
    })
    .defer(w =>  w.auth(useMyStore().authToken ? `Bearer ${useMyStore().authToken}` : undefined).headers({
        'X-WORKSPACE-ID': useMyStore().currentWorkspace?.id || ''
    }))
    .errorType('json')
    .catcher(400, () => { useMyNotificationsStore().reportApiError() })
    .catcher(401, () => { useMyStore().sessionLost(); throw 'SessionLostError'; })
    .catcher(403, () => { useMyNotificationsStore().reportForbiddenError() })
    .catcher(404, () => { useMyNotificationsStore().reportApiError() })
    // .catcher(419, (err, request) => {
    //     // in case of expired token we re-do the request with a fresh token included in the response
    //     return request
    //         .headers({ 'X-CSRF-Token': err.json.token })
    //         .replay()
    //         .json()
    // })
    .catcher(500, () => { useMyNotificationsStore().reportApiError() })
    .catcher(503, () => { useMyStore().inMaintenance() })
    .catcher('SyntaxError', () => {
        // when re-doing requests because of expired csrf token we assume the response will be json, which is not always
        // true because many of our api endpoints return empty 200 response on success
    })
    .catcher('AbortError', () => {
        // we intentionally abort requests all the time, this is not an error
    })
    .middlewares([
        // automatically retry requests in case of network error
        retry({
            delayTimer: 500,
            delayRamp: (delay, attempts) => delay * attempts,
            maxAttempts: 5,
            until: (response, error) => response || (error && error.message.includes('The user aborted a request.')),
            retryOnNetworkError: true
        })
    ])

// route helper
api.route = function (name, params) {
    return this.url(route(name, params))
}

// override query string processing to handle nested objects and filter falsy values (note, only 1 level of nesting is supported)
let originalQuery = api.query
api.query = function (query, replace) {
    query = Object.entries(query).reduce((q, [ key, val ]) => {
        if (val instanceof Object && ! (val instanceof Array)) {
            Object.entries(val).forEach(([ nestedKey, val ]) => q[`${key}[${nestedKey}]`] = val)
        } else if (val) {
            q[key] = val
        }

        return q
    }, {})

    return originalQuery.apply(this, [ query, replace ])
}

// override form data processing to filter falsy values
let originalFormData = api.formData
api.formData = function (formData, recursive) {
    formData = filter(formData, (k, v) => v !== undefined && v !== null)

    return originalFormData.apply(this, [ formData, recursive ])
}

export default api

export { content, features, targets } from './helpers/api-three-tables'
export { asyncResource } from './helpers/async-resource'
