<template>
    <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:px-6 lg:px-8">
        <template v-if="store.items.data && store.items.data.length">
            <template v-for="item in store.items.data">
                <media-card :content="item" :media="media" :stream-store="store" :key="media.id" v-for="media in resolveMedia(item)"></media-card>
            </template>
        </template>

        <template v-else-if="store.items.pending">
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
        </template>
    </div>
</template>

<script>
import MediaCard from '@/components/content/stream-cards/media'

import StreamPendingPlaceholder from '../cards/pending-media-placeholder'

export default {
    components: { MediaCard, StreamPendingPlaceholder },

    methods: {
        resolveMedia(content) {
            let hiddenImageFlags = [
                'ig_post_video_thumb', 'telegram_video_thumbnail', 'telegram_video_thumbnail',
                'tiktok-ingester_video_thumbnail', 'yt_video_thumbnail', 'IG_video_thumbnail', 'vk_post_video_thumb'
            ]

            if (content.videos.length) hiddenImageFlags.push('full_picture', 'video_inline')

            return [
                ...content.images.filter(i => ! i.flag.startsWith('video_thumbnail_') && ! hiddenImageFlags.includes(i.flag)),
                ...content.videos.map(v => ({ ...v, thumbnailUrl: this.videoThumbnailUrl(content, v) }))
            ]
        },

        videoThumbnailUrl(content, video) {
            return content.images.find(i => i.flag == `video_thumbnail_${video.id}`)?.src
                || content.images[0]?.src
        }
    }
}
</script>
