<template>
    <search-filter name="Tags" :ids="['tags']" :filters="filters" @opened="reset">
        <template v-slot:applied>
            <ui-icon name="tags" class="mr-1"></ui-icon>

            <div class="inline-flex space-x-1 max-w-md">
                <div class="inline-flex items-center space-x-1 text-xs font-medium leading-4 bg-blue-50 py-0.5 min-w-0" v-if="tagsValue.length == 1">
                    <span class="inline-block shrink-0 w-3 h-3 text-xs leading-4 rounded-full" :class="`bg-${tagsValue[0].color}`"></span>
                    <span class="truncate">{{tagsValue[0].name}}</span>
                </div>
                <div class="inline-flex shrink-0 items-center leading-4" v-else>{{ tagsValue.length }} tags</div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <div class="px-4">
                    <div class="relative w-full h-8">
                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
                    </div>
                </div>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <div class="max-h-80 overflow-y-auto" v-if="tags.length">
                    <div class="text-xs font-medium text-gray-700 px-4 mb-2 cursor-pointer" @click="isSelectingGroup = ! isSelectingGroup">
                        {{ selectedGroup ? selectedGroup.name : 'All Tags' }}
                        <ui-icon :name="isSelectingGroup ? 'chevron-up' : 'chevron-down'"></ui-icon>
                    </div>

                    <template v-if="isSelectingGroup">
                        <ui-dropdown-link @click="selectGroup(null)" keep-open :selected="! selectedGroup">
                            All Tags
                        </ui-dropdown-link>
                        <ui-dropdown-link @click="selectGroup(group)" keep-open :selected="!! (selectedGroup && selectedGroup.id == group.id)" :key="group.id" v-for="group in tagGroups">
                            {{group.name}}
                        </ui-dropdown-link>
                    </template>

                    <template v-else>
                        <ui-dropdown-link checkbox :selected="selectedTags.includes(tag.id)" @click="toggleTag(tag)" :icon="true" :key="tag.id" v-for="tag in tags">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${tag.color}`"></span>
                                </div>
                            </template>
                            <template v-slot:default>
                                {{tag.name}}
                            </template>
                        </ui-dropdown-link>
                    </template>
                </div>

                <div class="h-full flex flex-col justify-center items-center text-center text-gray-600 h-full text-sm p-4" v-else>
                    <ui-icon name="search" class="text-2xl text-gray-500 mb-1"></ui-icon>
                    <p class="mb-1">No results found.</p>
                    <p class="text-xs">Please try a different search query.</p>
                </div>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <p class="text-xs text-center px-4 mb-2">
                    <template v-if="mode == 'any'">
                        Showing only content with at least one of the selected tags.
                    </template>
                    <template v-else-if="mode == 'all'">
                        Showing only content with all od the selected tags.
                    </template>
                    <template v-else-if="mode == 'not-any'">
                        Excluding content with at least one of the selected tags.
                    </template>
                    <template v-else-if="mode == 'not-all'">
                        Excluding content with all of the selected tags.
                    </template>
                </p>

                <ui-dropdown-link icon="filter" submenu submenu-align="left" submenu-width="w-64" ref="modeDropdownLink">
                    Change mode...

                    <template v-slot:submenu>
                        <div class="text-xs font-medium text-gray-700 px-4 my-2">
                            Show only content with
                        </div>
                        <ui-dropdown-link :selected="mode == 'any'" @click="setMode('any')" keep-open>
                            At least one of the selected tags
                        </ui-dropdown-link>
                        <ui-dropdown-link :selected="mode == 'all'" @click="setMode('all')" keep-open>
                            All of the selected tags
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <div class="text-xs font-medium text-gray-700 px-4 my-2">
                            Exclude content with
                        </div>
                        <ui-dropdown-link :selected="mode == 'not-any'" @click="setMode('not-any')" keep-open>
                            At least one of the selected tags
                        </ui-dropdown-link>
                        <ui-dropdown-link :selected="mode == 'not-all'" @click="setMode('not-all')" keep-open>
                            All of the selected tags
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="filters.remove('tags'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useTaggingStore from '@/stores/tagging/tagging'

import { textSearch } from '@/helpers'
import { mapState } from 'pinia'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        search: '',
        selectedTags: [],
        isSelectingGroup: false,
        mode: 'any'
    }),

    computed: {
        ...mapState(useTaggingStore, {
            selectedGroup: 'selectedGroup',
            tagGroups: 'tagGroups',

            tags(store) {
                let tags = this.search ? textSearch(this.search, store.tags, t => t.name) : store.tags

                tags = tags.sort((a, b) => a.name.localeCompare(b.name))

                let applied = tags.filter(t => this.selectedTags.includes(t.id))
                let notApplied = tags.filter(t => ! this.selectedTags.includes(t.id))

                return [ ...applied, ...notApplied ]
            }
        }),

        value() {
            return this.filters.value('tags')
        },

        tagsValue() {
            return (this.value?.tags || []).map(t => useTaggingStore().find(t))
        }
    },

    methods: {
        toggleTag(item) {
            this.selectedTags.includes(item.id)
                ? this.selectedTags.splice(this.selectedTags.findIndex(v => v === item.id), 1)
                : this.selectedTags.push(item.id)
        },

        selectGroup(group) {
            useTaggingStore().selectGroup(group)
            this.isSelectingGroup = false
        },

        setMode(mode) {
            this.mode = mode

            this.$refs.modeDropdownLink.showSubmenu = false
        },

        reset() {
            this.search = ''

            this.mode = this.value?.mode || 'any'
            this.selectedTags = [ ...(this.value?.tags || []) ].map(v => parseInt(v))

            this.$nextTick(() => this.$refs.searchInput.focus())
        },

        apply() {
            this.selectedTags.length
                ? this.filters.set('tags', { mode: this.mode, tags: this.selectedTags })
                : this.filters.remove('tags')
        }
    }
}
</script>
