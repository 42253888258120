<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-reddit-user"
        avatar-badge-tooltip="Reddit User"
        :parent-component="{ 'reddit-post': _.type }"
    >
        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'

export default {
    name: 'reddit-post-card',

    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ]
}
</script>
