import { defineStore } from 'pinia'

export const targetTypesStore = defineStore({
    id: 'targetTypes',

    getters: {
        types: store => ([
            {
                type: 'facebook-page',
                name: 'Facebook Page',
                family: 'sources',
                icon: 'badges.target-facebook-page'
            },
            {
                type: 'facebook-user',
                name: 'Facebook User',
                family: 'sources',
                icon: 'badges.target-facebook-user'
            },
            {
                type: 'facebook-group',
                name: 'Facebook Group',
                family: 'groups',
                icon: 'badges.target-facebook-group'
            },
            {
                type: 'imageboard-board',
                name: 'Imageboard',
                family: 'sources',
                icon: 'badges.target-imageboard-board'
            },
            {
                type: 'instagram-profile',
                name: 'Instagram Profile',
                family: 'sources',
                icon: 'badges.target-instagram-profile'
            },
            {
                type: 'reddit-user',
                name: 'Reddit User',
                family: 'sources',
                icon: 'badges.target-reddit-user'
            },
            {
                type: 'reddit-subreddit',
                name: 'Reddit Subreddit',
                family: 'groups',
                icon: 'badges.target-reddit-subreddit'
            },
            {
                type: 'telegram-channel',
                name: 'Telegram Channel',
                family: 'sources',
                icon: 'badges.target-telegram-channel'
            },
            {
                type: 'telegram-group',
                name: 'Telegram Group',
                family: 'groups',
                icon: 'badges.target-telegram-group'
            },
            {
                type: 'telegram-user',
                name: 'Telegram User',
                family: 'sources',
                icon: 'badges.target-telegram-user'
            },
            {
                type: 'tiktok-user',
                name: 'TikTok User',
                family: 'sources',
                icon: 'badges.target-tiktok-user'
            },
            {
                type: 'twitter-user',
                name: 'Twitter User',
                family: 'sources',
                icon: 'badges.target-twitter-user'
            },
            {
                type: 'vkontakte-community',
                name: 'VKontakte Community',
                family: 'sources',
                icon: 'badges.target-vkontakte-community'
            },
            {
                type: 'vkontakte-user',
                name: 'VKontakte User',
                family: 'sources',
                icon: 'badges.target-vkontakte-user'
            },
            {
                type: 'web-feed',
                name: 'Web Feed',
                family: 'sources',
                icon: 'badges.target-web-feed'
            },
           {
               type: 'web-site',
               name: 'Web Site',
               family: 'sources',
               icon: 'badges.target-web-site'
           },
            {
                type: 'youtube-channel',
                name: 'Youtube Channel',
                family: 'sources',
                icon: 'badges.target-youtube-channel'
            }
        ])
    },

    actions: {
        typeToHuman(type) {
            return this.types.find(t => t.type == type)?.name ?? 'Unknown Type'
        }
    }
})

export default targetTypesStore
