<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg flex flex-col text-sm px-6 py-4">
        <div class="flex items-center space-x-1 mb-3">
            <div class="shrink-0 relative">
                <a :href="$router.resolve({ name: 'targets.target.information', params: { type: content.source.family, modelType: content.source.type, id: content.source.id } }).href" target="_blank">
                    <ui-avatar :item="content.source" class="h-4 w-4"></ui-avatar>
                    <ui-icon :name="`badges.target-${content.source.type}`" style="height: 0.7em; width: 0.7em" class="absolute -right-0.5 -bottom-0.5 text-xs ring-1 ring-white rounded-full" :class="{ 'grayscale': ! content.source.monitored }"></ui-icon>
                </a>
            </div>
            <div class="font-medium truncate min-w-0">
                <a :href="$router.resolve({ name: 'targets.target.information', params: { type: content.source.family, modelType: content.source.type, id: content.source.id } }).href" v-tooltip="content.source.title" target="_blank">
                    {{ content.source.title }}
                </a>
            </div>
            <div class="truncate text-gray-700 text-2xs tracking-tight">
                <a :href="$router.resolve({ name: 'targets.target.content', params: { type: content.source.family, modelType: content.source.type, id: content.source.id, contentId: content.id } }).href" target="_blank">
                    {{ $dateTime(content.publishedAt) }}
                </a>
            </div>
        </div>

        <h1 class="font-medium" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-if="content.title" v-html="content.title"></h1>
        <div class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></div>
    </div>
</template>

<script>
export default {
    props: [ 'content' ],

    computed: {
        text() {
            return this.content.body.replace(/\n\s*\n/g, "\n").substr(0, 200) + '...'
        },

        isRTL() {
            return ['ar', 'arc', 'ha', 'khw', 'ks', 'ps', 'yi', 'az', 'dv', 'he', 'ku', 'ckb', 'fa', 'ur'].includes(this.content.language)
        }
    }
}
</script>