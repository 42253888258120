<template>
    <tr class="border-b border-gray-200 last:border-none">
        <td class="py-4 px-6">
            <div class="flex items-center">
                <ui-avatar :name="list.name" family="curated-lists" class="text-4xl"></ui-avatar>
                <div class="ml-3">
                    <div class="font-semibold">{{list.name}}</div>
                    <div class="text-gray-700 text-xs font-medium">
                        {{list.sourcesCount}} {{list.sourcesCount == 1 ? 'source' : 'sources'}}
                        ·
                        {{list.groupsCount}} {{list.groupsCount == 1 ? 'group' : 'groups'}}
                    </div>
                </div>
            </div>
        </td>
        <td class="py-4 px-6 text-sm text-gray-700 font-medium">
            {{list.description}}
        </td>
        <td class="py-4 px-6 w-32">
            <div class="flex justify-center space-x-3">
                <subscribe-button :list="list"></subscribe-button>
                <ui-button light :to="{ name: 'targets.my-lists.list', params: { listId: list.id, type: 'sources' } }">Preview</ui-button>
            </div>
        </td>
    </tr>
</template>

<script>
import SubscribeButton from '@/components/targets/curated-lists/subscribe-button'

export default {
    props: [ 'list', 'to' ],

    components: { SubscribeButton }
}
</script>
