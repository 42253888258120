<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-tiktok-user"
        avatar-badge-tooltip="TikTok User"
        :parent-component="{ 'tiktok-video': TiktokVideo, 'tik-tok-video-comments': _.type }"
    >
    </content-card>
</template>

<script>
import ContentCard from './content'
import TiktokVideo from './tiktok-video'

import { markRaw } from 'vue'

export default {
    components: { ContentCard },

    data: () => ({
        TiktokVideo: markRaw(TiktokVideo)
    }),

    props: [ 'content', 'mode', 'streamStore' ]
}
</script>
