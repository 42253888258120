import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyTargetListsStore from '@/stores/me/target-lists'

export const useTargetsModalsListDuplicateStore = defineForm({
    id: 'targetsModalsListDuplicate',

    inputs: () => ({
        list: {},
        name: null
    }),

    submitRequest() {
        trackEvent('targets', 'duplicated-target-list-saved')

        return api.route('me lists duplicate', { id: this.inputs.list.id })
            .json({ name: this.inputs.name })
            .post()
    },

    async onResponse(res) {
        useMyTargetListsStore().reload()

        useModal().hide('targets-list-duplicate')
    },

    actions: {
        open(list = {}) {
            this.reset()

            this.inputs.list = list
            this.inputs.name = `${list.name} (copy)`

            useModal().show('targets-list-duplicate')
        },

        cancel() {
            useModal().hide('targets-list-duplicate')
        }
    }
})

export default useTargetsModalsListDuplicateStore
