<template>
    <ui-modal name="content-smart-tag" title="Smart Tag" :max-width="previewTask ? '800px' : '420px'">
        <ui-form :store="contentModalsSmartTagStore" v-slot="{ inputs, submitting, errors }">
            <template v-if="! previewTask">
                <div class="text-center">
                    This feature uses AI to apply tags to content based on an answer to a question. Please specify a question that can be answered with "yes" or "no".
                </div>

                <div class="mt-5">
                    <label for="question" class="block text-sm text-gray-700 font-medium mb-1">
                        Question
                    </label>

                    <div>
                        <ui-input type="text" name="question" id="name" placeholder="Does this content mention a military conflict?" v-model="inputs.question" :errors="errors.question"></ui-input>
                    </div>
                </div>

                <div class="flex space-x-6 mt-4">
                    <div class="flex-1">
                        <label for="question" class="block text-sm text-gray-700 font-medium mb-1">
                            Yes answer
                        </label>

                        <div>
                            <tags-dropdown v-model="inputs.yesTag"></tags-dropdown>
                        </div>
                    </div>

                    <div class="flex-1">
                        <label for="question" class="block text-sm text-gray-700 font-medium mb-1">
                            No answer
                        </label>

                        <div>
                            <tags-dropdown v-model="inputs.noTag"></tags-dropdown>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <a @click.prevent="showAdvanced = ! showAdvanced" href="#" class="text-sm text-gray-700 font-medium">
                        Advanced <ui-icon :name="showAdvanced ? 'chevron-down' : 'chevron-right'"></ui-icon>
                    </a>

                    <div class="mt-2" v-if="showAdvanced">
                        <label for="context" class="block text-sm text-gray-700 font-medium mb-1">
                            Context
                        </label>

                        <div>
                            <ui-input type="textarea" rows="2" name="context" id="context" placeholder="Military conflict refers to any use of military forces." v-model="inputs.context" :errors="errors.context"></ui-input>
                        </div>

                        <div class="text-sm text-gray-600 mt-1">
                            Use this text field to specify any additional context to your question. This can help to make the answers more accurate.
                        </div>
                    </div>
                </div>

                <ui-async :guards="itemsCount && $my.budgets">
                    <div class="px-5 py-3 bg-gray-50 rounded-lg mt-4 flex items-center gap-x-4">
                        <ui-icon name="disc" class="text-gray-400 text-2xl"></ui-icon>
                        <div class="flex-1">
                            <div class="flex gap-x-6">
                                <div>
                                    <div class="font-medium text-gray-800">
                                        {{$my.budgets.smartTags.limit - $my.budgets.smartTags.usage}}
                                    </div>
                                    <div class="text-xs text-gray-700 font-medium leading-tight">
                                        Budget
                                    </div>
                                </div>
                                <div>
                                    <div class="font-medium text-red-700">
                                        {{itemsCount}}
                                    </div>
                                    <div class="text-xs text-gray-700 font-medium leading-tight">
                                        Spending
                                    </div>
                                </div>
                                <div class="ml-auto">
                                    <div class="font-medium text-blue-700" :class="hasBudgetAvailable ? 'text-blue-700' : 'text-red-700'">
                                        {{$my.budgets.smartTags.limit - $my.budgets.smartTags.usage - itemsCount}}
                                    </div>
                                    <div class="text-xs text-gray-700 font-medium leading-tight">
                                        Remaining
                                    </div>
                                </div>
                                <div>
                                    <ui-icon name="help-circle" class="text-lg text-blue-600 hover:text-blue-900" v-tooltip="`Your current budget is ${$my.budgets.smartTags.limit} Smart Tags a month.`"></ui-icon>
                                </div>
                            </div>
                            <div v-if="hasBudgetAvailable" class="mt-1.5">
                                <div class="bg-gray-100 h-1 w-full rounded-full overflow-hidden flex">
                                    <div class="bg-blue-500 h-1" :style="`width:${$my.budgets.smartTags.usage / $my.budgets.smartTags.limit * 100}%;`"></div>
                                    <div class="bg-red-500 h-1" :style="`width:${itemsCount / $my.budgets.smartTags.limit * 100}%;`"></div>
                                </div>
                            </div>
                            <div v-else class="mt-1.5 text-red-500 text-sm font-medium">
                                <ui-icon name="alert-triangle"></ui-icon>
                                Insufficient budget.
                            </div>
                        </div>
                    </div>
                </ui-async>

                <div class="text-center text-sm text-gray-600 mt-4">
                    We will show you a preview of the applied tag, you will be able to go back to tweak your question.
                </div>

                <div class="flex justify-center mt-6 mb-2">
                    <ui-button type="submit" color="blue" :disabled="! hasBudgetAvailable || submitting">
                        Preview
                    </ui-button>
                </div>
            </template>

            <template v-else>
                <div>
                    <label class="block text-sm text-gray-700 font-medium mb-1">
                        Question
                    </label>

                    <div>
                        {{inputs.question}}
                    </div>
                </div>

                <div class="h-[50vh] overflow-y-scroll mt-4">
                    <div class="flex gap-x-5" v-if="previewTask.state == 'completed'">
                        <div class="flex-1 min-w-0">
                            <h2 class="block text-sm text-gray-700 font-medium mb-1">Yes answer</h2>

                            <ui-async :guards="yesContent.data" class="space-y-2">
                                <simple-content-card v-for="content in yesContent.data" :key="content.id" :content="content"></simple-content-card>
                            </ui-async>
                        </div>
                        <div class="flex-1 min-w-0">
                            <h2 class="block text-sm text-gray-700 font-medium mb-1">No answer</h2>

                            <ui-async :guards="noContent.data" class="space-y-2">
                                <simple-content-card v-for="content in noContent.data" :key="content.id" :content="content"></simple-content-card>
                            </ui-async>
                        </div>
                    </div>

                    <div class="h-full flex flex-col items-center justify-center space-y-3" v-else>
                        <ui-spinner class="text-3xl" type="clip"></ui-spinner>
                        <div>Loading Smart Tag preview...</div>
                    </div>
                </div>

                <div class="text-center text-sm text-gray-600 mt-6">
                    Applying the Smart Tag might take a few minutes, based on the amount of the content.
                </div>

                <div class="flex justify-center mt-6 mb-2 space-x-5">
                    <ui-button type="button" color="gray" :disabled="submitting" @click="back">
                        Back
                    </ui-button>

                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Apply
                    </ui-button>
                </div>
            </template>
        </ui-form>
    </ui-modal>
</template>

<script>
import SimpleContentCard from '@/components/content/stream-cards/simple-content'
import TagsDropdown from './partials/tags-dropdown'

import useContentModalsSmartTagStore from '@/stores/content/modals/smart-tag'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        SimpleContentCard, TagsDropdown
    },
    
    computed: {
        ...mapStores(useContentModalsSmartTagStore),
        ...mapState(useContentModalsSmartTagStore, [ 'hasBudgetAvailable', 'itemsCount', 'previewTask', 'yesContent', 'noContent' ])
    },

    data: () => ({
        showAdvanced: false
    }),

    methods: {
        ...mapActions(useContentModalsSmartTagStore, [ 'back' ])
    }
}
</script>
