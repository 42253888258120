<template>
    <div class="flex flex-col bg-white border border-gray-200 shadow-sm rounded-md mb-4" v-if="($page.target.monitored || $page.target.metrics.contentCount) && $page.type == 'sources'">
        <div class="flex justify-between items-center px-6 py-4">
            <h3 class="font-semibold text-gray-900">
                Heatmap
            </h3>

            <ui-dropdown align="right" width="w-48">
                <template v-slot:trigger>
                    <button class="flex items-center text-gray-700 text-sm hover:text-gray-900 focus:outline-none hover:bg-gray-50 focus:text-gray-900 transition px-3 py-1 rounded-full" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                        {{$page.selectedHeatmap}}
                        <ui-icon name="chevron-down" class="ml-1"></ui-icon>
                    </button>
                </template>

                <template v-slot:content>
                    <ui-dropdown-link @click="$page.selectHeatmap('hourly')" :selected="$page.selectedHeatmap == 'Hourly'">
                        Hourly
                    </ui-dropdown-link>
                    <ui-dropdown-link @click="$page.selectHeatmap('daily')" :selected="$page.selectedHeatmap == 'Daily'">
                        Daily
                    </ui-dropdown-link>
                </template>
            </ui-dropdown>
        </div>

        <div class="w-full px-4 py-4">
            <hourly-heatmap :store="targetsTargetInformationHeatmapStore.heatmapOptions" v-if="$page.selectedHeatmap == 'Hourly'"></hourly-heatmap>
            <daily-heatmap :store="targetsTargetInformationHeatmapStore.heatmapOptions" v-if="$page.selectedHeatmap == 'Daily'"></daily-heatmap>
        </div>
    </div>
</template>

<script>
import HourlyHeatmap from '@/components/analysis/analyses/heatmaps/hourly'
import DailyHeatmap from '@/components/analysis/analyses/heatmaps/daily'

import useTargetsTargetInformationHeatmapStore from '@/stores/targets/target/information-heatmap'

import { mapStores } from 'pinia'

export default {
    components: {
        DailyHeatmap, HourlyHeatmap
    },

    computed: {
        ...mapStores(
            useTargetsTargetInformationHeatmapStore
        )
    }
}
</script>
