<template>
    <div>
        <transition-group
            enter-active-class="transition duration-100 ease-out"
            enter-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
        >
            <div v-if="expiringLicense" class="flex items-center justify-center border-b border-red-600 px-6 py-1.5 font-medium bg-gradient-to-br from-red-700 to-red-500 text-white text-sm">
                <p v-if="expiringLicense.trial">
                    Your trial is ending in <span class="font-bold">{{expiringIn}}</span> days. Please <a href="mailto:contact@gerulata.com" class="underline">contact us</a> for options.
                </p>
                <p v-else>
                    Your license is expiring in <span class="font-bold">{{expiringIn}}</span> days. Please <a href="mailto:contact@gerulata.com" class="underline">contact us</a> for options.
                </p>
            </div>

            <div v-else-if="trialLicense" class="flex items-center justify-center border-b border-fuchsia-600 px-6 py-1.5 font-medium bg-gradient-to-br from-fuchsia-700 to-fuchsia-500 text-white text-sm">
                <p>
                    Your trial is available for another <span class="font-bold">{{trialExpiringIn}}</span> days.
                </p>
            </div>

            <div v-for="item in activeItems" :key="item.id"
                 class="flex items-center justify-between border-b px-6 py-3 font-medium" :class="warningClasses(item)">
                <p>{{ item.body }}</p>
                <a href="#" @click.prevent="close(item)" class="shrink-0 ml-3">
                    <ui-icon name="x"></ui-icon>
                </a>
            </div>
        </transition-group>
    </div>
</template>

<script>
import useMyWarningsStore from '@/stores/me/warnings'

import { sortByDate } from '@/helpers'

import { differenceInDays, parseISO } from 'date-fns'
import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useMyWarningsStore, [ 'activeItems' ]),

        trialLicense() {
            return sortByDate([ ...this.$my.user.organization.licenses ], 'validTo').find(l => l.trial)
        },

        trialExpiringIn() {
            return this.trialLicense ? differenceInDays(parseISO(this.trialLicense.validTo), new Date()) : null
        },

        expiringLicense() {
            return sortByDate([ ...this.$my.user.organization.licenses ], 'validTo').find(l => differenceInDays(parseISO(l.validTo), new Date()) < 14)
        },

        expiringIn() {
            return this.expiringLicense ? differenceInDays(parseISO(this.expiringLicense.validTo), new Date()) : null
        }
    },

    methods: {
        ...mapActions(useMyWarningsStore, [ 'close' ]),

        warningClasses(warning) {
            return {
                'bg-green-300 text-green-900': warning.type == 'success',
                'bg-blue-100 text-blue-900': warning.type == 'notice',
                'bg-amber-200 text-amber-900': warning.type == 'warning',
                'bg-red-200 text-red-900': warning.type == 'danger'
            }
        }
    }
}
</script>