<template>
    <ui-async :guards="$page.list">
        <ui-header :title="$page.list.name || 'My List'" avatar-type="target-list">
            <template v-slot:actions-start>
                <div class="flex items-center space-x-1.5 ml-3" v-if="! isCurated($page.list)">
                    <a href="#" class="inline-flex items-center justify-center transition text-base" :class="{ 'text-blue-600 hover:text-blue-900': $page.list.pinned, 'text-gray-400 hover:text-gray-900': ! $page.list.pinned }" @click.prevent="updateList($page.list, { favorite: $page.list.pinned ? 0 : 1 })" v-tooltip="$page.list.pinned ? 'Un-pin' : 'Pin'">
                        <ui-icon :name="$page.list.pinned ? 'pin-on' : 'pin-off'"></ui-icon>
                    </a>
                </div>

                <ui-contextual-menu plain color="black" class="ml-2 mt-1" z-index="60">
                    <template v-if="isCurated($page.list)">
                        <ui-dropdown-link icon="subscription" @click="subscribeToList($page.list)" v-if="! findList($page.list.id)">
                            Subscribe
                        </ui-dropdown-link>
                        <ui-dropdown-link icon="subscription" @click="unsubscribeFromList($page.list)" v-else>
                            Unsubscribe
                        </ui-dropdown-link>
                    </template>

                    <template v-else>
                        <ui-dropdown-link icon="pin-off" @click="updateList($page.list, { favorite: 1 })" v-if="! $page.list.pinned">
                           Pin
                        </ui-dropdown-link>
                        <ui-dropdown-link icon="pin-off" @click="updateList($page.list, { favorite: 0 })" v-if="$page.list.pinned">
                           Un-pin
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="settings" @click="editList($page.list)">
                           Settings
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="merge" @click="mergeList($page.list, true)">
                           Merge
                        </ui-dropdown-link>
                        <ui-dropdown-link icon="copy" @click="duplicateList($page.list)">
                           Duplicate
                        </ui-dropdown-link>
                    </template>

                    <template v-if="can('manage-world')">
                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="wrench" @click="showDebug($page.list)">
                            Debug
                        </ui-dropdown-link>
                    </template>
                </ui-contextual-menu>
            </template>

            <template v-slot:actions>
                <template v-if="isCurated($page.list)">
                    <ui-button v-if="isCurated($page.list) && ! isSubscribedByParent($page.list)" color="blue" light :action="() => subscribeToList($page.list)">
                        Subscribe
                    </ui-button>

                    <ui-button v-if="isCurated($page.list) && isSubscribedByParent($page.list)" color="blue" :action="() => unsubscribeFromList($page.list)">
                        Unsubscribe
                    </ui-button>

                    <ui-button v-if="! isCurated($page.list) && isSubscribedByChild($page.list)" color="blue" :action="() => unsubscribeFromList($page.list.parentList)">
                        Unsubscribe
                    </ui-button>
                </template>

                <template v-else>
                    <ui-button color="blue" icon="plus-square" :action="addTarget">
                        Add Target
                    </ui-button>
                </template>
            </template>

            <template v-slot:subsection>
                <stream-toolbar :store="$page.streamStore" :full-width="true" class="pt-3.5"></stream-toolbar>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-12xl mx-auto">
            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="$page.streamStore.items.data && ! $page.streamStore.items.data.pending && ! $page.streamStore.items.data.length && ! isCurated($page.list)">
                <ui-icon name="navigation.sub.target-lists" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to your new List!</h1>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Targets</h2>
                        <p class="text-justify my-2">Click on the "Add Target" button to add Targets to this list. You can also use the three-dots contextual menu in various parts of the app.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Suggestions</h2>
                        <p class="text-justify my-2">Click on the "Suggestions" button to get suggested new targets to add, based on the current List contents.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Search</h2>
                        <p class="text-justify my-2">Use your new list to show only content from included Targets in the Content search or as a dataset in the Analysis section.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can <span class="font-medium">add your first target</span>.
                </p>
                <div class="flex items-center space-x-3 mt-8">
                    <ui-button :action="openImportingModal" color="blue" size="lg" icon="upload">
                        Import Targets
                    </ui-button>

                    <ui-button :action="addTarget" color="blue" size="lg" icon="plus-square">
                        Add Target
                    </ui-button>
                </div>
            </div>

            <template v-else>
                <ui-stream :store="$page.streamStore">
                    <template v-slot:results-actions>
                        <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                            <a @click.prevent="$page.showSuggestions = ! $page.showSuggestions" href="#" class="flex items-center justify-center px-3" :class="{ 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]': $page.showSuggestions }" v-tooltip="'Show more suggested targets to add'">
                                Suggestions
                            </a>
                        </div>
                    </template>

                    <template v-slot:above-results>
                        <template v-if="$page.showSuggestions">
                            <div class="py-4 text-center space-y-2 text-sm text-gray-800 border-b border-gray-200 mb-12 pb-12" v-if="suggestionsError">
                                <p>We were unable to load suggestions for this List.</p>
                                <p>
                                    <a href="#" @click="reloadSuggestions()" class="text-blue-500 hover:text-blue-700">Retry</a>
                                </p>
                            </div>

                            <ui-cards-section title="Suggested Targets" :store="targetsListSuggestionsCardsStore" class="border-b border-gray-200 mb-12 pb-12" v-else>
                                <template v-slot:item="{ item, cardsStore }">
                                    <target-card :target="item" :list="$page.list" :cards-store="cardsStore" :mode="cardsStore.layout.id == 'table' ? 'row' : 'card'"></target-card>
                                </template>
                            </ui-cards-section>
                        </template>
                    </template>

                    <template v-slot:item="{ item, layout }">
                        <target-card :target="item" :list="$page.list" :cards-store="targetsListDummyCardsStore" :mode="layout.id == 'table' ? 'row' : 'card'"></target-card>
                    </template>

                    <template v-slot:placeholder="{ layout }">
                        <target-placeholder-card v-if="layout.id == 'grid'"></target-placeholder-card>
                        <target-placeholder-row v-if="layout.id == 'table'"></target-placeholder-row>
                    </template>
                </ui-stream>
            </template>
        </div>

        <Teleport to="#root">
            <targets-list-detach-confirmation-modal></targets-list-detach-confirmation-modal>
            <import-options-modal :stream-store="$page.streamStore"></import-options-modal>
        </Teleport>
    </ui-async>
</template>

<script>
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import TargetCard from './stream-cards/target'
import TargetPlaceholderCard from './stream-cards/target-placeholder'
import TargetPlaceholderRow from './stream-cards/target-placeholder-row'
import TargetsListDetachConfirmationModal from './modals/list-detach-confirmation'
import UiCardsSection from '@/components/ui/cards/cards-section'
import ImportOptionsModal from '@/components/targets/modals/list-import-targets'

import useMyTargetListsStore from '@/stores/me/target-lists'
import useTargetsListDummyCardsStore from '@/stores/targets/list-dummy-cards'
import useTargetsListSuggestionsStore from '@/stores/targets/list-suggestions'
import useTargetsListSuggestionsCardsStore from '@/stores/targets/list-suggestions-cards'
import useTargetsModalsListAddTargetStore from '@/stores/targets/modals/list-add-target'
import useTargetsModalsListDuplicateStore from '@/stores/targets/modals/list-duplicate'
import useTargetsModalsListEditStore from '@/stores/targets/modals/list-edit'
import useTargetsModalsListMergeStore from '@/stores/targets/modals/list-merge'
import useModalsListImportTargetsStore from '@/stores/targets/modals/list-import-targets'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        StreamToolbar, TargetCard, TargetPlaceholderCard, TargetPlaceholderRow,
        TargetsListDetachConfirmationModal, UiCardsSection, ImportOptionsModal
    },

    computed: {
        ...mapStores(useTargetsListDummyCardsStore, useTargetsListSuggestionsCardsStore),
        ...mapState(useTargetsListSuggestionsStore, { suggestionsError: 'error' })
    },

    methods: {
        ...mapActions(useMyTargetListsStore, {
            'findList': 'find',
            'isCurated': 'isCurated',
            'isSubscribedByParent': 'isSubscribedByParent',
            'isSubscribedByChild': 'isSubscribedByChild',
            'subscribeToList': 'subscribeToList',
            'unsubscribeFromList': 'unsubscribeFromList',
            'updateList': 'update'
        }),
        ...mapActions(useTargetsListSuggestionsStore, { reloadSuggestions: 'reload' }),
        ...mapActions(useTargetsModalsListAddTargetStore, { openAddTargetModal: 'open' }),
        ...mapActions(useTargetsModalsListDuplicateStore, { 'duplicateList': 'open' }),
        ...mapActions(useTargetsModalsListEditStore, { 'editList': 'open' }),
        ...mapActions(useTargetsModalsListMergeStore, { 'mergeList': 'open' }),
        ...mapActions(useModalsListImportTargetsStore, { openImportingModal: 'open' } ),

        addTarget() {
            this.openAddTargetModal(this.$page.list)
        }
    }
}
</script>
