<template>
    <div class="px-8 flex justify-center space-x-4 max-w-7xl mx-auto">
        <div class="w-1/3 space-y-4">
            <details-card :target="$page.target"></details-card>

            <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[targets]': `${$page.target.family == 'groups' ? 'group' : 'source'}|${$page.target.id}|${$page.target.title}` } }">
                <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-md h-20 flex items-center mt-4 px-6 text-xl text-blue-600 hover:text-blue-800">
                    <ui-icon name="search" class="mr-3 text-3xl"></ui-icon>
                    <div>
                        <div class="font-medium leading-tight">Content Stream</div>
                        <div class="text-sm">Search for all content by this target</div>
                    </div>
                    <ui-icon name="chevron-right" class="ml-auto"></ui-icon>
                </div>
            </router-link>

            <history-card :compact="true"></history-card>
            <connections-card v-if="$page.type == 'sources'"></connections-card>
            <top-groups-card></top-groups-card>
            <top-sources-card></top-sources-card>
        </div>

        <div class="w-2/3">
            <mirror-card></mirror-card>
            <inactive-card></inactive-card>
            <activity-card></activity-card>
            <statistics-card></statistics-card>
            <heatmap-card></heatmap-card>
            <top-posts-section></top-posts-section>
        </div>

        <Teleport to="#root">
            <stream-lightbox :store="targetsTargetInformationLightboxStore"></stream-lightbox>
            <stream-lightbox :store="contentDetailMediaLightbox"></stream-lightbox>
        </Teleport>
    </div>
</template>

<script>
import ActivityCard from './information/activity-card'
import ConnectionsCard from './information/connections-card'
import DetailsCard from './information/details-card'
import HeatmapCard from './information/heatmap-card'
import HistoryCard from './history/history-card'
import InactiveCard from './information/inactive-card'
import MirrorCard from './information/mirror-card'
import StatisticsCard from './information/statistics-card'
import StreamLightbox from '@/components/ui/stream/lightbox/lightbox'
import TopGroupsCard from './information/top-groups-card'
import TopPostsSection from './information/top-posts-section'
import TopSourcesCard from './information/top-sources-card'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'
import useTargetsTargetInformationLightboxStore from '@/stores/targets/target/information-lightbox'

import { mapState, mapStores } from 'pinia'

export default {
    components: {
        ActivityCard, ConnectionsCard, DetailsCard, HeatmapCard, HistoryCard, InactiveCard, MirrorCard, StatisticsCard,
        StreamLightbox, TopGroupsCard, TopSourcesCard, TopPostsSection
    },

    computed: {
        ...mapStores(
            useTargetsTargetInformationLightboxStore
        ),
        ...mapState(useContentModalsContentDetailStore, { contentDetailMediaLightbox: 'mediaLightbox' })
    }
}
</script>
