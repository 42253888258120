<template>
    <dashboard-widget v-bind="$attrs" :store="store" unstyled>
        <div class="flex flex-col h-full">
            <div class="flex justify-between mb-1 px-2">
                <h1 class="text-lg font-medium">{{store.title}}</h1>
            </div>

            <div class="flex-1 overflow-y-auto">
                <overview-stream :store="store.streamStore()" style="width:100% !important; height: 100% !important;"></overview-stream>
            </div>
        </div>

        <template v-slot:actions>
            <ui-dropdown-link @click="store.selectPerspective()" icon="perspective">Select perspective...</ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="list" submenu>
                Order by
                <template v-slot:submenu>
                    <ui-dropdown-link @click="store.setSorting('date-desc')" :selected="store.sorting == 'date-desc'" icon="calendar">Latest</ui-dropdown-link>
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link @click="store.setSorting('interactions-desc')" :selected="store.sorting == 'interactions-desc'" icon="interactions">Most Interactions</ui-dropdown-link>
                    <ui-dropdown-link @click="store.setSorting('trending-desc')" :selected="store.sorting == 'trending-desc'" icon="fire">Trending</ui-dropdown-link>
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link @click="store.setSorting('reactions-desc')" :selected="store.sorting == 'reactions-desc'" icon="thumbs-up">Most Reactions</ui-dropdown-link>
                    <ui-dropdown-link @click="store.setSorting('comments-desc')" :selected="store.sorting == 'comments-desc'" icon="comment">Most Comments</ui-dropdown-link>
                    <ui-dropdown-link @click="store.setSorting('shares-desc')" :selected="store.sorting == 'shares-desc'" icon="share">Most Shares</ui-dropdown-link>
                    <ui-dropdown-link @click="store.setSorting('views-desc')" :selected="store.sorting == 'views-desc'" icon="eye">Most Views</ui-dropdown-link>
                </template>
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>
        </template>
    </dashboard-widget>
</template>

<script>
import DashboardWidget from './widget'

import OverviewStream from '@/components/content/perspective/overview/widgets/stream'

export default {
    components: { DashboardWidget, OverviewStream }
}
</script>
