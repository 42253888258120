<!-- This filter does not extends generic search-filter as it has a more "custom" UI instead of the standard dropdown -->

<template>
    <div>
        <a href="#" class="block" @click.prevent="toggleSemanticSearch">
            <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-4 bg-blue-50 text-blue-600 rounded max-w-full" v-if="showSemanticSearch">
                <ui-icon name="ai" class="mr-1"></ui-icon>
                Semantic
            </div>

            <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-4 hover:bg-gray-50 text-gray-900 rounded max-w-full" v-else>
                Semantic
            </div>
        </a>

        <Teleport to="#top-filters" v-if="showSemanticSearch">
            <div class="mt-3">
                <form class="w-full relative" @submit.prevent="apply">
                    <div class="absolute top-0 left-4 h-9 flex items-center pointer-events-none text-base z-20">
                        <ui-icon name="ai" class="text-gray-600"></ui-icon>
                    </div>

                    <input type="text" autocomplete="off" spellcheck="false" placeholder="Semantic search..." class="block w-full h-9 pl-10 pr-28 rounded-t-sm text-gray-900 placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 sm:text-sm border-0 z-30 text-gray-900 bg-gray-100" v-model="query">

                    <div class="absolute top-0 right-4 h-9 flex items-center text-xs z-20">
                        <div class="tracking-tight">Loose</div>

                        <input type="range" min="0" max="100" step="5" class="w-32 h-1 mx-3" v-model="sensitivity">

                        <div class="tracking-tight">Strict</div>

                        <div class="ml-3 py-1 px-1.5 border text-2xs rounded leading-none uppercase border-gray-400 text-gray-500 cursor-default">
                            {{ sensitivity }}
                        </div>
                    </div>
                </form>
            </div>
        </Teleport>
    </div>
</template>

<script>
import debounce from 'just-debounce-it'

export default {
    props: [ 'filters', 'streamStore' ],

    data: () => ({
        showSemanticSearch: false,
        query: '',
        sensitivity: 50
    }),

    computed: {
        value() {
            return this.filters.value('semantic')
        }
    },

    methods: {
        apply() {
            this.filters.set('semantic', { query: this.query, sensitivity: this.sensitivity })
            this.streamStore.setSorting('ids')
        },

        toggleSemanticSearch() {
            if (! this.showSemanticSearch) {
                this.showSemanticSearch = true
            } else {
                this.showSemanticSearch = false
                this.query = ''
                this.sensitivity = 50

                this.filters.remove('semantic')
                if (this.streamStore.sorting.id == 'ids') this.streamStore.setSorting('latest')
            }
        }
    },

    watch: {
        value(value) {
            if (value) {
                this.showSemanticSearch = true
                this.query = value.query
                this.sensitivity = value.sensitivity
            } else {
                this.showSemanticSearch = false
                this.query = ''
                this.sensitivity = 50
            }
        },

        sensitivity: debounce(function (sensitivity, old) {
            if (this.value && sensitivity != old) { this.apply() }
        }, 150)
    }
}
</script>
