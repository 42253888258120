<template>
    <div class="mb-16" v-if="! store.isInitialized || store.items.length">
        <results-toolbar :title="title" :store="store"></results-toolbar>

        <ui-cards :store="store">
            <template v-slot:item="{ item, cardsStore }">
                <slot name="item" :item="item" :cards-store="cardsStore"></slot>
            </template>
        </ui-cards>
    </div>
</template>

<script>
import ResultsToolbar from '@/components/ui/cards/toolbar/results'

export default {
    components: {
        ResultsToolbar
    },

    props: [ 'title' ]
}
</script>
