<template>
    <ui-modal name="perspective-analyze" title="Analyze" max-width="560">
        <div class="space-y-1 text-gray-800 w-full">
            <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer text-blue-500 hover:text-blue-800 bg-gray-50 hover:bg-blue-50 rounded-lg text-lg" @click.prevent="store.analyze(analysis.id)" :key="analysis.id" v-for="analysis in analyses">
                <div class="pr-3">
                    <h1 class="text-lg font-medium">{{analysis.name}}</h1>
                    <p class="text-sm">{{analysis.description}}</p>
                </div>
                <div class="ml-auto">
                    <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                </div>
            </a>
        </div>
    </ui-modal>
</template>

<script>
export default {
    data: () => ({
        analyses: [
            {
                id: 'content-performance',
                name: 'Performance',
                description: 'Compares published content count, prevalence, interactions and other metrics over time.'
            },
            {
                id: 'content-breakdown-by-source',
                name: 'Top Sources',
                description: 'Detailed breakdown of top sources publishing content in the data-set.'
            },
            {
                id: 'content-breakdown-by-group',
                name: 'Top Groups',
                description: 'Detailed breakdown of top groups publishing content in the data-set.'
            },
            {
                id: 'content-top-platforms',
                name: 'Top Platforms',
                description: 'Detailed breakdown of top platforms where content was published in the data-set.'
            },
//            {
//                id: 'content-breakdown-by-topic',
//                name: 'Topic Modelling',
//                description: 'Visualize and compare break-down of published content by it\'s detected topic.'
//            },
            {
                id: 'content-publish-times',
                name: 'Publish Times',
                description: 'Visualizes the posting activity by day or hour using a heatmap.'
            },
            {
                id: 'content-pull-push',
                name: 'Pull-Push',
                description: 'Visualizes the relationship between the prevalence of published content and engagement over time.'
            },
            {
                id: 'content-sentiment',
                name: 'Sentiment',
                description: 'Shows how the sentiment of published content or its replies changes over time.'
            },
            {
                id: 'content-keywords',
                name: 'Keywords',
                description: 'Generates a keyword cloud of the most common keyword mentioned in the content.'
            }
        ]
    })
}
</script>
