<template>
    <div class="bg-yellow-100 last:rounded-b-lg divide-y divide-amber-200" v-if="item.notes?.length">
        <div class="flex gap-x-1 text-xs text-zinc-900 py-1.5 px-5 cursor-pointer" v-for="note in item.notes" :key="note.id" @click.prevent="open(item, note)">
            <div class="flex-1">
                <span class="italic">
                    {{render(note.body)}}
                </span>
                <span class="text-2xs pl-1 whitespace-nowrap">
                    &mdash; {{note.author.name}}
                </span>
            </div>
            <div>
                <ui-access-icon :workspace="note.workspace" class="text-2xs text-zinc-500"></ui-access-icon>
            </div>
        </div>
    </div>
</template>

<script>
import useMeModalsNoteStore from '@/stores/me/modals/note'

import { removeHTML } from '@/helpers'

import { marked } from 'marked'
import { mapActions } from 'pinia'

export default {
    props: [ 'item' ],

    methods: {
        ...mapActions(useMeModalsNoteStore, [ 'open' ]),

        render(body) {
            let rendered = removeHTML(marked.parse(body))

            return rendered.length > 120 ? `${rendered.substring(0, 120)}...` : rendered 
        }
    }
}
</script>
