<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Targets
    </h1>

    <navigation-section
        :items="topNavigation">
    </navigation-section>

    <navigation-section title="Pinned & Recent"
        :items="pinnedAndRecent" :wait-until="areListsInitialized && areRecentTargetsInitialized" :max-shown="5"
        :to="item => ({ name: 'targets.my-lists.list', params: { listId: item.id, type: 'sources' } })"
        :active="item => false"
        placeholder="Nothing pinned or recently visited yet..."
    >
    </navigation-section>

    <navigation-section title="Bookmarks"
        :items="bookmarks" :wait-until="areBookmarksInitialized" :max-shown="5"
        :to="item => ({ name: 'targets.target.information', params: { type: item.family, modelType: item.type, id: item.id } })"
        :active="item => false"
        placeholder="No Bookmarks yet...">
        <template v-slot:image="{ item }">
            <span class="relative inline-flex mr-2 h-5 w-5 shrink-0 items-center justify-center leading-4 transition ease-in-out duration-150 text-blue-600">
                <img :src="item.avatar?.imageUrl || item.image" class="h-full w-full rounded-full">
                <ui-icon :name="`badges.target-${item.type}`" v-tooltip="typeToHuman(item.type)" style="height: 1.05em; width: 1.05em" class="absolute -right-1 -bottom-0.5 text-xs ring-1 ring-white rounded-full" :class="{ 'grayscale': ! item.monitored }"></ui-icon>
            </span>
        </template>
    </navigation-section>

    <navigation-section title="Library"
        :items="libraryTargets" :show-count="false"
        :to="item => ({ name: 'targets.library.search', params: { type: [ 'facebook-group', 'telegram-group' ].includes(item.type) ? 'groups' : 'sources' }, query: { 'filter[type]': item.type } })"
        :active="item => false">
    </navigation-section>
</template>

<script>
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'
import useTargetsTargetTypesStore from '@/stores/targets/target-types'

import { sortNaturally } from '@/helpers'

import { mapActions, mapState } from 'pinia'

export default {
    data: () => ({
        topNavigation: [
            { name: 'Overview', icon: 'navigation.sub.overview', to: { name: 'targets' } },
            { name: 'Bookmarks', icon: 'navigation.sub.bookmarks', to: { name: 'targets.bookmarks' } },
            { name: 'My Lists', icon: 'navigation.sub.target-lists', to: { name: 'targets.my-lists' } },
            { name: 'My Targets', icon: 'navigation.targets', to: { name: 'targets.my-targets' } },
            { name: 'Curated Lists', icon: 'navigation.sub.curated-lists', to: { name: 'targets.curated-lists' } }
        ]
    }),

    computed: {
        pinnedAndRecent() {
            let items = [
                ...this.lists.map(item => ({
                    ...item, avatarType: 'target-list', to: { name: 'targets.my-lists.list', params: { listId: item.id, type: 'sources' } }
                })),
                ...this.recentTargets.map(item => ({
                    ...item, name: item.title, to: { name: 'targets.target.information', params: { type: item.family, modelType: item.type, id: item.id } }
                }))
            ]

            return sortNaturally(items)
        },

        ...mapState(useMyTargetListsStore, {
            areListsInitialized: 'isInitialized',
            lists: 'items'
        }),

        ...mapState(useMyRecentTargetsStore, {
            areRecentTargetsInitialized: 'isInitialized',
            recentTargets: 'items'
        }),

        ...mapState(useMyBookmarksStore, {
            areBookmarksInitialized: 'isInitialized',
            bookmarks(store) {
                return [ ...store.items ].sort((a, b) => a.title.localeCompare(b.title))
            }
        }),

        ...mapState(useTargetsTargetTypesStore, {
            libraryTargets: 'types'
        })
    },

    methods: {
        ...mapActions(useTargetsTargetTypesStore, [ 'typeToHuman' ]),
    }
}
</script>
