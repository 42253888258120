export default function logger(level, ...message)
{
    if (process.env.VUE_APP_ENV == 'development') console[level](...message)
}

export function info(...message)
{
    logger('info', ...message)
}

export function warning(...message)
{
    logger('warning', ...message)
}

export function error(...message)
{
    logger('error', ...message)
}

export function debug(...message)
{
    logger('debug', ...message)
}
