import { defineStore } from 'pinia'

import useMyStore from '@/stores/me/my'
import useMyBroadcasterStore from '@/stores/me/broadcaster'

export const useAnalysesChannel = defineStore({
    id: 'analysesChannel',

    state: () => ({
        isJoined: false,
        joinPromise: null,

        channel: null
    }),

    actions: {
        initialize() {
            this.join()
        },

        async join() {
            if (this.isJoined) return
            if (this.joinPromise) return Promise.all([ useMyBroadcasterStore().connectPromise, this.joinPromise ])

            this.joinPromise = new Promise((accept, reject) => {
                this.channel = useMyBroadcasterStore().broadcaster.private('users.' + useMyStore().user.id + '.analyses')

                this.channel.subscribed(() => {
                    accept()
                    this.isJoined = true
                })

                this.channel.error(e => reject(e))
            })

            return Promise.all([ useMyBroadcasterStore().connectPromise, this.joinPromise ])
        },

        async listen(type, callback) {
            await this.join()

            return this.channel.listen(type, callback)
        }
    }
})

export default useAnalysesChannel
