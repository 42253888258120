<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Features
    </h1>

    <navigation-section title="Library"
        :items="libraryFeatures"
        :to="item => ({ name: 'features', params: { type: item.type } })">
    </navigation-section>
</template>

<script>
import useFeaturesLibraryTypesStore from '@/stores/features/library-types'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useFeaturesLibraryTypesStore, {
            libraryFeatures: 'shownFeatures'
        })
    }
}
</script>
