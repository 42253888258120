<template>
    <router-link :to="{ name: 'dashboard.boards.board', params: { id: board.id } }" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-100 shadow hover:shadow-lg cursor-pointer relative group">
            <div class="flex items-center relative p-4" v-tooltip="board.description">
                <ui-avatar :item="board" class="w-8 h-8 shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{board.name}}
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        Dashboard
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :board="board" plain></contextual-menu>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-8 bg-gray-50 border-t border-gray-75 rounded-b space-x-3 text-xs text-gray-500" v-if="board.recent || board.pinned">
                <span class="flex items-center space-x-1" v-if="board.recent">
                    <ui-icon name="time"></ui-icon>
                    <span>Recent</span>
                </span>

                <span class="flex items-center space-x-1" v-if="board.pinned">
                    <ui-icon name="pin-off"></ui-icon>
                    <span>Pinned</span>
                </span>
            </div>
        </div>
    </router-link>

    <component :is="cardsStore.choose ? 'div' : 'router-link'" :to="{ name: 'dashboard.boards.board', params: { id: board.id } }" class="block" v-else-if="mode == 'row'">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0" v-tooltip="board.description">
                <ui-avatar :item="board" class="w-8 h-8 shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{board.name}}
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        Dashboard
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-end flex-1 space-x-2 text-gray-500">
                <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Recently used'" v-if="board.recent">
                    <ui-icon name="time"></ui-icon>
                </span>

                <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Pinned'" v-if="board.pinned">
                    <ui-icon name="pin-off"></ui-icon>
                </span>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <contextual-menu :board="board" plain></contextual-menu>
                </slot>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(board)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </component>
</template>

<script>
import ContextualMenu from '@/components/dashboards/contextual-menus/board'

import useMyDashboards from '@/stores/me/dashboards'

import { mapActions } from 'pinia'

export default {
    props: { board: {}, cardsStore: {}, mode: { default: 'card' } },

    components: { ContextualMenu },

    methods: {
        ...mapActions(useMyDashboards, {
            updateBoard: 'update'
        })
    }
}
</script>
