import useMyCuratedTopicsStore from '@/stores/me/curated-topics'

import defineCards from '@/stores/reusable/cards'

import { sortAlphabetically } from '@/helpers'

export const useContentSuggestedTopicsCardsStore = defineCards({
    id: 'contentSuggestedTopicsCards',

    source: useMyCuratedTopicsStore(),

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'desc',
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        }
    ],

    rows: 1
})

export default useContentSuggestedTopicsCardsStore
