<template>
    <div>
        <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="! store.series.length">
            <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
            <h1 class="text-2xl font-semibold my-6">Welcome to the "Content Performance" analysis!</h1>
            <p class="text-lg my-1">This analysis allows you to <span class="font-medium">visualize and compare various performance metrics</span> like published content count, prevalence, interactions, and more.</p>
            <div class="flex space-x-10 my-10">
                <div class="flex-1">
                    <h2 class="font-semibold">Data Series</h2>
                    <p class="text-justify my-2">Add multiple data series based on existing Perspectives, Topics or Target Lists, or you can define a new perspective inline.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Analysis Styles</h2>
                    <p class="text-justify my-2">Customize how the analysis looks by choosing form multiple analysis types like line or bar analysis and other options.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Range & Grouping</h2>
                    <p class="text-justify my-2">Use the range & grouping controls to specify the shown date range and granularity. You can also drag select a part of the analysis to zoom in.</p>
                </div>
            </div>
            <p class="text-lg my-1">
                To start let's <span class="font-medium">add some data</span> to the analysis by clicking on the Add Series button.
            </p>
            <div class="flex items-center mt-8">
                <ui-button color="blue" icon="plus-square" size="lg" :action="() => openSeriesModal(store)">
                    Add Series
                </ui-button>
            </div>
        </div>

        <div v-else>
            <analysis-toolbar :store="store">
                <template v-slot:settings>
                    <settings-series :store="store"></settings-series>
                </template>
            </analysis-toolbar>

            <div class="px-4 py-8 h-128 bg-white border border-gray-200 rounded-lg shadow-sm relative">
                <div class="absolute top-4 right-4 z-20">
                    <chart-styles-contextual-menu :store="store"></chart-styles-contextual-menu>
                </div>

                <ui-analysis :store="store" ref="analysis"></ui-analysis>
            </div>
        </div>
    </div>

    <Teleport to="#root">
        <series-modal></series-modal>
    </Teleport>
</template>

<script>
import AnalysisToolbar from './toolbar/toolbar'
import ChartStylesContextualMenu from '@/components/analysis/contextual-menus/chart-styles'
import SeriesModal from '@/components/analysis/modals/content-performance/series'
import SettingsSeries from './settings/content-performance/series'

import useAnalysisModalsContentPerformanceSeriesStore from '@/stores/analysis/modals/content-performance/series'

import { mapActions } from 'pinia'

export default {
    components: {
        AnalysisToolbar, ChartStylesContextualMenu, SeriesModal, SettingsSeries
    },

    methods: {
        ...mapActions(useAnalysisModalsContentPerformanceSeriesStore, { openSeriesModal: 'open' }),
    },

    watch: {
        'store.series.length'() {
            this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
        }
    },

    mounted() {
        this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
    }
}
</script>
