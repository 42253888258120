<template>
    <div class="h-8 flex items-center mb-6 relative space-x-4" v-if="store.showsResultsBar">
        <slot name="results-actions"></slot>

        <div class="flex items-center h-full">
            <a @click.prevent="store.toggleQuickInspect" v-if="store.resultsToolbar.tags !== false" v-tooltip="'Inspect'" href="#" class="bg-white rounded-lg flex h-full text-xl flex items-center justify-center w-11 text-blue-600 hover:text-blue-900 hover:bg-gray-50">
                <ui-icon name="navigation.analysis"></ui-icon>
            </a>

            <a @click.prevent="openExportingModal({streamStore: store})" v-if="store.exportable" v-tooltip="'Export'" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2">
                <ui-icon name="download"></ui-icon> Export
            </a>

            <a @click.prevent="openImportingModal" v-if="store.importable" v-tooltip="'Import'" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2">
                <ui-icon name="upload"></ui-icon> Import
            </a>

            <toolbar-analyze :store="store" v-if="store.analyses.length && store.appliedPerspective"></toolbar-analyze>

            <toolbar-tags :store="store" v-if="store.resultsToolbar.tags !== false"></toolbar-tags>
        </div>

        <div class="flex-1"></div>

        <div class="text-sm" v-if="store.itemsCountTotal.data !== null">
            <span class="font-medium">{{ $number(store.itemsCountTotal.data) }}{{ store.itemsCountTotal.data == 10000 ? '+' : '' }}</span>
            results
        </div>

        <results-toolbar-sorting class="ml-6" :store="store" v-if="store.sortingOptions.length"></results-toolbar-sorting>
        <results-toolbar-view class="ml-4" :store="store"></results-toolbar-view>

        <transition
            enter-active-class="transition ease duration-150 transform"
            enter-class="opacity-0 -translate-y-full"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease duration-150 transform"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-full"
        >
            <stream-content-statistics :store="store" v-if="store.isShowingQuickInspect"></stream-content-statistics>
        </transition>

        <div class="flex justify-center text-sm w-full absolute top-0 pointer-events-none" v-if="store.itemsNewCount.data">
            <a href="#" class="inline-flex items-center px-6 h-10 leading-4 bg-blue-500 text-white font-medium shadow-md rounded-full bg-opacity-85 hover:bg-blue-600 pointer-events-auto" @click.prevent="showNewItems()">
                {{$number(store.itemsNewCount.data)}} new items
            </a>
        </div>

        <Teleport to="#root">
            <import-options-modal :stream-store="store"></import-options-modal>
        </Teleport>
    </div>
</template>

<script>
import ImportOptionsModal from '@/components/targets/modals/list-import-targets'
import ResultsToolbarSorting from '@/components/ui/cards/toolbar/results-sorting'
import ResultsToolbarView from '@/components/ui/cards/toolbar/results-view'
import StreamContentStatistics from '../statistics/content'
import ToolbarAnalyze from './toolbar-analyze'
import ToolbarTags from './toolbar-tags'

import useModalsExportOptionsStore from '@/stores/modals/export-options'
import useModalsListImportTargetsStore from '@/stores/targets/modals/list-import-targets'

import { scroll } from '@/helpers'
import { mapActions } from 'pinia'

export default {
    components: {
        ImportOptionsModal, ResultsToolbarSorting, ResultsToolbarView, StreamContentStatistics, ToolbarAnalyze, ToolbarTags
    },

    data: () => ({
        scroll
    }),

    methods: {
        showNewItems() {
            this.store.showNewItems()

            document.querySelector('#app-content').scrollTo(0, 0)
        },

        ...mapActions(useModalsExportOptionsStore, { openExportingModal: 'open' } ),
        ...mapActions(useModalsListImportTargetsStore, { openImportingModal: 'open' } )
    },

    watch: {
        'scroll.position'(val) { if (val > 300) this.showInspect = false }
    }
}
</script>
