import defineCards from '@/stores/reusable/cards'

import useDashboardsManagePage from '@/pages/dashboards-index'
import useMyDashboardsStore from '@/stores/me/dashboards'

import { sortAlphabetically, textSearch } from '@/helpers'

export const useDashboardsIndexCardsStore = defineCards({
    id: 'dashboardsIndexCards',

    source: useMyDashboardsStore(),
    filter: items => textSearch(
        useDashboardsManagePage().search,
        useDashboardsManagePage().type
            ? items.filter(item => item.type === useDashboardsManagePage().type.slice(0, -1))
            : items,
        i => i.name
    ),

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'desc',
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => items.sort((a, b) => new Date(b.lastUsedAt) - new Date(a.lastUsedAt))
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        }
    ]
})

export default useDashboardsIndexCardsStore
