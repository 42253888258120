import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTargetsListStreamStore from '@/stores/targets/list-stream'
import useTargetsListSuggestionsStore from '@/stores/targets/list-suggestions'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsMyListsListPage = defineStore({
    id: 'targetsMyListsListPage',

    state: () => ({
        type: 'sources',
        listId: null
    }),

    getters: {
        title: store => store.list ? `${store.list.name} | Targets` : '',

        streamStore(store) {
            return useTargetsListStreamStore()
        },

        list(store) {
            return useMyTargetListsStore().find(store.listId) || useMyCuratedListsStore().findList(store.listId) || null
        }
    },

    actions: {
        afterEnter(to) {
            this.type = to.params.type
            this.listId = to.params.listId

            useMyBookmarksStore().initialize()
            useMyRecentTargetsStore().initialize()

            Promise.all([
                useMyCuratedListsStore().initialize(),
                useMyTargetListsStore().initialize()
            ]).then(() => {
                this.initializeStream(to)
                if (this.list?.lastUsedAt) { this.list.lastUsedAt = new Date }
            })
        },

        initializeStream(to) {
            this.streamStore.initialize()
            this.streamStore.navigatedTo(to, {
                'list': this.list
            })

            this.streamStore.searchContext = this.list.name

            useTargetsListSuggestionsStore().load(this.list)

            document.title = this.title
        }
    }
})

export default useTargetsMyListsListPage
