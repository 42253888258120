<template>
    <div>
        <ui-header>
            <template v-slot:subsection>
                <stream-toolbar :store="$page.streamStore"></stream-toolbar>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-12xl mx-auto">
            <div class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 border-b border-gray-200 pb-6 mb-6">
                <router-link :to="{ name: 'targets.library.search', params: { type: 'sources' }, query: { 'sorting': 'publishedPerWeek-desc' } }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="stream" class="shrink-0 mr-2"></ui-icon>
                    Most Active
                </router-link>
                <router-link :to="{ name: 'targets.library.search', params: { type: 'sources' }, query: { 'sorting': 'followers-desc' } }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="users" class="shrink-0 mr-2"></ui-icon>
                    Most Followed
                </router-link>
                <a href="#" @click.prevent="createList" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="folder" class="shrink-0 mr-2"></ui-icon>
                    New List
                </a>
                <a href="#" @click.prevent="addTarget" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="plus-square" class="shrink-0 mr-2"></ui-icon>
                    Monitor Target
                </a>
            </div>

            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="navigation.targets" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to the Targets section!</h1>
                <p class="text-lg my-1">In this section you can <span class="font-medium">search through and interact with the sources and groups we collect data from</span> and create our own lists.</p>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Targets</h2>
                        <p class="text-justify my-2">We collect data from numerous sources and groups like Facebook Pages and Groups, Youtube Channels, Web Feeds and Web Sites. We call these sources and groups <span class="font-medium">targets</span>. </p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Bookmarks</h2>
                        <p class="text-justify my-2">You can <span class="font-medium">bookmark</span> targets to make them easier accessible in the future, similar to how you can bookmark web pages in your web browser.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Lists</h2>
                        <p class="text-justify my-2">You can collect multiple targets into <span class="font-medium">target lists</span>. Your target lists can then be used in perspectives or analyses.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can <span class="font-medium">build your first list</span>, choose from one of the <span class="font-medium">curated lists below</span> or simply <span class="font-medium">search for a target</span> that interests you.
                </p>
                <div class="flex items-center mt-8">
                    <ui-button icon="plus-square" color="blue" size="lg" :action="createList">
                        New List
                    </ui-button>
                </div>
            </div>

            <ui-cards-section title="Pinned & Recent" :store="targetsIndexCardsStore" key="indexCards">
                <template v-slot:item="{ item, cardsStore }">
                    <list-card :list="item" :cards-store="cardsStore" :mode="cardsStore.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'lists'"></list-card>
                </template>
            </ui-cards-section>

            <ui-cards-section title="Bookmarks" :store="targetsIndexBookmarksCardsStore" key="bookmarksCards">
            </ui-cards-section>

            <div class="mb-16" v-if="! areCuratedListsInitialized || curatedSubcategories.length">
                <h1 class="px-0.5 text-xl text-gray-900 font-semibold mb-2">
                    Curated Lists
                </h1>

                <ui-async :guards="areCuratedListsInitialized">
                    <template v-slot>
                        <div class="flex justify-center my-8 mt-2">
                            <ui-dropdown>
                                <template v-slot:trigger>
                                    <div class="relative text-2xl font-semibold cursor-pointer" v-if="$page.curatedRootCategory">
                                        <ui-icon :name="$page.curatedRootCategory.icon"></ui-icon>
                                        <span class="mx-1">{{$page.curatedRootCategory.name}}</span>
                                        <ui-icon name="chevron-down" class="text-lg text-gray-700"></ui-icon>
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <ui-dropdown-link :icon="category.icon" variant="large" @click="$page.curatedRootCategory = $page.curatedCategory = category" :key="category.id" v-for="category in curatedRootCategories">
                                        {{category.name}}
                                    </ui-dropdown-link>
                                </template>
                            </ui-dropdown>
                        </div>

                        <div class="grid grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-6" v-if="curatedSubcategories.length">
                            <template v-for="category in curatedSubcategories">
                                <curated-category-card :category="category" :to="{ name: 'targets.curated-lists.category', params: { categoryId: category.id } }" :key="category.id" v-if="category.leaf"></curated-category-card>
                                <curated-category-card :category="category" @click.prevent="$page.curatedCategory = category" :key="category.id" v-else></curated-category-card>
                            </template>
                        </div>
                    </template>
                </ui-async>
            </div>

            <div class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 border-t border-gray-200 pt-6" v-if="! $my.showSidebar">
                <router-link :to="{ name: 'targets.bookmarks' }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="navigation.sub.bookmarks" class="shrink-0 text-xl mr-2"></ui-icon>
                    Bookmarks
                </router-link>
                <router-link :to="{ name: 'targets.my-lists' }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="navigation.sub.target-lists" class="shrink-0 text-xl mr-2"></ui-icon>
                    My Lists
                </router-link>
                <router-link :to="{ name: 'targets.my-targets' }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="navigation.targets" class="shrink-0 text-xl mr-2"></ui-icon>
                    My Targets
                </router-link>
                <router-link :to="{ name: 'targets.curated-lists' }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="navigation.sub.curated-lists" class="shrink-0 text-xl mr-2"></ui-icon>
                    Curated Lists
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import CuratedCategoryCard from './stream-cards/curated-category'
import ListCard from './stream-cards/list'
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import UiCardsSection from '@/components/ui/cards/cards-section'

import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useTargetsIndexCardsStore from '@/stores/targets/index-cards'
import useTargetsIndexBookmarksCardsStore from '@/stores/targets/index-bookmarks-cards'
import useTargetsModalsAddTargetStore from '@/stores/targets/modals/library-add-target'
import useTargetsModalsListEditStore from '@/stores/targets/modals/list-edit'

import breakpoint from '@/helpers/breakpoint'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        CuratedCategoryCard, ListCard, StreamToolbar, UiCardsSection
    },

    computed: {
        ...mapStores(useTargetsIndexCardsStore, useTargetsIndexBookmarksCardsStore),

        ...mapState(useMyCuratedListsStore, {
            areCuratedListsInitialized: 'isInitialized',
            curatedRootCategories(store) {
                return [ ...store.categories ].sort((a, b) => a.name.localeCompare(b.name))
            },
            curatedSubcategories() {
                return this.$page.curatedCategory?.children || []
            }
        }),

        perRow() {
            return { xs: 2, sm: 2, md: 2, lg: 3, xl: 3, '2xl': 3, '3xl': 4 }[breakpoint.is]
        },

        isShowingWelcomeMessage() {
            return this.targetsIndexCardsStore.isInitialized && ! this.targetsIndexCardsStore.items.length
        }
    },

    methods: {
        ...mapActions(useTargetsModalsAddTargetStore, { 'addTarget': 'open' }),
        ...mapActions(useTargetsModalsListEditStore, { 'createList': 'open' })
    }
}
</script>
