<template>
    <div>
        <ui-header title="Perspectives">
            <template v-slot:actions>
                <ui-button icon="plus-square" color="blue" size="sm" :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' } }">
                    New Perspective
                </ui-button>

                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off"
                           spellcheck="false"
                           placeholder="Search perspectives..."
                           class="block w-full h-8 pl-8 pr-2 rounded placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm"
                    >
                </form>

<!--                <ui-help-button v-tooltip="'Content'" page="content" section="content-perspectives"></ui-help-button>-->
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-12xl mx-auto">
            <div class="h-120 flex flex-col items-center justify-center text-gray-600" v-if="noSearchResults">
                <ui-icon name="wind" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">Check your spelling or try a different query.</div>
            </div>

            <div class="mb-8" v-else>
                <ui-cards-toolbar :store="contentPerspectivesCardsStore"></ui-cards-toolbar>

                <ui-cards :store="contentPerspectivesCardsStore">
                </ui-cards>
            </div>
        </div>
    </div>
</template>

<script>
import useContentPerspectivesCardsStore from '@/stores/content/perspectives-cards'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useContentPerspectivesCardsStore),

        noSearchResults() {
            return this.contentPerspectivesCardsStore.source.isInitialized && ! this.contentPerspectivesCardsStore.items.length
        }
    }
}
</script>
