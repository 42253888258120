import useAnalysisMyAnalysesPage from '@/pages/analysis-my-analyses'
import useMyAnalysesStore from '@/stores/me/analyses'

import defineCards from '@/stores/reusable/cards'

import { sortAlphabetically, textSearch } from '@/helpers'

export const useAnalysisMyAnalysesCardsStore = defineCards({
    id: 'analysisMyAnalysesCards',

    source: useMyAnalysesStore(),
    filter: items => textSearch(useAnalysisMyAnalysesPage().search, items, i => i.name),

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'desc',
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => items.sort((a, b) => new Date(b.lastUsedAt) - new Date(a.lastUsedAt))
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        }
    ]
})

export default useAnalysisMyAnalysesCardsStore
