import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyTargetListsStore from '@/stores/me/target-lists'

export const useTargetsModalsListEditStore = defineForm({
    id: 'targetsModalsListEdit',

    inputs: () => ({
        list: {},
        name: null,
        description: null,
        avatar: {}
    }),

    submitRequest() {
        if (! this.inputs.list.id) {
            trackEvent('targets', 'new-target-list-saved')
        }

        let route = this.inputs.list.id
            ? [ 'me lists update', this.inputs.list.id ]
            : [ 'me lists store' ]

        return api.route(...route)
            .json({
                _method: this.inputs.list.id ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: {
                    color: this.inputs.avatar.color,
                    icon: this.inputs.avatar.icon,
                    imageId: this.inputs.avatar.imageId
                }
            })
            .post()
    },

    async onResponse(res) {
        await useMyTargetListsStore().reload()

        useModal().hide('targets-list-edit')

        if (! this.inputs.list.id) {
            useRouter().push({ name: 'targets.my-lists.list', params: { listId: res.data.id, type: 'sources' } })
        }
    },

    actions: {
        open(list = {}) {
            this.reset()

            this.inputs.list = list
            this.inputs.name = list.name
            this.inputs.description = list?.description
            this.inputs.avatar = list?.avatar || {}

            useModal().show('targets-list-edit')
        },

        cancel() {
            useModal().hide('targets-list-edit')
        },

        async delete() {
            try {
                await useMyTargetListsStore().delete({ id: this.inputs.list.id })
            } catch (e) {
                return
            }

            useModal().hide('targets-list-edit')
            useRouter().push({ name: 'targets.my-lists' })
        }
    }
})

export default useTargetsModalsListEditStore
